import React from 'react';
import * as Yup from 'yup';

import { InputField } from 'src/components';

import debounce from 'lodash.debounce';
import { httpClient } from 'src/helpers';

import { useTranslation } from 'react-i18next';

const { GATSBY_VERTICAL } = process.env;

const initialValues = {
  facilityName: '',
  employees: 0,
};

const schema = Yup.object().shape({
  acceptTerms: Yup.boolean().required('forms.required').isTrue(),
});

const Step = () => {
  const { t } = useTranslation(['dashboard', 'greenshield']);

  return (
    <div className="space-y-8">
      <span className="text-xl font-bold">{t('forms.package.title')}</span>
      <div className="space-y-4">
        <div className="sm:w-2/3">
          <div className="flex items-center p-6 space-x-4 border-2 rounded-lg shadow-md lg:space-x-8 bg-primary-light bg-opacity-10 border-primary-light">
            <div>
              <svg viewBox="0 0 20 20" className="w-5 h-5 fill-current text-primary-light">
                <path d="M17.7962 0.5L16.6943 1.61165C13.6568 4.65569 10.4161 8.11086 7.38176 11.1972L3.18869 7.74527L1.97952 6.75061L0 9.15919L1.19941 10.1538L6.50414 14.5224L7.60604 15.4293L8.60068 14.4249C11.9611 11.0573 15.6016 7.11912 18.8981 3.81546L20 2.7038L17.7962 0.5Z" />
              </svg>
            </div>
            <div className="flex flex-col space-y-4 text-sm">
              <span className="text-xl">Sharecare health security pass</span>
              <p>
                Easy compliance with OSHA and State vaccine and testing guidelines and requirements.
              </p>
              <p>
                Super-fast implementation in 15min. No apps to install and no training needed, with
                an intuitive chatbot that works on any device. Avoid the daunting task of manually
                tracking employees and sites: save money with real-time tracking of daily/weekly
                COVID-19 tests, vaccines, exemptions and other compliance options. Digital audit
                trail saves a fortune in fees, fines and overhead in case of an OSHA or State
                audits.
              </p>
            </div>
          </div>
        </div>
        {false && (
          <div className="flex items-center p-6 space-x-4 rounded-lg opacity-50 lg:space-x-8">
            <div>
              <div className="flex items-start justify-start h-5 p-1 bg-gray-500 rounded-full w-9">
                <div className="w-3 h-full bg-white rounded-full" />
              </div>
            </div>
            <div>
              <span className="text-xl font-bold">{t('forms.package.items.readiness.title')}</span>
              <p className="pt-2 text-sm">{t('forms.package.items.readiness.description')}</p>
            </div>
          </div>
        )}
      </div>
      <InputField
        type="checkbox"
        name="acceptTerms"
        translate={false}
        label={
          <p className="text-left">
            <span>{t('forms.package.pricing.confirm')}</span>
            <a href="/legal" target="_blank" className="pl-2 text-blue-500 underline">
              {t('forms.package.pricing.confirmLink')}
            </a>
          </p>
        }
        namespaces={['dashboard']}
      />
    </div>
  );
};

export { initialValues, schema };
export default Step;
