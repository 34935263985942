import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { UsersLoader } from 'src/views/app/shared';
import { Page, Searcher, Pagination } from 'src/components';
import { Employee } from './components';

import ModalEvidenceProof from './components/ModalEvidenceProof';
import sort from 'src/helpers/sort-by-role';

const { GATSBY_VERTICAL } = process.env;

const EmployeeStatus = ({ facility, mutate: refreshFacility }) => {
  const user = useSelector((state) => state.user);

  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const pageSize = 25;

  const [currentEvidenceProof, setCurrentEvidenceProof] = useState(0);
  const [modalEvidenceProofOpen, setModalEvidenceProofOpen] = useState(false);
  const [currentMouseHover, setCurrentMouseHover] = useState('');

  const [sortParam, setSortParam] = useState({
    colName: 'lastCheckDate',
    colOrder: 'asc',
  });

  const protectionStatusParam = '';
  // const protectionStatusParam = `protectionStatus=VALID`;
  // const protectionStatusParam = `&protectionStatus=EXPIRED`;
  // const protectionStatusParam = `&protectionStatus=MISSING`;

  const exposureStatusParam = '';
  // const exposureStatusParam = `&exposureStatus=EXPOSED`;
  // const exposureStatusParam = `&exposureStatus=${encodeURIComponent('NOT EXPOSED')}`;

  const searchParam = search ? `&search=${encodeURIComponent(search)}` : '';

  const pagingParams = `&pages=true&page=${search ? 1 : page}&size=${pageSize}`;

  const url = `/facilities/${facility.id}/employees?${protectionStatusParam}${exposureStatusParam}${searchParam}&sort=${sortParam.colName}-${sortParam.colOrder}${pagingParams}`;
  const { data, error, mutate } = useSWR(facility.id ? [url] : null);

  if (error) {
    return (
      <Page title={`${facility.name} Users` || 'Facility Users'}>
        <Page.Main className="pt-16">
          <div>An error has occurred.</div>
        </Page.Main>
      </Page>
    );
  }

  const handleSort = (keyName) => {
    setSortParam({
      colName: keyName,
      colOrder:
        // If I clicked on the SAME column, then toggle asc or desc
        // else I clicked on a different column, set to asc
        sortParam.colName === keyName ? (sortParam.colOrder === 'asc' ? 'desc' : 'asc') : 'asc',
    });
  };

  const renderSortArrow_HOVER = (keyName, bgColor, borderColor) => {
    // If the return() is trying to render the same column my mouse is hovering over
    return currentMouseHover === keyName &&
      // AND This column isn't already showing a sorting arrow
      sortParam.colName !== currentMouseHover ? (
      // Then show the HOVER sorting arrow pointed UP (Ascending)
      <div
        className={`px-0.5 py-0.5 rounded border-2 ${borderColor}`}
        style={{ background: bgColor }}
      >
        <svg
          width="12"
          height="12"
          viewBox="0 0 6 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3 0.5L3 7.5M3 0.5L1 2.22839M3 0.5L5 2.22839"
            stroke="#5D6A74"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    ) : null;
  };

  const renderSortArrow = (keyName, bgColor, borderColor) => {
    return sortParam.colName === keyName ? (
      <div
        className={`px-0.5 py-0.5 rounded border-2 ${borderColor}`}
        style={{ background: bgColor }}
      >
        {sortParam.colOrder === 'desc' ? (
          <svg
            width="12"
            height="12"
            viewBox="0 0 6 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3 7.5V0.5M3 7.5L5 5.77161M3 7.5L1 5.77161"
              stroke="#5D6A74"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        ) : (
          <svg
            width="12"
            height="12"
            viewBox="0 0 6 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3 0.5L3 7.5M3 0.5L1 2.22839M3 0.5L5 2.22839"
              stroke="#5D6A74"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
      </div>
    ) : null;
  };

  return (
    <>
      <Page title={`${facility.name} Employees` || 'Facility Employees'}>
        <Page.Main className="px-4 pt-16 pb-24 lg:container">
          <div>
            <h5 className="pt-8 font-bold lg:text-3xl">Employee Status</h5>
            <div className="flex flex-col pt-6 space-y-6 lg:flex-row lg:space-y-0">
              <div className="flex items-center justify-between flex-shrink-0 w-full space-x-4 lg:w-60">
                <Searcher
                  search={search}
                  setSearch={setSearch}
                  placeholder="Search an employee or an ID"
                />
                {search !== '' && data && (
                  <span className="text-sm text-secondary whitespace-nowrap">
                    {`${
                      data.employees.length > 1
                        ? `${data.employees.length} results`
                        : `${data.employees.length} result`
                    }`}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="pt-6 lg:pt-12">
            {!data && <UsersLoader />}
            {data && GATSBY_VERTICAL === 'GREENSHIELD' && data.employees.length <= 0 && (
              <div className="flex items-center justify-center">
                <div>
                  <svg viewBox="0 0 180.000000 161.000000" className="h-48 pb-4">
                    <g transform="translate(0.000000,161.000000) scale(0.100000,-0.100000)">
                      <path
                        d="M775 1589 c-276 -43 -513 -225 -619 -477 -43 -101 -59 -187 -59 -307 0 -139 19 -220 82 -350 131 -268 400 -435 702 -436 138 0 230 21 354 81 160 77 282 197 360 355 64 131 79 196 79 350 0 153 -14 217 -79 350 -126 259 -377 423 -673 439 -48 3 -114 1 -147 -5z m254 -194 c210 -50 390 -227 446 -440 19 -76 19 -224 0 -299 -41 -158 -172 -319 -316 -390 -281 -138 -609 -49 -781 213 -118 180 -129 406 -30 604 71 143 229 268 392 311 72 19 209 19 289 1z"
                        fill="#4a4a4a"
                      />
                      <path
                        d="M605 1131 c-108 -49 -159 -185 -113 -306 35 -92 145 -221 278 -328 88 -70 79 -80 105 119 l17 131 -40 39 -41 39 40 85 40 85 -25 18 c-69 50 -70 52 -58 85 11 31 10 32 -26 42 -56 15 -132 12 -177 -9z"
                        fill="#029099"
                      />
                      <path
                        d="M976 1136 c-22 -8 -48 -24 -60 -35 l-21 -21 42 -42 41 -41 -31 -64 -32 -64 44 -37 44 -38 -63 -164 c-34 -91 -66 -175 -71 -187 -27 -70 114 18 256 161 121 123 169 211 170 311 0 88 -31 156 -92 199 -35 24 -54 30 -115 33 -48 2 -87 -2 -112 -11z"
                        fill="#029099"
                      />
                    </g>
                  </svg>
                  <span className="block text-sm font-bold text-center">
                    {search ? 'No user found' : 'No User yet'}
                  </span>
                </div>
              </div>
            )}
            {data && GATSBY_VERTICAL === 'GREENSHIELD' && data.employees.length > 0 && (
              <>
                <div className="hidden lg:block">
                  <div className="flex items-center text-xs font-bold uppercase h-9 text-secondary">
                    <div
                      className="flex flex-row items-center h-full gap-2 cursor-pointer w-72"
                      onClick={() => handleSort('lastName')}
                      onMouseEnter={() => setCurrentMouseHover('lastName')}
                      onMouseLeave={() => setCurrentMouseHover('')}
                    >
                      <span>Name (Employee ID)</span>
                      {renderSortArrow('lastName', '#F6F6F6', 'border-gray-300')}
                      {renderSortArrow_HOVER('lastName', '#F6F6F6', 'border-gray-300')}
                    </div>
                    <div
                      className="flex flex-row items-center justify-center w-64 h-full gap-2 cursor-pointer"
                      style={{ background: '#FEEDD4' }}
                      onClick={() => handleSort('evidences.0.evidenceRemainingTime')}
                      onMouseEnter={() => setCurrentMouseHover('evidences.0.evidenceRemainingTime')}
                      onMouseLeave={() => setCurrentMouseHover('')}
                    >
                      <div>
                        <span className="font-normal">Protection </span>
                        Status
                      </div>
                      {renderSortArrow(
                        'evidences.0.evidenceRemainingTime',
                        '#F6D6A4',
                        'border-white'
                      )}
                      {renderSortArrow_HOVER(
                        'evidences.0.evidenceRemainingTime',
                        '#FEEDD4',
                        'border-white'
                      )}
                    </div>
                    <div
                      className="flex flex-row items-center justify-center w-64 h-full gap-2 mr-24 cursor-pointer"
                      style={{ background: '#FEEDD4' }}
                      onClick={() => handleSort('evidences.0.evidenceType')}
                      onMouseEnter={() => setCurrentMouseHover('evidences.0.evidenceType')}
                      onMouseLeave={() => setCurrentMouseHover('')}
                    >
                      <div>
                        <span className="font-normal">Protection </span>Type
                      </div>
                      {renderSortArrow('evidences.0.evidenceType', '#F6D6A4', 'border-white')}
                      {renderSortArrow_HOVER('evidences.0.evidenceType', '#FEEDD4', 'border-white')}
                    </div>
                    <div
                      className="flex flex-row items-center justify-center w-64 h-full gap-2 cursor-pointer"
                      style={{ background: '#FEEDD4' }}
                      onClick={() => handleSort('lastCheckDate')}
                      onMouseEnter={() => setCurrentMouseHover('lastCheckDate')}
                      onMouseLeave={() => setCurrentMouseHover('')}
                    >
                      <div>
                        <span className="font-normal">Exposure Last </span>Status
                      </div>
                      {renderSortArrow('lastCheckDate', '#F6D6A4', 'border-white')}
                      {renderSortArrow_HOVER('lastCheckDate', '#FEEDD4', 'border-white')}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col border-2 divide-y-2 lg:space-y-6 lg:divide-y-0 lg:mt-6 lg:border-0 rounded-xl">
                  {data &&
                    data.employees.map((e, i) => (
                      <Employee
                        key={e.id}
                        employee={e}
                        setModalEvidenceProofOpen={() => {
                          setCurrentEvidenceProof(i);
                          setModalEvidenceProofOpen(true);
                        }}
                        facility={facility}
                      />
                    ))}
                </div>
                {data.pages > 1 && (
                  <div style={{ maxWidth: '1152px' }}>
                    <Pagination
                      page={page}
                      setPage={setPage}
                      pages={data.pages}
                      result={data.result}
                      size={pageSize}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </Page.Main>
      </Page>
      {modalEvidenceProofOpen && (
        <ModalEvidenceProof
          open={modalEvidenceProofOpen}
          requestClose={() => setModalEvidenceProofOpen(false)}
          evidenceLink={data.employees[currentEvidenceProof].evidences[0].evidencePath}
          employee={data.employees[currentEvidenceProof]}
        />
      )}
    </>
  );
};

EmployeeStatus.propTypes = {
  facility: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    products: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    userRole: PropTypes.string.isRequired,
    drmUser: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      roles: PropTypes.arrayOf(PropTypes.string.isRequired),
    }).isRequired,
    gmUser: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      roles: PropTypes.arrayOf(PropTypes.string.isRequired),
    }),
    prUser: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      roles: PropTypes.arrayOf(PropTypes.string.isRequired),
    }),
  }),
  mutate: PropTypes.func.isRequired,
};

EmployeeStatus.defaultProps = {
  facility: {},
};

export default EmployeeStatus;
