import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import useSWR from 'swr';

import { httpClient, topicsDups } from 'src/helpers';
import { Modal, Spinner, Snackbar, Button } from 'src/components';
import UpdateAssignmentsForm from './UpdateAssignmentsForm';

const updateJourneySchema = Yup.object().shape({
  assignments: Yup.array().of(Yup.object().shape({})).required(),
});

const ModalUpdateAssignments = ({ open, requestClose, mutate, facility, topics }) => {
  const [snackbarState, setSnackbarState] = useState({ type: 'HIDDEN', msg: '' });
  const [users, setUsers] = useState({ error: false, data: null });

  useSWR(facility.id ? [`/facilities/${facility.id}/users?withTopics=true&pages=false`] : null, {
    onSuccess: (data) => setUsers({ error: false, data }),
    onError: (error) => setUsers({ error, data: null }),
  });

  const handleSubmit = async (values, actions) => {
    actions.setSubmitting(true);
    if (window.gtag) window.gtag('event', 'Update_journey');
    setSnackbarState({ type: 'LOADING' });
    document.getElementById('modal-body').scroll({ top: 0, behavior: 'smooth' });

    if (topicsDups(topics, values)) {
      setSnackbarState({
        type: 'ERROR',
        msg: 'Each section must be at least selected once. Verify your assignment',
      });
      actions.setSubmitting(false);
      return;
    }

    try {
      const { facility: unused, ...payload } = values;

      const { status } = await httpClient.put(
        `/facilities/${facility.id}/users/assignments`,
        payload
      );

      if (status === 200) {
        setSnackbarState({ type: 'SUCCESS' });

        mutate();

        setTimeout(() => {
          requestClose();
        }, 1500);
      } else throw Error('Journey update failed.');
    } catch (err) {
      if (err.response.status === 409 && err.response.data && err.response.data.code)
        switch (err.response.data.code) {
          case 'NAME':
            setSnackbarState({
              type: 'ERROR',
              msg:
                'One journey already exists for this combinaison of ship, homeport and saildate(s)',
            });
            break;
          default:
            setSnackbarState({ type: 'ERROR' });
        }
      else setSnackbarState({ type: 'ERROR' });
      actions.setSubmitting(false);
    }
  };

  return (
    <Modal open={open} requestClose={requestClose}>
      <Modal.Header>
        <h4 className="text-lg font-bold">Update assignments</h4>
        <div className="absolute right-0 pr-6">
          <button type="button" onClick={requestClose}>
            <svg viewBox="0 0 20 20" className="w-5 h-5">
              <path d="M16.6952 0.51282C17.4549 -0.17094 18.6705 -0.17094 19.4302 0.51282C20.1899 1.27255 20.1899 2.48813 19.4302 3.24786L12.7445 10.0095L19.4302 16.6952C20.1899 17.4549 20.1899 18.6705 19.4302 19.4302C18.6705 20.1899 17.4549 20.1899 16.6952 19.4302L10.0095 12.7445L3.24786 19.4302C2.48813 20.1899 1.27255 20.1899 0.51282 19.4302C-0.17094 18.6705 -0.17094 17.4549 0.51282 16.6952L7.27445 10.0095L0.51282 3.24786C-0.17094 2.48813 -0.17094 1.27255 0.51282 0.51282C1.27255 -0.17094 2.48813 -0.17094 3.24786 0.51282L10.0095 7.27445L16.6952 0.51282Z" />
            </svg>
          </button>
        </div>
      </Modal.Header>
      <Modal.Body className="px-6">
        {!users.error && !users.data && (
          <div className="flex items-center justify-center pb-6 text-primary-light">
            <Spinner />
          </div>
        )}
        {users.error && <div className="flex items-center justify-center">An error occured.</div>}
        {!users.error && users.data && (
          <Formik
            initialValues={{
              facility: { ...facility },
              assignments: users.data
                .filter((u) => u.externalChatbotManagerId)
                .sort((a, b) => a.email.localeCompare(b.email))
                .map((e) => {
                  const { topics: userTopics, ...user } = e;

                  return {
                    user,
                    topics: topics.map((t) => ({
                      ...t,
                      selected: userTopics ? userTopics.includes(t.topicMasterId) : false,
                    })),
                  };
                }),
            }}
            validationSchema={updateJourneySchema}
            onSubmit={handleSubmit}
          >
            {({ isValid, isSubmitting, dirty }) => (
              <Form className="max-w-md mx-auto" autoComplete="off">
                <Snackbar
                  type={snackbarState.type}
                  message={snackbarState.msg}
                  requestClose={() => setSnackbarState({ ...snackbarState, type: 'HIDDEN' })}
                />

                <UpdateAssignmentsForm />

                <div className="flex flex-col items-center justify-center pb-6">
                  <div className="flex w-full">
                    <Button
                      type="submit"
                      size="md"
                      disabled={
                        !(isValid && dirty) || isSubmitting || snackbarState.type === 'SUCCESS'
                      }
                    >
                      Update assignments
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </Modal.Body>
    </Modal>
  );
};

ModalUpdateAssignments.propTypes = {
  open: PropTypes.bool.isRequired,
  requestClose: PropTypes.func.isRequired,
  mutate: PropTypes.func.isRequired,
  facility: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  topics: PropTypes.arrayOf(
    PropTypes.shape({
      topicMasterId: PropTypes.string.isRequired,
      topicName: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};

export default ModalUpdateAssignments;
