import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';

import views from 'src/constants/views';

const Sidebar = ({ ship }) => {
  const { t } = useTranslation('facility');

  return (
    <>
      <div className="fixed inset-x-0 top-0 z-50 flex items-center h-16 px-4 space-x-4 bg-gray-100 lg:hidden">
        <Link to="/dashboard/ships">
          <svg viewBox="0 0 10 15" className="w-5 h-5 fill-current">
            <path d="M1.09905 6.82635L7.65594 0.269461C8.03767 -0.0898204 8.6215 -0.0898204 8.98079 0.269461C9.34007 0.628743 9.34007 1.21257 8.98079 1.59431L3.0751 7.5L8.98079 13.4057C9.34007 13.765 9.34007 14.3713 8.98079 14.7305C8.6215 15.0898 8.03767 15.0898 7.65594 14.7305L1.09905 8.1512C0.73977 7.79192 0.73977 7.20808 1.09905 6.82635Z" />
          </svg>
        </Link>
        <div>
          {ship.logo ? (
            <img
              src={ship.logo.src}
              alt={ship.logo.alt}
              className="flex-shrink-0 object-contain w-6 h-6"
            />
          ) : (
            <svg viewBox="0 0 70 70" className="w-8 h-8 fill-current text-secondary">
              <path d="M34.1388 40.9258H5.25L10.25 66.4813L34.1388 69.9998L58.0276 66.4813L63.0276 40.9258H34.1388Z" />
              <path d="M60.7125 19.4444H51.9162V14.7222H40.3421L38.6755 2.49999H40.157V0H34.6014H33.4903H27.9348V2.49999H29.4162L27.7496 14.7222H16.3607V19.4444H7.56445V39.1666H60.7125V19.4444ZM19.4163 31.111H13.3978V25.0925H19.4163V31.111ZM28.3051 31.111H22.2866V25.0925H28.3051V31.111ZM37.1014 31.111H31.0829V25.0925H37.1014V31.111ZM45.9903 31.111H39.9718V25.0925H45.9903V31.111ZM54.8791 31.111H48.8606V25.0925H54.8791V31.111Z" />
            </svg>
          )}
        </div>
        <div className="flex-grow">
          <p className="font-bold truncate" style={{ maxWidth: '250px' }}>
            {ship.name}
          </p>
        </div>
      </div>
      <aside className="fixed inset-y-0 z-40 hidden h-screen pt-16 lg:block">
        <div className="h-full bg-gray-100 border-r w-72">
          <div className="flex flex-col justify-between h-full">
            <div>
              <div className="px-4">
                <div className="pt-4">
                  <Link to="/dashboard/ships" className="flex items-center">
                    <svg viewBox="0 0 10 15" className="w-4 h-4 fill-current">
                      <path d="M1.09905 6.82635L7.65594 0.269461C8.03767 -0.0898204 8.6215 -0.0898204 8.98079 0.269461C9.34007 0.628743 9.34007 1.21257 8.98079 1.59431L3.0751 7.5L8.98079 13.4057C9.34007 13.765 9.34007 14.3713 8.98079 14.7305C8.6215 15.0898 8.03767 15.0898 7.65594 14.7305L1.09905 8.1512C0.73977 7.79192 0.73977 7.20808 1.09905 6.82635Z" />
                    </svg>
                    <span className="pl-2 font-medium">{t('layout.home')}</span>
                  </Link>
                </div>
                <div className="pt-6">
                  <div className="flex items-center p-4 bg-white rounded-lg">
                    {ship.logo ? (
                      <img
                        src={ship.logo.src}
                        alt={ship.logo.alt}
                        className="flex-shrink-0 object-contain w-16 h-16"
                      />
                    ) : (
                      <svg viewBox="0 0 70 70" className="w-12 h-12 fill-current text-secondary">
                        <path d="M34.1388 40.9258H5.25L10.25 66.4813L34.1388 69.9998L58.0276 66.4813L63.0276 40.9258H34.1388Z" />
                        <path d="M60.7125 19.4444H51.9162V14.7222H40.3421L38.6755 2.49999H40.157V0H34.6014H33.4903H27.9348V2.49999H29.4162L27.7496 14.7222H16.3607V19.4444H7.56445V39.1666H60.7125V19.4444ZM19.4163 31.111H13.3978V25.0925H19.4163V31.111ZM28.3051 31.111H22.2866V25.0925H28.3051V31.111ZM37.1014 31.111H31.0829V25.0925H37.1014V31.111ZM45.9903 31.111H39.9718V25.0925H45.9903V31.111ZM54.8791 31.111H48.8606V25.0925H54.8791V31.111Z" />
                      </svg>
                    )}
                    <div className="pl-4 text-sm text-left" style={{ maxWidth: '163px' }}>
                      <p className="font-bold truncate-2">{ship.name}</p>
                    </div>
                  </div>
                </div>
              </div>
              <nav className="flex-grow pt-6">
                <ul className="flex flex-col justify-start">
                  {views.ship.map(
                    (v) =>
                      v.enable &&
                      v.sidebar && (
                        <li key={v.path}>
                          <Link
                            to={`/dashboard/ships/${ship.id}${v.sidebar.to}`}
                            className="aside-link"
                            activeClassName="aside-link-active"
                          >
                            <svg viewBox="0 0 29 25" className="w-8 h-8 fill-current">
                              {v.sidebar.iconPaths.map((i) => (
                                <path key={`${v.sidebar.to}${i}`} d={i} />
                              ))}
                            </svg>
                            <span className="ml-4 text-sm font-medium">{t(v.sidebar.value)}</span>
                          </Link>
                        </li>
                      )
                  )}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </aside>
      <nav className="fixed inset-x-0 bottom-0 z-50 lg:hidden bg-gray-50">
        <ul className="flex items-end justify-between">
          {views.ship.map(
            (v) =>
              v.enable &&
              v.sidebar && (
                <li key={v.path} className="w-full">
                  <Link
                    to={`/dashboard/ships/${ship.id}${v.sidebar.to}`}
                    className="aside-link"
                    activeClassName="aside-link-active"
                  >
                    <svg viewBox="0 0 29 25" className="w-6 h-6 fill-current">
                      {v.sidebar.iconPaths.map((i) => (
                        <path key={`${v.sidebar.to}${i}`} d={i} />
                      ))}
                    </svg>
                    <span className="mt-1 text-xs font-medium">{t(v.sidebar.value)}</span>
                  </Link>
                </li>
              )
          )}
        </ul>
      </nav>
    </>
  );
};

Sidebar.propTypes = {
  ship: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    logo: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    }),
  }),
};

Sidebar.defaultProps = {
  ship: {
    logo: undefined,
  },
};

export default Sidebar;
