import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { Page, Spinner, Searcher, Sorter } from 'src/components';
import { ModalArchivedUser, ModalReactivateUser } from '../../../../shared';
import { User, AsideUser, ModalNewUser, ModalEditUser } from './components';

const getSorts = () => {
  switch (process.env.GATSBY_VERTICAL) {
    case 'VACCINE':
      return [
        { label: 'All roles', func: () => true },
        { label: 'Site manager', func: (e) => e.roles.includes('MANAGER') },
        { label: 'Provider', func: (e) => e.roles.includes('VIEWER') },
      ];
    default:
      return [
        { label: 'All roles', func: () => true },
        { label: 'DRM', func: (e) => e.roles.includes('MANAGER') },
        { label: 'SME', func: (e) => e.roles.includes('VIEWER') },
      ];
  }
};

const Users = () => {
  const VERTICAL = process.env.GATSBY_VERTICAL;
  const user = useSelector((state) => state.user);
  const { t } = useTranslation('users');

  const [search, setSearch] = useState('');
  const [sort, setSort] = useState(getSorts()[0]);
  const [showActive, setShowActive] = useState(false);

  const [userIdentifier, setUserIdentifier] = useState(null);
  const [modalNewUserOpen, setModalNewUserOpen] = useState(false);
  const [editUser, setEditUser] = useState(null);
  const [archivedUser, setArchivedUser] = useState(null);
  const [reactivateUser, setReactivateUser] = useState(null);

  const { data: sites, error: sitesError, mutate: sitesMutate } = useSWR(
    user.clientId ? [`/facilities?clientId=${user.clientId}`] : null
  );

  const { data, error, isValidating, mutate } = useSWR(
    user.clientId
      ? [
          `/users?clientId=${user.clientId}${
            search !== '' ? `&search=${encodeURIComponent(search)}` : ''
          }`,
        ]
      : null
  );

  const users = useCallback(
    () =>
      data
        ? data
            .filter((e) => !(showActive && e.status && e.status.toUpperCase() === 'DISABLED'))
            .filter(sort.func)
            .sort((a, b) => a.email.localeCompare(b.email))
        : [],
    [data, showActive, sort]
  );

  return (
    <>
      <Page title="Users">
        <Page.Main className="container py-12">
          <div className="flex flex-col md:items-center md:flex-row md:space-x-4">
            {VERTICAL === 'VACCINE' && user.role === 'ADMIN' && (
              <div>
                <button
                  type="button"
                  disabled={sitesError || !sites || sites.length <= 0}
                  onClick={() => setModalNewUserOpen(true)}
                  className="w-full px-6 py-2 font-bold text-blue-500 transition rounded-md select-none bg-blue-50 hover:bg-blue-100 focus:bg-blue-100 focus:outline-none focus:text-blue-600 hover:text-blue-600"
                >
                  Add user
                </button>
              </div>
            )}
            <Sorter
              sorts={getSorts().filter((s) => !s.admin || (s.admin && user.role === 'ADMIN'))}
              sort={sort}
              setSort={setSort}
              label="Filter by"
            />
            <div className="flex items-center justify-between space-x-4">
              <Searcher search={search} setSearch={setSearch} placeholder="Search a user..." />
              {search !== '' && data && (
                <span className="text-sm text-secondary whitespace-nowrap">
                  {`${data.length > 1 ? `${data.length} results` : `${data.length} result`}`}
                </span>
              )}
            </div>
          </div>
          <div className="pt-6">
            <label id="inactive-only" htmlFor="inactive" className="flex items-center">
              <input
                type="checkbox"
                aria-labelledby="inactive-only"
                onChange={() => setShowActive(!showActive)}
                value={showActive}
                checked={showActive}
                className="flex-shrink-0 w-6 h-6 border border-gray-300 rounded appearance-none form-tick checked:bg-primary-light checked:border-transparent focus:outline-none disabled:opacity-50 disabled:text-black disabled:text-opacity-50"
                style={{
                  backgroundImage:
                    "url(\"data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e\")",
                }}
              />
              <span className="pl-3 text-sm text-secondary">Only show active users</span>
            </label>
          </div>
          <div className="pt-12">
            {error && (
              <div>
                <span>An error has occurred.</span>
              </div>
            )}
            {!error && !data && (
              <div className="flex justify-center text-primary-light">
                <Spinner />
              </div>
            )}
            {!error && data && users().length > 0 ? (
              <table className="w-full">
                <thead className="hidden lg:table-header-group">
                  <tr className="text-sm text-left uppercase text-secondary">
                    <th className="pb-2">{t('users:table.name')}</th>
                    <th className="pb-2">{t('users:table.assignment')}</th>
                    <th className="pb-2">{t('users:table.email')}</th>
                    <th className="pb-2">{t('users:table.phone')}</th>
                    <th className="pb-2 text-center">{t('users:table.status')}</th>
                    <th className="pb-2 opacity-0">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {users().map((u) => (
                    <User
                      key={u.id}
                      actions={user.role === 'ADMIN'}
                      firstName={u.firstName}
                      lastName={u.lastName}
                      me={user.email === u.email}
                      roles={u.roles}
                      disabled={u.status ? u.status.toUpperCase() === 'DISABLED' : false}
                      email={u.email}
                      phone={u.phone}
                      status={u.status || 'null'}
                      assignments={u.assignments}
                      assignmentType={VERTICAL === 'VACCINE' ? 'SITE' : 'FACILITY'}
                      openAside={() => setUserIdentifier(u.id.toString())}
                      openEdit={() =>
                        setEditUser({
                          id: u.id.toString(),
                          firstName: u.firstName,
                          lastName: u.lastName,
                          email: u.email,
                          phone: u.phone,
                        })
                      }
                      openArchived={() =>
                        setArchivedUser({
                          id: u.id.toString(),
                          firstName: u.firstName,
                          lastName: u.lastName,
                          roles: u.roles,
                        })
                      }
                      openReactivate={() =>
                        setReactivateUser({
                          id: u.id.toString(),
                          firstName: u.firstName,
                          lastName: u.lastName,
                          roles: u.roles,
                        })
                      }
                      canBeRemoved={u.canBeRemoved}
                    />
                  ))}
                </tbody>
              </table>
            ) : (
              !error && !isValidating && <span>No user</span>
            )}
          </div>
        </Page.Main>
      </Page>
      {userIdentifier && (
        <AsideUser
          open={userIdentifier !== null}
          requestClose={() => setUserIdentifier(null)}
          labels={VERTICAL === 'VACCINE' ? ['Sites', 'Role'] : ['Facilities', 'Role']}
          identifier={userIdentifier}
        />
      )}
      {archivedUser && (
        <ModalArchivedUser
          open={archivedUser !== null}
          requestClose={() => setArchivedUser(null)}
          user={archivedUser}
          mutate={mutate}
        />
      )}
      {reactivateUser && (
        <ModalReactivateUser
          open={reactivateUser !== null}
          requestClose={() => setReactivateUser(null)}
          user={reactivateUser}
          mutate={mutate}
        />
      )}
      {editUser && (
        <ModalEditUser
          open={editUser !== null}
          requestClose={() => setEditUser(false)}
          user={editUser}
          sites={sites}
          userMutate={mutate}
          sitesMutate={sitesMutate}
        />
      )}
      {VERTICAL === 'VACCINE' && modalNewUserOpen && sites && sites.length > 0 && (
        <ModalNewUser
          open={modalNewUserOpen}
          requestClose={() => setModalNewUserOpen(false)}
          mutate={mutate}
          sites={sites}
        />
      )}
    </>
  );
};

export default Users;
