import React from 'react';
import PropTypes from 'prop-types';

import { STATUS } from 'src/helpers';

const Vehicule = ({ name, internalId, plate, status, owner, out, qr }) => (
  <div className="grid grid-cols-3 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7">
    <div className="flex items-center col-span-2 focus:outline-none focus:underline">
      <svg viewBox="0 0 40 40" className="h-16 fill-current text-secondary">
        <path
          fillRule="evenodd"
          d="M8.38875 31.7417C8.38875 32.202 8.38875 32.6624 8.38875 33.1228C8.38875 33.7877 7.82609 34.3504 7.16113 34.3504H1.84143C1.17647 34.3504 0.664962 33.7877 0.664962 33.1228C0.664962 29.5422 0.664962 25.9616 0.716113 22.3811C0.767263 18.0844 2.55754 16.8568 2.9156 16.4476H0.767263C0.358056 16.4476 0 16.0895 0 15.6803V14.7084C0 14.2992 0.511509 13.89 0.920716 13.8389L4.65473 13.3274L7.10997 8.72379C7.31458 8.41688 8.6445 6.98465 10.2813 6.7289C16.5729 5.75703 23.376 5.75703 29.7187 6.7289C31.3043 6.98465 32.6343 8.41688 32.8389 8.72379L35.3453 13.3274L39.0793 13.8389C39.4885 13.89 40 14.2992 40 14.7084V15.6803C40 16.0895 39.6419 16.4476 39.2327 16.4476H37.0332C37.4425 16.8568 39.2327 18.0844 39.2839 22.3811C39.2839 25.9616 39.335 29.5422 39.335 33.1228C39.335 33.7877 38.7724 34.3504 38.1586 34.3504H32.7877C32.1228 34.3504 31.6113 33.7877 31.6113 33.1228C31.6113 32.6624 31.6113 32.202 31.6113 31.7417C23.8363 32.202 16.1125 32.202 8.38875 31.7417ZM10.4859 8.92839C9.87212 9.08184 9.30946 9.3376 8.79795 10.1049C8.08184 11.4859 7.36573 12.9182 6.64962 14.2992H33.3504C32.6343 12.9182 31.9182 11.4859 31.202 10.1049C30.6905 9.3376 30.1279 9.08184 29.5141 8.92839C23.1714 8.16113 16.8286 8.16113 10.4859 8.92839ZM36.8286 23.046C34.3734 23.5064 31.9693 23.8133 29.6164 24.3248C29.0537 24.6317 28.6445 25.1432 28.2864 25.757C28.1841 26.0128 28.1841 26.422 28.2353 26.8312C30.5371 26.4731 32.8389 26.1151 35.0895 25.7059C35.8568 25.4501 36.4194 24.8875 36.8286 24.1202C36.9309 23.6087 36.9309 23.2506 36.8286 23.046ZM3.17136 23.046C5.57545 23.5064 7.97954 23.8133 10.3325 24.3248C10.8951 24.6317 11.3555 25.1432 11.7136 25.757C11.7647 26.0128 11.7647 26.422 11.7136 26.8312C9.41177 26.4731 7.16113 26.1151 4.85933 25.7059C4.09207 25.4501 3.52941 24.8875 3.1202 24.1202C3.06905 23.6087 3.06905 23.2506 3.17136 23.046Z"
        />
      </svg>
      <div className="pl-4 text-sm text-left lg:text-base">
        <div>
          <p className="font-bold truncate" style={{ maxWidth: '143px' }}>
            {name}
          </p>
        </div>
        <div className="flex flex-col">
          <span className="block capitalize text-secondary 2xl:hidden">{owner}</span>
          <span className="capitalize text-secondary">{internalId}</span>
          <span className="block capitalize text-secondary xl:hidden">{plate}</span>
        </div>
      </div>
    </div>
    <div className="items-center hidden xl:flex text-secondary">{plate}</div>
    <div className="items-center hidden lg:flex">
      <p className="flex items-center space-x-2 text-lg">
        <strong className={`uppercase ${STATUS[status].color}`}>{status}</strong>
        <span>{STATUS[status].icon}</span>
      </p>
    </div>
    <div className="items-center hidden 2xl:flex text-secondary">{owner}</div>
    <div className="items-center hidden lg:flex text-secondary">{out}</div>
    <div className="items-center hidden lg:flex">{qr}</div>
    <div className="flex flex-col items-end text-sm text-right lg:hidden text-secondary">
      <p className="flex items-center space-x-2">
        <strong className={`uppercase ${STATUS[status].color}`}>{status}</strong>
        <span>{STATUS[status].icon}</span>
      </p>
      <span>{out}</span>
      {qr}
    </div>
  </div>
);

Vehicule.propTypes = {
  name: PropTypes.string.isRequired,
  internalId: PropTypes.string.isRequired,
  plate: PropTypes.string.isRequired,
  status: PropTypes.oneOf(['VERIFIED', 'PENDING', 'UNVERIFIED', 'REVOKED', 'DELETED']).isRequired,
  owner: PropTypes.string.isRequired,
  out: PropTypes.string.isRequired,
  qr: PropTypes.node.isRequired,
};

export default Vehicule;
