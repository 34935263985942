import React from 'react';
import { useSelector } from 'react-redux';

import { Modal } from 'src/components';

import { textFromEvidenceType, formatEpochToPrettyDate } from '../helpers/formatting';

const ModalEvidenceProof = ({ open, requestClose, evidenceLink, employee }) => {
  const user = useSelector((state) => state.user);

  return (
    <Modal open={open} requestClose={requestClose} maxWidth>
      <Modal.Header>
        <h4 className="text-lg font-bold">Evidence</h4>
        <div className="absolute right-0 pr-6">
          <button type="button" onClick={requestClose}>
            <svg viewBox="0 0 20 20" className="w-5 h-5">
              <path d="M16.6952 0.51282C17.4549 -0.17094 18.6705 -0.17094 19.4302 0.51282C20.1899 1.27255 20.1899 2.48813 19.4302 3.24786L12.7445 10.0095L19.4302 16.6952C20.1899 17.4549 20.1899 18.6705 19.4302 19.4302C18.6705 20.1899 17.4549 20.1899 16.6952 19.4302L10.0095 12.7445L3.24786 19.4302C2.48813 20.1899 1.27255 20.1899 0.51282 19.4302C-0.17094 18.6705 -0.17094 17.4549 0.51282 16.6952L7.27445 10.0095L0.51282 3.24786C-0.17094 2.48813 -0.17094 1.27255 0.51282 0.51282C1.27255 -0.17094 2.48813 -0.17094 3.24786 0.51282L10.0095 7.27445L16.6952 0.51282Z" />
            </svg>
          </button>
        </div>
      </Modal.Header>

      <Modal.Body className="flex flex-col items-center lg:mx-10">
        <div className="p-4 m-4" style={{ boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)' }}>
          <img style={{ maxHeight: '64vh' }} src={evidenceLink} alt="Evidence Image" />
        </div>
        <div className="flex flex-row mb-4 space-x-10 text-xs font-bold uppercase text-secondary">
          <div className="flex flex-col py-1 space-y-4 text-center w-44">
            <span>
              <span className="font-normal">Evidence </span>
              Type
            </span>
            <div className="font-normal text-center w-44">
              {textFromEvidenceType(employee.evidences[0]?.evidenceType)}
            </div>
          </div>
          <div className="flex flex-col py-1 space-y-4 text-center w-44">
            <span>
              <span className="font-normal">Evidence </span>Start Date
            </span>
            <div className="font-normal text-center w-44">
              {formatEpochToPrettyDate(employee.evidences[0]?.evidenceDeclaredDate)}
            </div>
          </div>
          <div className="flex flex-col py-1 space-y-4 text-center w-44">
            <span>
              <span className="font-normal">Evidence </span>Upload Date
            </span>
            <div className="font-normal text-center w-44">
              {formatEpochToPrettyDate(employee.evidences[0]?.createdAt)}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalEvidenceProof;
