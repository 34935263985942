import React from 'react';
import PropTypes from 'prop-types';
import useSWR from 'swr';

import { Spinner } from 'src/components';
import Badge from './Badge';
import Informations from './Informations';
import Actions from './Actions';

const PropertyBadge = ({ id, externalId, dashboardId }) => {
  const { data, error } = useSWR(id ? [`/facilities/${id}/badge`] : null);

  if (error) {
    return (
      <section className="px-8 py-6 lg:border-b md:px-6 md:py-12 lg:px-12 xl:px-24">
        <div className="flex flex-col md:flex-row">
          <div className="md:px-8 md:flex-grow md:flex md:items-center">
            <span>An error occured.</span>
          </div>
        </div>
      </section>
    );
  }

  if (!data) {
    return (
      <section className="px-8 py-6 lg:border-b md:px-6 md:py-12 lg:px-12 xl:px-24">
        <div className="flex text-primary-light">
          <Spinner />
        </div>
      </section>
    );
  }

  return (
    <section className="px-8 py-6 lg:border-b md:px-6 md:py-12 lg:px-12 xl:px-24">
      <div className="flex flex-col md:flex-row">
        <div>
          <Badge status={data.status} externalId={externalId} />
        </div>
        <div className="md:px-8 md:flex-grow md:flex md:items-center">
          <div className="space-y-6 md:flex-grow">
            <Informations data={data} />
            <Actions status={data.status} externalId={externalId} dashboardId={dashboardId} />
          </div>
        </div>
      </div>
    </section>
  );
};

PropertyBadge.propTypes = {
  id: PropTypes.string.isRequired,
  externalId: PropTypes.string.isRequired,
  dashboardId: PropTypes.string.isRequired,
};

export default PropertyBadge;
