import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import debounce from 'lodash.debounce';
import * as Yup from 'yup';

import { httpClient } from 'src/helpers';
import UserPicker from './UserPicker';

const marketingSchema = Yup.object().shape({
  marketing: Yup.object().shape({
    manager: Yup.object().shape({
      firstName: Yup.string().max(250, 'forms.too_long').required('forms.required'),
      lastName: Yup.string().max(250, 'forms.too_long').required('forms.required'),
      title: Yup.string().max(250, 'forms.too_long').required('forms.required'),
      email: Yup.string()
        .email('forms.format')
        .required('forms.required')
        .when('newUser', {
          is: true,
          then: Yup.string()
            .email('forms.format')
            .max(250, 'forms.too_long')
            .required('forms.required')
            .test(
              'manager-email-backend-validation',
              'forms.emailUsed',
              debounce(async (email) => {
                try {
                  if (!email || email === '') return true;
                  const { data } = await httpClient.get(`/users?email=${email.toLowerCase()}`);
                  return !data.length;
                } catch (error) {
                  return false;
                }
              }, 750)
            ),
        }),
      phone: Yup.string()
        .max(250, 'forms.too_long')
        .test('phone-match-regex', 'forms.format', (phone) => {
          if (!phone || phone === '') return true;
          return phone.match(
            /^([+(]?|\d{1}|(\+?\d{2}))[ ]?(\(?\d{1,4}\)?)[- ]?\d{1,4}[- ]?\d{1,4}([- ]?\d{1,5})?$/
          );
        })
        .nullable(),
    }),
    lead: Yup.object().shape({
      firstName: Yup.string().max(250, 'forms.too_long').required('forms.required'),
      lastName: Yup.string().max(250, 'forms.too_long').required('forms.required'),
      title: Yup.string().max(250, 'forms.too_long').required('forms.required'),
      email: Yup.string()
        .email('forms.format')
        .required('forms.required')
        .when('newUser', {
          is: true,
          then: Yup.string()
            .email('forms.format')
            .max(250, 'forms.too_long')
            .required('forms.required')
            .test(
              'manager-email-backend-validation',
              'forms.emailUsed',
              debounce(async (email) => {
                try {
                  if (!email || email === '') return true;
                  const { data } = await httpClient.get(`/users?email=${email.toLowerCase()}`);
                  return !data.length;
                } catch (error) {
                  return false;
                }
              }, 750)
            ),
        }),
      phone: Yup.string()
        .max(250, 'forms.too_long')
        .test('phone-match-regex', 'forms.format', (phone) => {
          if (!phone || phone === '') return true;
          return phone.match(
            /^([+(]?|\d{1}|(\+?\d{2}))[ ]?(\(?\d{1,4}\)?)[- ]?\d{1,4}[- ]?\d{1,4}([- ]?\d{1,5})?$/
          );
        })
        .nullable(),
    }),
  }),
});

const buildUserList = (manager, users) =>
  !manager?.newUser ? [...users] : [...users, { ...manager, isManager: true, roles: ['MANAGER'] }];

const MarketingForm = ({ users }) => {
  const { t } = useTranslation('dashboard');
  const [{ value }] = useField('manager');

  const [selectableUsers] = useState(
    buildUserList(value, users).sort((a, b) => a.email.localeCompare(b.email))
  );

  return (
    <div className="pt-12 pb-32 space-y-6">
      <div>
        <p>{t('forms.marketing.introduction')}</p>
        <p className="flex items-center p-4 mt-2 space-x-4 bg-purple-500 rounded-md bg-opacity-10">
          <svg viewBox="0 0 12 12" className="flex-shrink-0 w-6 h-6">
            <path
              d="M6 0C2.68661 0 0 2.68661 0 6C0 9.31339 2.68661 12 6 12C9.31339 12 12 9.31339 12 6C12 2.68661 9.31339 0 6 0ZM6 9.48214C5.70402 9.48214 5.46429 9.24241 5.46429 8.94643C5.46429 8.65045 5.70402 8.41071 6 8.41071C6.29598 8.41071 6.53571 8.65045 6.53571 8.94643C6.53571 9.24241 6.29598 9.48214 6 9.48214ZM6.84241 6.54241C6.72112 6.58925 6.61676 6.67155 6.54294 6.77858C6.46912 6.8856 6.42926 7.01239 6.42857 7.14241V7.44643C6.42857 7.50536 6.38036 7.55357 6.32143 7.55357H5.67857C5.61964 7.55357 5.57143 7.50536 5.57143 7.44643V7.15848C5.57143 6.84911 5.66116 6.54375 5.83795 6.28929C6.01071 6.04018 6.25179 5.85 6.53571 5.74152C6.99107 5.56607 7.28571 5.18438 7.28571 4.76786C7.28571 4.17723 6.70848 3.69643 6 3.69643C5.29152 3.69643 4.71429 4.17723 4.71429 4.76786V4.86964C4.71429 4.92857 4.66607 4.97679 4.60714 4.97679H3.96429C3.90536 4.97679 3.85714 4.92857 3.85714 4.86964V4.76786C3.85714 4.24152 4.0875 3.75 4.50536 3.38438C4.90714 3.03214 5.4375 2.83929 6 2.83929C6.5625 2.83929 7.09286 3.03348 7.49464 3.38438C7.9125 3.75 8.14286 4.24152 8.14286 4.76786C8.14286 5.54196 7.63259 6.23839 6.84241 6.54241Z"
              fill="#5243AA"
            />
          </svg>

          <span className="text-sm">{t('forms.marketing.hint')}</span>
        </p>
      </div>

      <div className="space-y-4">
        <span className="text-xl font-bold">{t('forms.marketing.manager.title')}</span>
        <UserPicker
          name="marketing.manager"
          options={selectableUsers}
          addUserWithId={() => {}}
          removeUserWithId={() => {}}
        />
      </div>

      <div className="space-y-4">
        <div>
          <span className="text-xl font-bold">{t('forms.marketing.lead.title')}</span>
          <p className="pt-2 text-secondary">{t('forms.marketing.lead.description')}</p>
        </div>

        <UserPicker
          name="marketing.lead"
          options={selectableUsers}
          addUserWithId={() => {}}
          removeUserWithId={() => {}}
        />
      </div>
    </div>
  );
};

MarketingForm.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
};

export { marketingSchema };

export default MarketingForm;
