import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { v1 as uuidv1 } from 'uuid';
import useSWR from 'swr';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { httpClient } from 'src/helpers';
import { Modal, Snackbar, Spinner, Button } from 'src/components';
import EditUserForm from './EditUserForm';

const editUserSchema = Yup.object().shape({
  firstName: Yup.string().max(250, 'forms.too_long').required('forms.required'),
  lastName: Yup.string().max(250, 'forms.too_long').required('forms.required'),
  phone: Yup.string()
    .max(250, 'forms.too_long')
    .matches(
      /^([+(]?|\d{1}|(\+?\d{2}))[ ]?(\(?\d{1,4}\)?)[- ]?\d{1,4}[- ]?\d{1,4}([- ]?\d{1,5})?$/,
      'forms.format'
    ),
  sites: Yup.array()
    .of(
      Yup.object().shape({
        data: Yup.object().shape({
          id: Yup.string().required('forms.required'),
          name: Yup.string().required('forms.required'),
          address: Yup.object().shape({
            city: Yup.string().required('forms.required'),
            country: Yup.string().required('forms.required'),
          }),
        }),
        role: Yup.object().shape(
          {
            sme: Yup.boolean().when('drm', { is: false, then: Yup.boolean().oneOf([true]) }),
            drm: Yup.boolean().when('sme', { is: false, then: Yup.boolean().oneOf([true]) }),
          },
          [['sme', 'drm']]
        ),
      })
    )
    .min(1, 'forms.required'),
});

const computedSites = (sites) =>
  sites.map((s) => ({
    id: uuidv1(),
    data: {
      id: s.id,
      name: s.name,
      address: { city: s.address.city, country: s.address.country },
    },
    role: {
      sme: s.role.includes('viewer'),
      drm: s.role.includes('manager'),
    },
  }));

const ModalEditUser = ({ open, requestClose, user, sites, userMutate, sitesMutate }) => {
  const [snackbarState, setSnackbarState] = useState({ type: 'HIDDEN', msg: '' });
  const { data, error } = useSWR(`/users/${user.id}/facilities`, {
    revalidateOnFocus: false,
    revalidateOnReconnect: true,
    refreshWhenOffline: false,
    refreshWhenHidden: false,
    refreshInterval: 0,
  });

  const handleSubmit = async (values, actions) => {
    if (window.gtag) window.gtag('event', 'Update_user');
    setSnackbarState({ type: 'LOADING' });
    actions.setSubmitting(true);

    try {
      document.getElementById('modal-body').scroll({ top: 0, behavior: 'smooth' });
      const { status } = await httpClient.put(`/users/${user.id}`, values);

      if (status === 200) {
        setSnackbarState({ type: 'SUCCESS' });
        userMutate();
        sitesMutate();

        setTimeout(() => {
          requestClose();
        }, 1000);
      } else throw Error('Facility creation failed.');
    } catch (err) {
      setSnackbarState({ type: 'ERROR' });
      actions.setSubmitting(false);
    }
  };

  return (
    <Modal open={open} requestClose={requestClose}>
      {!error && data ? (
        <>
          <Modal.Header>
            <div className="h-5" />
            <div className="absolute right-0 pr-6">
              <button type="button" onClick={requestClose}>
                <svg viewBox="0 0 20 20" className="w-5 h-5">
                  <path d="M16.6952 0.51282C17.4549 -0.17094 18.6705 -0.17094 19.4302 0.51282C20.1899 1.27255 20.1899 2.48813 19.4302 3.24786L12.7445 10.0095L19.4302 16.6952C20.1899 17.4549 20.1899 18.6705 19.4302 19.4302C18.6705 20.1899 17.4549 20.1899 16.6952 19.4302L10.0095 12.7445L3.24786 19.4302C2.48813 20.1899 1.27255 20.1899 0.51282 19.4302C-0.17094 18.6705 -0.17094 17.4549 0.51282 16.6952L7.27445 10.0095L0.51282 3.24786C-0.17094 2.48813 -0.17094 1.27255 0.51282 0.51282C1.27255 -0.17094 2.48813 -0.17094 3.24786 0.51282L10.0095 7.27445L16.6952 0.51282Z" />
                </svg>
              </button>
            </div>
          </Modal.Header>
          <Modal.Body className="px-6">
            {error && <div>An error occured.</div>}
            {!error && !data && (
              <div className="flex items-center justify-center h-12 mb-12 text-primary-light">
                <Spinner />
              </div>
            )}
            {!error && data && (
              <Formik
                initialValues={{ ...user, sites: computedSites(data) }}
                validationSchema={editUserSchema}
                onSubmit={handleSubmit}
              >
                {({ isValid, isSubmitting, dirty }) => (
                  <Form className="max-w-md mx-auto" autoComplete="off">
                    <Snackbar
                      type={snackbarState.type}
                      message={snackbarState.msg}
                      requestClose={() => setSnackbarState({ ...snackbarState, type: 'HIDDEN' })}
                    />

                    <EditUserForm sites={sites} />

                    <div className="flex items-center justify-center pb-6">
                      <div className="w-full max-w-md">
                        <Button
                          type="submit"
                          size="md"
                          disabled={
                            !(isValid && dirty) || isSubmitting || snackbarState.type === 'SUCCESS'
                          }
                        >
                          Update User
                        </Button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </Modal.Body>
        </>
      ) : (
        <div className="flex items-center justify-center p-6 text-primary-light">
          <Spinner />
        </div>
      )}
    </Modal>
  );
};

ModalEditUser.propTypes = {
  open: PropTypes.bool.isRequired,
  requestClose: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
  }).isRequired,
  sites: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.node.isRequired,
      name: PropTypes.string.isRequired,
      address: PropTypes.shape({
        city: PropTypes.string.isRequired,
        country: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
  userMutate: PropTypes.func.isRequired,
  sitesMutate: PropTypes.func.isRequired,
};

export default ModalEditUser;
