import React from 'react';
import PropTypes from 'prop-types';

import URLS from 'src/constants/urls.json';
import UnverifiedIcon from 'src/assets/images/badge_unverified.png';
import VerifiedIcon from 'src/assets/images/badge_verified.png';
import HospitalityVerifiedIcon from 'src/assets/images/badge_hospitality.png';

const badgeIcon = (st) => {
  if (process.env.GATSBY_VERTICAL === 'HOSPITALITY')
    return st === 'VERIFIED' ? HospitalityVerifiedIcon : UnverifiedIcon;
  return st === 'VERIFIED' ? VerifiedIcon : UnverifiedIcon;
};

const getBadgeLink = () => {
  const VERTICAL = process.env.GATSBY_VERTICAL;
  const DEPLOYMENT = process.env.GATSBY_DEPLOYMENT;

  switch (VERTICAL) {
    case 'FTG':
      return URLS.BOT[DEPLOYMENT];
    case 'DEKRA':
      return URLS.DEKRA[DEPLOYMENT];
    default:
      return URLS.ASSESSMENT[DEPLOYMENT];
  }
};

const Badge = ({ status, externalId }) => (
  <a
    href={`${getBadgeLink()}/certification/${externalId}`}
    target="_blank"
    rel="noreferrer"
    className="flex items-center justify-center"
  >
    <div className="flex items-center justify-center w-48 h-48 p-8 bg-gray-100 rounded-lg">
      <img
        src={badgeIcon(status)}
        alt="Verified"
        className={status === 'PENDING' || status === 'REVOKED' ? 'opacity-10' : 'opacity-100'}
      />
    </div>
  </a>
);

Badge.propTypes = {
  status: PropTypes.oneOf(['PENDING', 'VERIFIED', 'UNVERIFIED', 'REVOKED']).isRequired,
  externalId: PropTypes.string.isRequired,
};

export default Badge;
