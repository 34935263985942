import React from 'react';
import PropTypes from 'prop-types';

import { Avatar } from 'src/components';

const Manager = ({ type, firstName, lastName, email, roles }) => (
  <div className="px-6 py-4 border rounded-lg">
    <span className="text-lg font-bold">{type}</span>

    <div className="flex items-center pt-4 space-x-4">
      <Avatar initials={`${firstName.slice(0, 1)}${lastName.slice(0, 1)}`} role={roles[0]} />
      <div className="flex flex-col">
        <span className="text-base capitalize">{`${firstName} ${lastName}`}</span>
        <p className="text-sm truncate text-secondary" style={{ maxWidth: '220px' }}>
          {email}
        </p>
      </div>
    </div>
  </div>
);

Manager.propTypes = {
  type: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

export default Manager;
