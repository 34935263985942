import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { v1 as uuidv1 } from 'uuid';
import useSWR from 'swr';
import { FieldArray, useFormikContext } from 'formik';
import AsyncSelect from 'react-select/async';

import { httpClient, fetchOnce } from 'src/helpers';
import { Spinner } from 'src/components';
import { AssignmentForm } from 'src/views/app/shared';

import NewTripForm from './NewTripForm';

const filterShip = (options, input) => {
  const match = (i) => i.name.toLowerCase().includes(input.toLowerCase());

  if (!input || input === '') return options;
  return options.filter(match);
};

const formatOptionLabel = ({ name }) => (
  <div className="flex items-center pr-4 space-x-4">
    <svg viewBox="0 0 70 70" className="flex-shrink-0 w-8 h-8 fill-current text-secondary">
      <path d="M34.1388 40.9258H5.25L10.25 66.4813L34.1388 69.9998L58.0276 66.4813L63.0276 40.9258H34.1388Z" />
      <path d="M60.7125 19.4444H51.9162V14.7222H40.3421L38.6755 2.49999H40.157V0H34.6014H33.4903H27.9348V2.49999H29.4162L27.7496 14.7222H16.3607V19.4444H7.56445V39.1666H60.7125V19.4444ZM19.4163 31.111H13.3978V25.0925H19.4163V31.111ZM28.3051 31.111H22.2866V25.0925H28.3051V31.111ZM37.1014 31.111H31.0829V25.0925H37.1014V31.111ZM45.9903 31.111H39.9718V25.0925H45.9903V31.111ZM54.8791 31.111H48.8606V25.0925H54.8791V31.111Z" />
    </svg>
    <p className="text-sm font-medium text-center truncate-2">{name}</p>
  </div>
);

const NewJourneyForm = ({ topics }) => {
  const user = useSelector((state) => state.user);
  const { values, setFieldValue } = useFormikContext();

  const { data: dataUsers, error: errorUsers } = useSWR(
    values.ship ? [`/subclients/${values.ship.id}/users?pages=false`] : null,
    {
      ...fetchOnce,
      onSuccess: (users) => {
        setFieldValue(
          'assignments',
          users
            .sort((a, b) => a.email.localeCompare(b.email))
            .map((e) => ({
              user: e,
              topics: topics.map((t) => ({ ...t, selected: users.length <= 1 })),
            }))
        );
      },
    }
  );

  const fetchShips = (input) =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`/clients/${user.clientId}/subclients`)
        .then(({ data }) =>
          resolve(
            filterShip(
              data.map((d) => ({ ...d, value: d.id })).sort((a, b) => a.name.localeCompare(b.name)),
              input
            )
          )
        )
        .catch((e) => reject(e));
    });

  return (
    <div className="max-w-md pt-4 pb-12">
      <div className="space-y-1">
        <span className="text-sm">Select a ship*</span>
        <AsyncSelect
          cacheOptions
          defaultOptions
          classNamePrefix="r-select"
          loadOptions={fetchShips}
          formatOptionLabel={formatOptionLabel}
          placeholder=""
          closeMenuOnSelect
          onChange={(value) => {
            setFieldValue('ship', value);
            setFieldValue('assignments', []);
          }}
          value={values.ship}
        />
      </div>

      <FieldArray name="trips">
        {({ remove, push }) => (
          <div className="pt-12 space-y-6">
            <span className="text-xl font-bold">Journey(s)*</span>

            <div className="space-y-10">
              {values.trips.map((trip, index) => (
                <NewTripForm
                  key={trip.id}
                  index={index}
                  country={trip.data.country}
                  remove={remove}
                  removable={values.trips.length > 1}
                />
              ))}
            </div>

            <div className="flex items-center justify-end">
              <button
                type="button"
                className="flex flex-row items-center space-x-2 font-bold text-blue-400 hover:underline focus:underline focus:outline-none focus:text-blue-900 hover:text-blue-900"
                onClick={() =>
                  push({
                    id: uuidv1(),
                    data: { homeport: '', country: 'US', sails: [''], repeat: '', end: '' },
                  })
                }
              >
                <svg viewBox="0 0 17 17" className="w-4 h-4 fill-current">
                  <path d="M7.31339 1.62251C7.34568 1.00902 7.8623 0.492403 8.47578 0.460114C9.12156 0.460114 9.63818 0.976734 9.63818 1.62251L9.67046 7.33761H15.3533C15.999 7.33761 16.5157 7.85423 16.5157 8.5C16.5157 9.14577 15.999 9.66239 15.3533 9.66239H9.67046L9.63818 15.3775C9.63818 16.0233 9.12156 16.5399 8.47578 16.5399C7.8623 16.5076 7.34568 15.991 7.31339 15.3775L7.34568 9.66239L1.59829 9.66239C0.984805 9.6301 0.468186 9.11349 0.435897 8.5C0.468186 7.88651 0.984805 7.3699 1.59829 7.33761L7.34568 7.33761L7.31339 1.62251Z" />
                </svg>
                <span>Add journey</span>
              </button>
            </div>
          </div>
        )}
      </FieldArray>

      <div className="pt-12 space-y-8">
        <div>
          <span className="text-xl font-bold">Assignments</span>
          <p className="pt-1 text-sm text-secondary">
            {!values.ship
              ? 'Please select a ship to manage your assignments'
              : 'Select the application Health Security sections for each user.'}
          </p>
        </div>
        {values.ship && values.assignments.length <= 0 && !errorUsers && (
          <div className="flex items-center justify-center text-primary-light">
            <Spinner />
          </div>
        )}
        {errorUsers && !dataUsers && <div>An error occured.</div>}
        {values.assignments.map((assignment, i) => (
          <AssignmentForm
            key={assignment.user.id}
            index={i}
            user={assignment.user}
            topics={assignment.topics}
            defaultOpen
          />
        ))}
      </div>
    </div>
  );
};

NewJourneyForm.propTypes = {
  topics: PropTypes.arrayOf(
    PropTypes.shape({
      topicMasterId: PropTypes.string.isRequired,
      topicName: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};

export default NewJourneyForm;
