import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import URLS from 'src/constants/urls.json';
import { Modal } from 'src/components';

const getLink = () => {
  const VERTICAL = process.env.GATSBY_VERTICAL;
  const DEPLOYMENT = process.env.GATSBY_DEPLOYMENT;

  switch (VERTICAL) {
    case 'FTG':
      return URLS.BOT[DEPLOYMENT];
    case 'DEKRA':
      return URLS.DEKRA[DEPLOYMENT];
    default:
      return URLS.ASSESSMENT[DEPLOYMENT];
  }
};

const ModalManager = ({ open, requestClose }) => {
  const { t } = useTranslation('facility');

  return (
    <Modal open={open} requestClose={requestClose}>
      <Modal.Header>
        <div className="h-5" />
        <div className="absolute right-0 pr-6">
          <button type="button" onClick={requestClose}>
            <svg viewBox="0 0 20 20" className="w-5 h-5">
              <path d="M16.6952 0.51282C17.4549 -0.17094 18.6705 -0.17094 19.4302 0.51282C20.1899 1.27255 20.1899 2.48813 19.4302 3.24786L12.7445 10.0095L19.4302 16.6952C20.1899 17.4549 20.1899 18.6705 19.4302 19.4302C18.6705 20.1899 17.4549 20.1899 16.6952 19.4302L10.0095 12.7445L3.24786 19.4302C2.48813 20.1899 1.27255 20.1899 0.51282 19.4302C-0.17094 18.6705 -0.17094 17.4549 0.51282 16.6952L7.27445 10.0095L0.51282 3.24786C-0.17094 2.48813 -0.17094 1.27255 0.51282 0.51282C1.27255 -0.17094 2.48813 -0.17094 3.24786 0.51282L10.0095 7.27445L16.6952 0.51282Z" />
            </svg>
          </button>
        </div>
      </Modal.Header>
      <Modal.Body className="max-w-md px-6 mx-auto">
        <div className="space-y-6 ">
          <span className="text-2xl font-bold">{t('forms.modal.drm.title')}</span>
          <p>{t('forms.modal.drm.description')}</p>
        </div>

        <div className="flex items-center justify-center pt-12 pb-6">
          <a
            href={`${getLink()}/healthsecuritycertification/healthSecurityverification.html`}
            target="_blank"
            rel="noreferrer"
            className="w-full py-4 text-center text-white transition border-2 rounded-md whitespace-nowrap focus:outline-none bg-primary-light border-primary-light hover:bg-primary-light-hover hover:border-primary-light-hover focus:border-primary-light-hover focus:bg-primary-light-hover"
          >
            {t('forms.modal.drm.link')}
          </a>
        </div>
      </Modal.Body>
    </Modal>
  );
};

ModalManager.propTypes = {
  open: PropTypes.bool.isRequired,
  requestClose: PropTypes.func.isRequired,
};

export default ModalManager;
