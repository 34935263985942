import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { httpClient } from 'src/helpers';
import { Modal, Spinner, Snackbar } from 'src/components';

const ModalDeleteJourney = ({ open, requestClose, mutate, journeyId }) => {
  const [snackbarState, setSnackbarState] = useState({ type: 'HIDDEN', msg: '' });
  const [isArchiving, setIsArchiving] = useState(false);

  const handleConfirm = async () => {
    if (window.gtag) window.gtag('event', 'Delete_user');
    setIsArchiving(true);

    try {
      const { status } = await httpClient.delete(`/journeys/${journeyId}`);

      if (status === 200) {
        setTimeout(() => {
          setSnackbarState({ type: 'SUCCESS' });
        }, 1000);

        setTimeout(() => {
          mutate();
          requestClose();
        }, 2000);
      } else throw Error('Disabled user failed.');
    } catch (error) {
      setSnackbarState({ type: 'ERROR' });
      setIsArchiving(false);
    }
  };

  const handleCancel = () => {
    setIsArchiving(false);
    requestClose();
  };

  return (
    <Modal open={open} requestClose={requestClose}>
      {!isArchiving ? (
        <Modal.Body className="px-6 pt-6">
          <Snackbar
            type={snackbarState.type}
            message={snackbarState.msg}
            requestClose={() => setSnackbarState({ ...snackbarState, type: 'HIDDEN' })}
          />
          <div className="max-w-md mx-auto">
            <div className="pt-6 pb-12">
              <h5 className="font-bold">Do you want to remove this journey ?</h5>
              <p className="pt-2 text-secondary">
                Users will no longer have access to the journey.
              </p>
            </div>
            <div className="flex items-center justify-center pb-6">
              <div className="flex items-center w-full space-x-4">
                <button
                  type="button"
                  onClick={() => handleCancel()}
                  className="w-1/2 py-4 font-semibold transition border-2 rounded-lg select-none focus:outline-none hover:bg-gray-200 focus:bg-gray-200"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={() => handleConfirm()}
                  className="w-full py-4 font-semibold transition border-2 rounded-lg select-none border-danger text-danger hover:border-danger-light focus:border-danger-light hover:bg-danger-light focus:bg-danger-light hover:text-white focus:text-white focus:outline-none"
                >
                  Delete Journey
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      ) : (
        <div className="flex justify-center p-6 text-primary-light">
          <Spinner />
        </div>
      )}
    </Modal>
  );
};

ModalDeleteJourney.propTypes = {
  open: PropTypes.bool.isRequired,
  requestClose: PropTypes.func.isRequired,
  mutate: PropTypes.func.isRequired,
  journeyId: PropTypes.string.isRequired,
};

export default ModalDeleteJourney;
