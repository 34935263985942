import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import debounce from 'lodash.debounce';

import { httpClient } from 'src/helpers';
import { Modal, Snackbar, Button } from 'src/components';
import NewUserForm from './NewUserForm';

const newUserSchema = Yup.object().shape({
  user: Yup.object().shape({
    firstName: Yup.string().max(250, 'forms.too_long').required('forms.required'),
    lastName: Yup.string().max(250, 'forms.too_long').required('forms.required'),
    email: Yup.string()
      .email('forms.format')
      .required('forms.required')
      .when('newUser', {
        is: true,
        then: Yup.string()
          .email('forms.format')
          .max(250, 'forms.too_long')
          .required('forms.required')
          .test(
            'manager-email-backend-validation',
            'forms.emailUsed',
            debounce(async (email) => {
              try {
                if (!email || email === '') return true;
                const { data } = await httpClient.get(`/users?email=${email.toLowerCase()}`);
                return !data.length;
              } catch (error) {
                return false;
              }
            }, 750)
          ),
      }),
    title: Yup.string().max(250, 'forms.too_long').required('forms.required'),
    phone: Yup.string()
      .max(250, 'forms.too_long')
      .test('phone-regex', 'forms.format', (phone) => {
        if (!phone) return true;
        return phone.match(
          /^([+(]?|\d{1}|(\+?\d{2}))[ ]?(\(?\d{1,4}\)?)[- ]?\d{1,4}[- ]?\d{1,4}([- ]?\d{1,5})?$/
        );
      })
      .nullable(),
  }),
  assignments: Yup.object().shape({
    previous: Yup.mixed().oneOf(['0', '1']).required('forms.required'),
  }),
});

const initialValues = {
  user: {
    newUser: true,
    firstName: '',
    lastName: '',
    email: '',
    title: '',
    phone: '',
  },
  assignments: {
    previous: '',
    topics: [],
  },
};

const ModalNewUser = ({ open, requestClose, mutate, shipId, shipUsers, topics }) => {
  const [snackbarState, setSnackbarState] = useState({ type: 'HIDDEN', msg: '' });

  const handleSubmit = async (formValues, actions) => {
    if (window.gtag) window.gtag('event', 'Add_user');
    const { newUser, ...values } = formValues;

    setSnackbarState({ type: 'LOADING' });
    actions.setSubmitting(true);

    try {
      document.getElementById('modal-body').scroll({ top: 0, behavior: 'smooth' });
      const { status } = await httpClient.post(`/subclients/${shipId}/user`, values);

      if (status < 400) {
        setSnackbarState({ type: 'SUCCESS' });
        mutate();

        setTimeout(() => {
          requestClose();
        }, 1000);
      } else throw Error('Adding user to ship failed.');
    } catch (err) {
      setSnackbarState({ type: 'ERROR' });
      actions.setSubmitting(false);
    }
  };

  return (
    <Modal open={open} requestClose={requestClose}>
      <Modal.Header>
        <div className="h-5" />
        <div className="absolute right-0 pr-6">
          <button type="button" onClick={requestClose}>
            <svg viewBox="0 0 20 20" className="w-5 h-5">
              <path d="M16.6952 0.51282C17.4549 -0.17094 18.6705 -0.17094 19.4302 0.51282C20.1899 1.27255 20.1899 2.48813 19.4302 3.24786L12.7445 10.0095L19.4302 16.6952C20.1899 17.4549 20.1899 18.6705 19.4302 19.4302C18.6705 20.1899 17.4549 20.1899 16.6952 19.4302L10.0095 12.7445L3.24786 19.4302C2.48813 20.1899 1.27255 20.1899 0.51282 19.4302C-0.17094 18.6705 -0.17094 17.4549 0.51282 16.6952L7.27445 10.0095L0.51282 3.24786C-0.17094 2.48813 -0.17094 1.27255 0.51282 0.51282C1.27255 -0.17094 2.48813 -0.17094 3.24786 0.51282L10.0095 7.27445L16.6952 0.51282Z" />
            </svg>
          </button>
        </div>
      </Modal.Header>
      <Modal.Body className="px-6">
        <Formik
          initialValues={{
            ...initialValues,
            assignments: {
              previous: true,
              topics: topics.map((t) => ({ ...t, selected: false })),
            },
          }}
          validationSchema={newUserSchema}
          onSubmit={handleSubmit}
        >
          {({ isValid, isSubmitting, dirty }) => (
            <Form className="max-w-md mx-auto" autoComplete="off">
              <Snackbar
                type={snackbarState.type}
                message={snackbarState.msg}
                requestClose={() => setSnackbarState({ ...snackbarState, type: 'HIDDEN' })}
              />

              <NewUserForm shipUsers={shipUsers} />

              <div className="flex pb-6">
                <Button
                  type="submit"
                  size="md"
                  disabled={!(isValid && dirty) || isSubmitting || snackbarState.type === 'SUCCESS'}
                >
                  Add User
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

ModalNewUser.propTypes = {
  open: PropTypes.bool.isRequired,
  requestClose: PropTypes.func.isRequired,
  mutate: PropTypes.func.isRequired,
  shipId: PropTypes.string.isRequired,
  shipUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,
  topics: PropTypes.arrayOf(
    PropTypes.shape({
      topicMasterId: PropTypes.string.isRequired,
      topicName: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};

export default ModalNewUser;
