import React from 'react';
import PropTypes from 'prop-types';

import { mapRoles } from 'src/helpers';
import { Avatar, UserSettingsDropdown } from 'src/components';

const { GATSBY_VERTICAL } = process.env;

const classFromStatus = (st) => {
  switch (st) {
    case 'INVITED':
      return 'bg-gray-400 bg-opacity-20 text-gray-400';
    case 'DISABLED':
      return 'bg-red-400 bg-opacity-20 text-red-400';
    default:
      return 'bg-primary-light bg-opacity-20 text-primary-light';
  }
};

const getTopicWithId = (id, topics) => {
  const index = topics.findIndex((topic) => topic.topicMasterId === id);
  return index !== -1 ? topics[index].topicName : null;
};

const User = ({ user, topics, openEdit, openDelete, openResend, canEdit, canResend, settings }) => {
  const settingsOptions = [
    { label: 'Edit user', event: openEdit, active: canEdit },
    { label: 'Resend welcome email', event: openResend, active: canResend },
    {
      label: 'Anonymize data',
      event: () => {},
      active: false,
      hide: !['VULCAIN', 'DEKRA'].includes(GATSBY_VERTICAL),
    },
    {
      label: 'Remove user',
      event: openDelete,
      active: user.canBeRemoved || false,
      danger: true,
    },
  ];

  return (
    <div className="grid p-4 md:gap-y-0 md:grid-cols-6 xl:grid-cols-8 2xl:grid-cols-9 gap-x-2">
      <div className="flex items-center col-span-1 col-start-1 row-start-1 space-x-4 md:col-span-2">
        <Avatar
          initials={`${user.firstName.slice(0, 1)}${user.lastName.slice(0, 1)}`}
          role={user.roles[0]}
        />
        <div className="flex flex-col">
          <p className="font-semibold capitalize truncate" style={{ maxWidth: '200px' }}>
            {`${user.firstName} ${user.lastName}`}
          </p>
          <small className="lowercase truncate text-secondary" style={{ maxWidth: '200px' }}>
            {user.email}
          </small>
        </div>
      </div>

      <div className="items-center justify-center hidden 2xl:flex">
        <p className="truncate">{user.title || 'Viewer'}</p>
      </div>

      {GATSBY_VERTICAL !== 'GREENSHIELD' && user.topics.length && topics.length ? (
        <div className="flex items-center justify-start col-start-1 row-start-2 md:row-start-auto md:col-start-auto lg:justify-center md:col-span-3 xl:col-span-4">
          <div className="flex flex-row flex-wrap items-center justify-start gap-2 pt-4 md:pt-0 lg:px-4 lg:justify-center">
            {user.topics.map((topic) => {
              const topicName = getTopicWithId(topic, topics);

              if (topicName)
                return (
                  <div
                    key={topic}
                    className="px-2 py-1 text-xs bg-gray-100 rounded-md text-secondary"
                  >
                    {topicName}
                  </div>
                );
              return null;
            })}
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center col-start-1 row-start-2 md:row-start-auto md:col-start-auto md:col-span-3 xl:col-span-4">
          <div className="flex-col items-center justify-center hidden col-span-4 md:flex">
            {user.roles
              .filter((r) => r !== 'USER' && r !== 'ADMIN')
              .map((role, index) => (
                <span key={index} className="text-sm capitalize text-secondary">
                  {mapRoles(role)}
                </span>
              ))}
          </div>
        </div>
      )}

      <div className="items-center justify-center hidden xl:flex">
        {user.status && (
          <div className={`px-2 py-1 capitalize rounded text-sm ${classFromStatus(user.status)}`}>
            {user.status.toLowerCase()}
          </div>
        )}
      </div>

      <div className="flex items-center justify-end col-start-2 row-span-2 row-start-1 space-x-6 md:row-start-auto md:col-start-auto md:row-span-1">
        {settings ? <UserSettingsDropdown options={settingsOptions} /> : <div />}
      </div>
    </div>
  );
};

User.propTypes = {
  user: PropTypes.shape({
    lastName: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    roles: PropTypes.arrayOf(PropTypes.string).isRequired,
    title: PropTypes.string.isRequired,
    topics: PropTypes.arrayOf(PropTypes.string.isRequired),
    canBeRemoved: PropTypes.bool.isRequired,
    status: PropTypes.oneOf(['REGISTERED', 'INVITED', 'DISABLED']).isRequired,
  }).isRequired,
  topics: PropTypes.arrayOf(
    PropTypes.shape({
      topicMasterId: PropTypes.string.isRequired,
      topicName: PropTypes.string.isRequired,
    }).isRequired
  ),
  openEdit: PropTypes.func.isRequired,
  openDelete: PropTypes.func.isRequired,
  openResend: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
  canResend: PropTypes.bool.isRequired,
  settings: PropTypes.bool.isRequired,
};

User.defaultProps = {
  topics: null,
};

export default User;
