import React from 'react';
import PropTypes from 'prop-types';

import { mapRoles } from 'src/helpers';
import { Avatar, UserSettingsDropdown } from 'src/components';

const classFromStatus = (st) => {
  switch (st) {
    case 'INVITED':
      return 'bg-gray-400 bg-opacity-20 text-gray-400';
    case 'DISABLED':
      return 'bg-red-400 bg-opacity-20 text-red-400';
    default:
      return 'bg-primary-light bg-opacity-20 text-primary-light';
  }
};

const User = ({ user, openEdit, openDelete, openResend, canEdit, canResend, settings }) => {
  const settingsOptions = [
    { label: 'Edit user', event: openEdit, active: canEdit },
    { label: 'Resend welcome email', event: openResend, active: canResend },
    { label: 'Remove user', event: openDelete, active: false, danger: true },
  ];

  return (
    <div className="grid p-4 sm:gap-y-0 sm:grid-cols-5 lg:grid-cols-6 gap-x-2">
      <div className="flex items-center col-span-1 col-start-1 row-start-1 space-x-4 sm:col-span-2">
        <Avatar
          initials={`${user.firstName.slice(0, 1)}${user.lastName.slice(0, 1)}`}
          role={user.roles[0]}
        />
        <div className="flex flex-col">
          <p className="font-semibold capitalize truncate" style={{ maxWidth: '200px' }}>
            {`${user.firstName} ${user.lastName}`}
          </p>
          <small className="lowercase truncate text-secondary" style={{ maxWidth: '200px' }}>
            {user.email}
          </small>
        </div>
      </div>

      <div className="flex-col items-center justify-center hidden col-span-2 sm:flex">
        {user.roles
          .filter((r) => r !== 'USER' && r !== 'ADMIN')
          .map((role) => (
            <span className="text-xs capitalize xl:text-sm text-secondary">{mapRoles(role)}</span>
          ))}
      </div>

      <div className="items-center justify-center hidden lg:flex">
        {user.status && (
          <div className={`px-2 py-1 capitalize rounded text-sm ${classFromStatus(user.status)}`}>
            {user.status.toLowerCase()}
          </div>
        )}
      </div>

      <div className="flex items-center justify-end col-start-2 row-span-2 row-start-1 space-x-6 sm:row-start-auto sm:col-start-auto sm:row-span-1">
        {settings ? <UserSettingsDropdown options={settingsOptions} /> : <div />}
      </div>
    </div>
  );
};

User.propTypes = {
  user: PropTypes.shape({
    lastName: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    roles: PropTypes.arrayOf(PropTypes.string).isRequired,
    topics: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    status: PropTypes.oneOf(['REGISTERED', 'INVITED', 'DISABLED']).isRequired,
  }).isRequired,
  openEdit: PropTypes.func.isRequired,
  openDelete: PropTypes.func.isRequired,
  openResend: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
  canResend: PropTypes.bool.isRequired,
  settings: PropTypes.bool.isRequired,
};

export default User;
