import React from 'react';
import { useState, useEffect } from 'react';
import * as Yup from 'yup';

import { InputField } from 'src/components';

import debounce from 'lodash.debounce';
import { httpClient } from 'src/helpers';

import { useTranslation } from 'react-i18next';

import countries from 'src/constants/countries.json';
import states from 'src/constants/states.json';

import { useFormikContext } from 'formik';

import ct from 'city-timezones';

const initialValues = {
  manager: 'Sam',
};

const schema = Yup.object().shape({
  facilityAddress: Yup.string().required('forms.required').max(250, 'forms.too_long'),
  facilityAdditionalAddress: Yup.string().max(250, 'forms.too_long'),
  facilityCity: Yup.string().required('forms.required').max(250, 'forms.too_long'),
  facilityPostalCode: Yup.string().required('forms.required').max(250, 'forms.too_long'),
  facilityCountry: Yup.string().required('forms.required').max(250, 'forms.too_long'),
  facilityState: Yup.string().when('facilityCountry', {
    is: (val) => val === 'US' || val === 'CA',
    then: Yup.string().required('forms.required').max(250, 'forms.too_long'),
  }),
  facilityTimezone: Yup.string().required('forms.required').max(250, 'forms.too_long'),
});

const Step = () => {
  const { t } = useTranslation(['dashboard', 'countries', 'states']);
  const { values, setFieldValue } = useFormikContext();

  const [timezones, setTimezones] = useState([]);

  useEffect(() => {
    let tzs = ct.cityMapping.filter(
      (e) => (e.iso2 === values.facilityCountry || e.iso3 === values.facilityCountry) && e.timezone
    );

    // handle countries not supported by city-timezones (ie: Anguilla)
    if (values.facilityCountry === 'AI') {
      tzs = [{ timezone: 'America/Anguilla' }];
    }

    const uniqueTzs = [...new Set(tzs.map((i) => i.timezone))];
    setTimezones(uniqueTzs);
    setFieldValue('facilityTimezone', uniqueTzs[0], true);
  }, [values.facilityCountry]);

  return (
    <div className="space-y-12">
      <div className="space-y-6">
        <span className="text-xl font-bold">{t(`forms.address.titles.address`)}</span>
        <div className="sm:w-1/3">
          <InputField
            name="facilityAddress"
            label="forms.address.address"
            namespaces={['dashboard']}
          />
        </div>
        <div className="sm:w-1/3">
          <InputField
            name="facilityAdditionalAddress"
            label="forms.address.additionalAddress"
            namespaces={['dashboard']}
          />
        </div>
        <div className="sm:w-1/3">
          <InputField
            name="facilityCity"
            label="forms.address.city"
            autoComplete="city"
            namespaces={['dashboard']}
          />
        </div>
        <div className="sm:w-1/3">
          <InputField
            id="label-facility-postal"
            name="facilityPostalCode"
            label="forms.address.postal"
            autoComplete="zip"
            namespaces={['dashboard']}
          />
        </div>
        <div className="sm:w-1/3">
          <InputField
            type="select"
            name="facilityCountry"
            label="forms.address.country"
            namespaces={['dashboard']}
            options={countries.map((country) => ({
              ...country,
              label: t(`countries:${country.label}`),
            }))}
          />
        </div>
        {(values.facilityCountry === 'CA' || values.facilityCountry === 'US') &&
          states[values.facilityCountry] && (
            <div className="sm:w-1/3">
              <InputField
                type="select"
                name="facilityState"
                label="forms.address.state"
                namespaces={['dashboard']}
                options={states[values.facilityCountry].map((state) => ({
                  ...state,
                  label: t(`states:${values.facilityCountry}.${state.label}`),
                }))}
              />
            </div>
          )}
        {values.facilityCountry && timezones.length > 1 && (
          <div className="sm:w-1/3">
            <InputField
              type="select"
              name="facilityTimezone"
              label="forms.address.timezone"
              namespaces={['dashboard']}
              options={timezones.map((tz) => ({ label: tz, value: tz }))}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export { schema };
export default Step;
