import React from 'react';
import * as Yup from 'yup';

import { InputField } from 'src/components';

import debounce from 'lodash.debounce';
import { httpClient } from 'src/helpers';

const initialValues = {
  facilityName: '',
  facilityRooms: 0,
};

const schema = Yup.object().shape({
  facilityName: Yup.string()
    .max(250, 'forms.errors.facility.name')
    .required('forms.required')
    .test(
      'facility-name-backend-validation',
      'forms.errors.facility.taken',
      debounce(async (name) => {
        try {
          if (!name || name === '') return true;
          const { data } = await httpClient.get(`/facilities/${encodeURIComponent(name)}/exists`);
          return !data.exists;
        } catch (error) {
          return false;
        }
      }, 750)
    ),
  facilityRooms: Yup.number().min(1, 'forms.min.rooms').required('forms.required'),
});

const Step = () => (
  <div className="space-y-12">
    <div className="space-y-6">
      <span className="text-xl font-bold">Your Facility</span>

      <div className="space-y-4 sm:w-1/3">
        <InputField
          type="text"
          name="facilityName"
          label="greenshield:forms.addFacilityStep1YourFacility.facilityName"
          namespaces={['dashboard', 'greenshield']}
        />
      </div>
      <div className="space-y-4 sm:w-1/3">
        <InputField
          type="number"
          name="facilityRooms"
          label="greenshield:forms.addFacilityStep1YourFacility.employeeCount"
          namespaces={['dashboard', 'greenshield']}
        />
      </div>
    </div>
  </div>
);

export { initialValues, schema };
export default Step;
