import React from 'react';

const JourneysLoader = () => (
  <>
    <div className="hidden grid-cols-2 pt-12 md:grid sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-7">
      <span className="pl-4 text-sm font-bold uppercase text-secondary">Ship</span>
      <span className="hidden text-xs font-bold text-center uppercase xl:block text-secondary">
        Homeport
      </span>
      <span className="hidden text-xs font-bold text-center uppercase xl:block text-secondary">
        Sail date
      </span>
      <span className="text-xs font-bold text-center uppercase text-secondary">Status</span>
      <span className="text-xs font-bold text-center uppercase text-secondary">Drm</span>
    </div>
    <div className="grid pt-6 gap-y-4">
      {[...Array(8)].map((_, i) => (
        <div
          key={i.toString()}
          className="grid grid-cols-2 text-sm transition rounded-md bg-secondary-grey-light sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-7"
        >
          <div className="p-4 space-y-2 xl:hidden">
            <div className="w-24 h-4 rounded-full bg-secondary-hover animate-pulse" />
            <div className="w-16 h-2 rounded-full bg-secondary-hover animate-pulse" />
            <div className="w-16 h-2 rounded-full bg-secondary-hover animate-pulse" />
          </div>

          <div className="flex justify-end p-4 sm:hidden">
            <div className="w-16 h-4 rounded-full bg-secondary-hover animate-pulse" />
          </div>
          <div className="items-center hidden py-4 pl-4 xl:flex">
            <div className="w-16 h-4 rounded-full bg-secondary-hover animate-pulse" />
          </div>
          <div className="items-center justify-center hidden py-4 capitalize xl:flex">
            <div className="w-full h-4 rounded-full bg-secondary-hover animate-pulse" />
          </div>
          <div className="items-center justify-center hidden py-4 xl:flex">
            <div className="w-16 h-4 rounded-full bg-secondary-hover animate-pulse" />
          </div>
          <div className="items-center justify-center hidden py-4 sm:flex">
            <div className="w-24 h-4 rounded-full bg-secondary-hover animate-pulse" />
          </div>
          <div className="items-center justify-center hidden py-4 md:flex">
            <div className="w-16 h-4 rounded-full bg-secondary-hover animate-pulse" />
          </div>
        </div>
      ))}
    </div>
  </>
);

export default JourneysLoader;
