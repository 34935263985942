import React from 'react';
import PropTypes from 'prop-types';

const CardList = ({ children }) => (
  <div className="grid grid-cols-1 gap-12 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4">
    {children}
  </div>
);

CardList.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CardList;
