import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import { Formik, Form } from 'formik';
import { motion } from 'framer-motion';

import { httpClient } from 'src/helpers';
import { Modal, Button } from 'src/components';
import ConfirmImage from 'src/assets/images/add_facility_confirm.jpeg';

import IndustryForm, { industrySchema } from './IndustryForm';
import AddressForm, { addressSchema } from './AddressForm';
import AddressFormLite, { addressLiteSchema } from './AddressFormLite';
import ManagerForm, { managerSchema } from './ManagerForm';
import MarketingForm, { marketingSchema } from './MarketingForm';
import ContactForm, { contactSchema } from './ContactForm';
import PackageForm, { packageSchema } from './PackageForm';
import CheckoutInformation from './CheckoutInformation';

import initialValues from './initial-values';
import formatValues from './format-values';

const { GATSBY_VERTICAL } = process.env;
const INITIAL_STEP = 0;

const getSteps = () => {
  switch (GATSBY_VERTICAL) {
    case 'FTG':
      return 4;
    case 'DEKRA':
      return 3;
    default:
      return 2;
  }
};

const getSchemas = () => {
  switch (GATSBY_VERTICAL) {
    case 'FTG':
      return [addressSchema, managerSchema, marketingSchema, contactSchema, packageSchema];
    case 'GREENSHIELD':
      return [addressLiteSchema, managerSchema, packageSchema];
    case 'DEKRA':
      return [industrySchema, addressSchema, managerSchema, packageSchema];
    default:
      return [addressSchema, managerSchema, packageSchema];
  }
};

const ModalAdd = ({ open, requestClose, users, products, industries, mutate }) => {
  const user = useSelector((state) => state.user);
  const { t } = useTranslation(['dashboard', 'common', 'greenshield']);

  const STEPS = getSteps();
  const SCHEMAS = getSchemas();

  const [state, setState] = useState({
    step: INITIAL_STEP,
    schema: SCHEMAS[INITIAL_STEP],
    isLoading: false,
    isComplete: false,
    isSuccess: false,
    isError: false,
  });

  const [product, setProduct] = useState(products[0]);

  const handleSubmitRequest = async (values) => {
    setState({ ...state, isLoading: true });

    const formatedValues = formatValues(values);
    formatedValues.clientId = user.clientId;

    try {
      const { status } = await httpClient.post(`/facilities`, formatedValues);

      if (status === 200) {
        mutate();

        setTimeout(() => {
          setState({
            ...state,
            isLoading: false,
            isComplete: true,
            isError: false,
            isSuccess: true,
          });
        }, 4500);
      } else {
        throw Error('Facility creation failed.');
      }
    } catch (error) {
      setState({ ...state, isLoading: false, isComplete: true, isError: true, isSuccess: false });
    }
  };

  const handleSubmit = (values, actions) => {
    const nextStep = state.step + 1;
    if (typeof document !== 'undefined') document.getElementById('modal-body').scrollTop = 0;
    if (nextStep - 1 >= STEPS) {
      handleSubmitRequest(values);
      if (window.gtag) window.gtag('event', `Create_facility_validated`);
    } else {
      setState({ ...state, step: nextStep, schema: SCHEMAS[nextStep] });
      if (window.gtag) window.gtag('event', `Create_facility_step_${nextStep}`);
    }

    actions.setTouched({});
    actions.setSubmitting(false);
  };

  const handleBack = (setErrors) => {
    if (typeof document !== 'undefined') document.getElementById('modal-body').scrollTop = 0;
    if (
      state.step <= INITIAL_STEP ||
      state.isComplete ||
      state.isLoading ||
      state.isError ||
      state.isSuccess
    )
      return;
    setState({ ...state, step: state.step - 1, schema: SCHEMAS[state.step - 1] });
    setErrors({});
  };

  const handleClose = () => {
    setState({
      step: INITIAL_STEP,
      schema: SCHEMAS[INITIAL_STEP],
      isLoading: false,
      isComplete: false,
      isSuccess: false,
      isError: false,
    });
    requestClose();
  };

  const children = () => {
    switch (GATSBY_VERTICAL) {
      case 'FTG': {
        return (
          <>
            {state.step === 0 && (
              <div className="max-w-md px-6 mx-auto">
                <AddressForm product={product} />
              </div>
            )}
            {state.step === 1 && (
              <div className="max-w-md px-6 mx-auto">
                <ManagerForm users={users} />
              </div>
            )}
            {state.step === 2 && (
              <div className="max-w-md px-6 pb-4 mx-auto">
                <MarketingForm users={users} />
              </div>
            )}
            {state.step === 3 && (
              <div
                className="px-6 space-y-16 lg:grid lg:space-y-0 lg:px-0"
                style={{ gridTemplateColumns: '1fr 420px' }}
              >
                <div className="lg:pb-12 lg:max-w-lg lg:mx-auto lg:w-full">
                  <ContactForm />
                </div>
                <div className="bg-secondary-grey-light">
                  <CheckoutInformation product={product} />
                </div>
              </div>
            )}
            {state.step === 4 && (
              <div
                className="px-6 space-y-16 lg:grid lg:space-y-0 lg:px-0"
                style={{ gridTemplateColumns: '1fr 420px' }}
              >
                <div className="lg:max-w-lg lg:mx-auto lg:w-full">
                  <PackageForm products={products} product={product} setProduct={setProduct} />
                </div>
                <div className="bg-secondary-grey-light">
                  <CheckoutInformation product={product} />
                </div>
              </div>
            )}
          </>
        );
      }
      case 'GREENSHIELD': {
        return (
          <>
            {state.step === 0 && (
              <div className="max-w-md px-6 mx-auto">
                <AddressFormLite product={product} />
              </div>
            )}
            {state.step === 1 && (
              <div className="max-w-md px-6 mx-auto">
                <ManagerForm users={users} />
              </div>
            )}
            {state.step === 2 && (
              <div className="max-w-md px-6 pb-12 mx-auto">
                <PackageForm setProduct={setProduct} />
              </div>
            )}
          </>
        );
      }
      case 'DEKRA': {
        return (
          <>
            {state.step === 0 && (
              <div className="max-w-md px-6 mx-auto">
                <IndustryForm industries={industries} />
              </div>
            )}
            {state.step === 1 && (
              <div className="max-w-md px-6 mx-auto">
                <AddressForm product={product} />
              </div>
            )}
            {state.step === 2 && (
              <div className="max-w-md px-6 mx-auto">
                <ManagerForm users={users} />
              </div>
            )}
            {state.step === 3 && (
              <div className="max-w-md px-6 pb-12 mx-auto">
                <PackageForm setProduct={setProduct} />
              </div>
            )}
          </>
        );
      }
      default:
        return (
          <>
            {state.step === 0 && (
              <div className="max-w-md px-6 mx-auto">
                <AddressForm product={product} />
              </div>
            )}
            {state.step === 1 && (
              <div className="max-w-md px-6 mx-auto">
                <ManagerForm users={users} />
              </div>
            )}
            {state.step === 2 && (
              <div className="max-w-md px-6 pb-12 mx-auto">
                <PackageForm setProduct={setProduct} />
              </div>
            )}
          </>
        );
    }
  };

  return (
    <Modal
      open={open}
      requestClose={handleClose}
      closeOnEsc={state.isComplete}
      closeOnOverlay={state.isComplete}
      maxWidth
    >
      <Formik
        initialValues={{
          ...initialValues,
          facilityIndustry: GATSBY_VERTICAL === 'DEKRA' ? '' : industries[0],
          facilityProduct: { ...product },
          billingAttention: `${user.firstName} ${user.lastName}`,
          billingAddressee: `${user.clientName}`,
          contactEmail: user.email,
          billingAddresseePhone: user.phone,
        }}
        validationSchema={state.schema}
        onSubmit={handleSubmit}
      >
        {({ isValid, isSubmitting, dirty, setErrors }) => (
          <Form className="modal-content">
            {!state.isComplete && !state.isLoading && (
              <Modal.Header className="bg-gray-100">
                <h4 className="text-xl font-bold lg:text-2xl">
                  {t('dashboard:forms.modal.title')}
                </h4>
                <div className="absolute right-0 pr-6">
                  <button type="button" onClick={handleClose}>
                    <svg viewBox="0 0 20 20" className="w-5 h-5">
                      <path d="M16.6952 0.51282C17.4549 -0.17094 18.6705 -0.17094 19.4302 0.51282C20.1899 1.27255 20.1899 2.48813 19.4302 3.24786L12.7445 10.0095L19.4302 16.6952C20.1899 17.4549 20.1899 18.6705 19.4302 19.4302C18.6705 20.1899 17.4549 20.1899 16.6952 19.4302L10.0095 12.7445L3.24786 19.4302C2.48813 20.1899 1.27255 20.1899 0.51282 19.4302C-0.17094 18.6705 -0.17094 17.4549 0.51282 16.6952L7.27445 10.0095L0.51282 3.24786C-0.17094 2.48813 -0.17094 1.27255 0.51282 0.51282C1.27255 -0.17094 2.48813 -0.17094 3.24786 0.51282L10.0095 7.27445L16.6952 0.51282Z" />
                    </svg>
                  </button>
                </div>
                <div className="absolute inset-x-0 bottom-0">
                  <div className="h-2">
                    <motion.div
                      initial={{ width: `${(state.step + 1) * (100 / (STEPS + 1))}%` }}
                      animate={{ width: `${(state.step + 1) * (100 / (STEPS + 1))}%` }}
                      transition={{ ease: [0.25, 0.1, 0.25, 1], duration: 0.2 }}
                      className="h-full bg-primary-light"
                    />
                  </div>
                </div>
                <div className="absolute inset-x-0 hidden -bottom-8 lg:block">
                  <div className="pl-3">
                    <span className="font-bold text-primary-light">
                      {`${state.step + 1}/${STEPS + 1}`}
                    </span>
                  </div>
                </div>
              </Modal.Header>
            )}
            <Modal.Body>
              <div>
                {!state.isComplete && !state.isLoading ? (
                  children()
                ) : (
                  <div className="flex flex-col p-12">
                    <div className="flex justify-end">
                      {!state.isLoading && (
                        <button type="button" onClick={handleClose}>
                          <svg viewBox="0 0 20 20" className="w-5 h-5">
                            <path d="M16.6952 0.51282C17.4549 -0.17094 18.6705 -0.17094 19.4302 0.51282C20.1899 1.27255 20.1899 2.48813 19.4302 3.24786L12.7445 10.0095L19.4302 16.6952C20.1899 17.4549 20.1899 18.6705 19.4302 19.4302C18.6705 20.1899 17.4549 20.1899 16.6952 19.4302L10.0095 12.7445L3.24786 19.4302C2.48813 20.1899 1.27255 20.1899 0.51282 19.4302C-0.17094 18.6705 -0.17094 17.4549 0.51282 16.6952L7.27445 10.0095L0.51282 3.24786C-0.17094 2.48813 -0.17094 1.27255 0.51282 0.51282C1.27255 -0.17094 2.48813 -0.17094 3.24786 0.51282L10.0095 7.27445L16.6952 0.51282Z" />
                          </svg>
                        </button>
                      )}
                    </div>
                    <div className="flex items-center md:space-x-6">
                      <div className="hidden w-full md:block">
                        {!state.isError ? (
                          <img
                            src={ConfirmImage}
                            alt="Facility created"
                            className="object-cover w-full"
                          />
                        ) : (
                          <div className="flex items-center justify-center w-full py-24">
                            <svg viewBox="0 0 180.000000 161.000000" className="h-56">
                              <g transform="translate(0.000000,161.000000) scale(0.100000,-0.100000)">
                                <path
                                  d="M775 1589 c-276 -43 -513 -225 -619 -477 -43 -101 -59 -187 -59 -307 0 -139 19 -220 82 -350 131 -268 400 -435 702 -436 138 0 230 21 354 81 160 77 282 197 360 355 64 131 79 196 79 350 0 153 -14 217 -79 350 -126 259 -377 423 -673 439 -48 3 -114 1 -147 -5z m254 -194 c210 -50 390 -227 446 -440 19 -76 19 -224 0 -299 -41 -158 -172 -319 -316 -390 -281 -138 -609 -49 -781 213 -118 180 -129 406 -30 604 71 143 229 268 392 311 72 19 209 19 289 1z"
                                  fill="#4a4a4a"
                                />
                                <path
                                  d="M605 1131 c-108 -49 -159 -185 -113 -306 35 -92 145 -221 278 -328 88 -70 79 -80 105 119 l17 131 -40 39 -41 39 40 85 40 85 -25 18 c-69 50 -70 52 -58 85 11 31 10 32 -26 42 -56 15 -132 12 -177 -9z"
                                  fill="#029099"
                                />
                                <path
                                  d="M976 1136 c-22 -8 -48 -24 -60 -35 l-21 -21 42 -42 41 -41 -31 -64 -32 -64 44 -37 44 -38 -63 -164 c-34 -91 -66 -175 -71 -187 -27 -70 114 18 256 161 121 123 169 211 170 311 0 88 -31 156 -92 199 -35 24 -54 30 -115 33 -48 2 -87 -2 -112 -11z"
                                  fill="#029099"
                                />
                              </g>
                            </svg>
                          </div>
                        )}
                      </div>
                      {state.isLoading && (
                        <div className="flex flex-col items-center justify-center w-full text-primary-light">
                          <svg viewBox="0 0 128 128" className="w-24 h-24">
                            <g>
                              <path
                                d="M75.4 126.63a11.43 11.43 0 0 1-2.1-22.65 40.9 40.9 0 0 0 30.5-30.6 11.4 11.4 0 1 1 22.27 4.87h.02a63.77 63.77 0 0 1-47.8 48.05v-.02a11.38 11.38 0 0 1-2.93.37z"
                                fill="currentColor"
                              />
                              <animateTransform
                                attributeName="transform"
                                type="rotate"
                                from="0 64 64"
                                to="360 64 64"
                                dur="1800ms"
                                repeatCount="indefinite"
                              />
                            </g>
                          </svg>
                        </div>
                      )}
                      {state.isComplete && state.isSuccess && (
                        <div className="flex flex-col w-full space-y-6">
                          <h4 className="font-bold">
                            {t(
                              GATSBY_VERTICAL === 'GREENSHIELD'
                                ? 'greenshield:forms.confirmation.title'
                                : 'dashboard:forms.confirmation.title'
                            )}
                          </h4>
                          <p className="pt-2">
                            <Trans
                              t={t}
                              i18nKey={
                                GATSBY_VERTICAL === 'GREENSHIELD'
                                  ? 'greenshield:forms.confirmation.text'
                                  : 'dashboard:forms.confirmation.text'
                              }
                              components={{ br: <br />, strong: <strong /> }}
                            >
                              You have set up a new facility for health security verification.
                              <br />
                              Your facility creation is in progress.
                              <br />
                              <br />
                              Once validated, your Designated Readiness Manager (DRM) will receive
                              an email with login credentials and a link to the verification
                              platform.
                              <br />
                              <br />
                              You can manage your facility&apos;s details through this portal at any
                              time.
                            </Trans>
                          </p>
                          <div className="flex">
                            <Button onClick={handleClose}>
                              {t('dashboard:forms.confirmation.view')}
                            </Button>
                          </div>
                        </div>
                      )}
                      {state.isComplete && state.isError && (
                        <div className="flex flex-col w-full space-y-6">
                          <h4 className="font-bold">Oops!</h4>
                          <p className="pt-2">
                            {!navigator.onLine
                              ? t('common:snackbar.offline-facility')
                              : t('common:snackbar.server-facility')}
                          </p>
                          <div className="flex">
                            <Button onClick={handleClose}>
                              {t('dashboard:forms.confirmation.view')}
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </Modal.Body>
            {!state.isComplete && !state.isLoading && (
              <Modal.Footer>
                <div className="max-w-md px-6 mx-auto">
                  <div className="flex items-center space-x-6">
                    {state.step > INITIAL_STEP && (
                      <div className="w-1/3">
                        <Button
                          variant="secondary"
                          size="md"
                          disabled={isSubmitting}
                          onClick={() => handleBack(setErrors)}
                        >
                          {t('dashboard:forms.buttons.back')}
                        </Button>
                      </div>
                    )}
                    <div className="w-full">
                      <Button
                        type="submit"
                        size="md"
                        disabled={!(isValid && dirty) || isSubmitting}
                      >
                        {state.step >= STEPS ? t('forms.buttons.create') : t('forms.buttons.next')}
                      </Button>
                    </div>
                  </div>
                </div>
              </Modal.Footer>
            )}
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

ModalAdd.propTypes = {
  open: PropTypes.bool.isRequired,
  requestClose: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      policy: PropTypes.shape({
        id: PropTypes.number.isRequired,
        flat: PropTypes.bool.isRequired,
        price: PropTypes.number.isRequired,
        rate: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
  industries: PropTypes.arrayOf(
    PropTypes.shape({
      industryId: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  mutate: PropTypes.func.isRequired,
};

export default ModalAdd;
