import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ModalInformations from './ModalInformations';

const Informations = ({ facility, mutate }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation('facility');

  return (
    <>
      <div>
        <div className="flex items-center justify-between lg:justify-start lg:space-x-8">
          <h5 className="text-3xl font-bold">{t('informations.titles.property')}</h5>
          <button
            type="button"
            disabled={!['admin', 'manager'].includes(facility.userRole)}
            onClick={() => setModalOpen(true)}
            className="px-6 py-2 font-bold text-blue-500 transition rounded-md bg-blue-50 hover:bg-blue-100 focus:bg-blue-100 focus:outline-none focus:text-blue-600 hover:text-blue-600"
          >
            {t('informations.buttons.edit')}
          </button>
        </div>
        <div className="grid max-w-lg grid-cols-3 gap-4 pt-4 pb-8">
          <span className="col-span-1 text-secondary">{t('informations.items.property')}</span>
          <p className="col-span-2 truncate" style={{ maxWidth: '200px' }}>
            {facility.name}
          </p>

          <span className="col-span-1 text-secondary">{t('informations.items.address')}</span>
          {facility.address ? (
            <span className="col-span-2">
              {facility.address.line1}
              {facility.address.line2 && (
                <>
                  <br />
                  {facility.address.line2}
                </>
              )}
              <br />
              <span>{`${facility.address.zipCode}, ${facility.address.city} `}</span>
              {facility.address.country && (
                <span className="uppercase">{facility.address.country}</span>
              )}
            </span>
          ) : (
            <span className="col-span-2" />
          )}
          <span className="col-span-1 text-secondary">{t(`units.${facility.unit}.quantity`)}</span>
          <span className="col-span-2">{facility.rooms}</span>
        </div>
      </div>
      {modalOpen && (
        <ModalInformations
          open={modalOpen}
          requestClose={() => setModalOpen(false)}
          facilityId={facility.id}
          unit={facility.unit}
          initialValues={{
            facilityName: facility.name,
            facilityRooms: `${facility.rooms}`,
            facilityEmployees: `${facility.employees}`,
            facilityAddress: facility.address.line1,
            facilityAdditionalAddress: facility.address.line2,
            facilityCity: facility.address.city,
            facilityPostalCode: facility.address.zipCode,
            facilityCountry: facility.address.country,
            facilityState: facility.address.state,
            facilityTimezone: facility.timezone,
          }}
          mutate={mutate}
        />
      )}
    </>
  );
};

Informations.propTypes = {
  facility: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    address: PropTypes.shape({
      line1: PropTypes.string.isRequired,
      line2: PropTypes.string,
      zipCode: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      country: PropTypes.string.isRequired,
      state: PropTypes.string,
    }).isRequired,
    rooms: PropTypes.number.isRequired,
    employees: PropTypes.number.isRequired,
    unit: PropTypes.string.isRequired,
    userRole: PropTypes.string.isRequired,
    timezone: PropTypes.string.isRequired,
  }).isRequired,
  mutate: PropTypes.func.isRequired,
};

export default Informations;
