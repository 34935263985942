import React from 'react';
import PropTypes from 'prop-types';
import Loadable from '@loadable/component';

const AsyncHeader = Loadable(() => import('./Header'));

const Layout = ({ userRole, children }) => (
  <>
    <AsyncHeader userRole={userRole} />
    {children}
  </>
);

Layout.propTypes = {
  userRole: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Layout;
