import React from 'react';

import { Page } from 'src/components';

const src = () => {
  const { GATSBY_VERTICAL } = process.env;

  switch (GATSBY_VERTICAL) {
    case 'CRUISES':
      return 'https://app.powerbi.com/view?r=eyJrIjoiNGUyNmYzY2MtY2RhMS00NjhiLWI4ODctZjk4NmEzNmYzY2E2IiwidCI6IjQ3OTEyODZiLTA3MDctNDc4Mi04ZGFlLTg5ZmU0YTMyMGIwOSIsImMiOjF9';
    case 'VULCAIN':
      return 'https://app.powerbi.com/view?r=eyJrIjoiMjQ3MWQ3YjctZDBjNy00NmM2LWJhMGUtMTU4YzM2MTA0MGRjIiwidCI6IjQ3OTEyODZiLTA3MDctNDc4Mi04ZGFlLTg5ZmU0YTMyMGIwOSIsImMiOjF9';
    case 'GREENSHIELD':
      return 'https://app.powerbi.com/view?r=eyJrIjoiNDI3MTEwYmQtMTY4OS00NGNkLWIzYWItYThmMWRlN2Y3NzkxIiwidCI6IjQ3OTEyODZiLTA3MDctNDc4Mi04ZGFlLTg5ZmU0YTMyMGIwOSIsImMiOjF9';
    default:
      return 'https://app.powerbi.com/view?r=eyJrIjoiZmY3YmRiOTMtZmRhNi00MmZlLWIyMTMtOTU0NmExOTRiN2ZmIiwidCI6IjQ3OTEyODZiLTA3MDctNDc4Mi04ZGFlLTg5ZmU0YTMyMGIwOSIsImMiOjF9';
  }
};

const Dashboard = () => (
  <Page title="Dashboard">
    <Page.Main>
      <iframe id="iframe" title="Dashboard" src={src()} className="w-full powerbi-dashboard" />
    </Page.Main>
  </Page>
);

export default Dashboard;
