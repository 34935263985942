import React from 'react';
import PropTypes from 'prop-types';

import Sidebar from './Sidebar';

const Layout = ({ children, ship }) => (
  <div id="layout-facility">
    <div>
      <Sidebar ship={ship} />
    </div>
    <div>{children}</div>
  </div>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  ship: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    logo: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    }),
  }),
};

Layout.defaultProps = {
  ship: {
    logo: undefined,
  },
};

export default Layout;
