import React from 'react';
import PropTypes from 'prop-types';

import { Page } from 'src/components';

const Site = ({ site }) => (
  <Page title={site.id || 'Site'}>
    <Page.Main className="pt-16">
      <pre>{JSON.stringify(site, null, 2)}</pre>
    </Page.Main>
  </Page>
);

Site.propTypes = {
  site: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
};

Site.defaultProps = {
  site: null,
};

export default Site;
