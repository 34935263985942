import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import useSWR from 'swr';

import { Modal, Spinner } from 'src/components';
import AssignmentForm from './AssignmentForm';

const ModalViewJourney = ({ open, requestClose, journey, topics }) => {
  const { data, error } = useSWR(
    journey.id ? [`/facilities/${journey.id}/users?withTopics=true&pages=false`] : null
  );

  return (
    <Modal open={open} requestClose={requestClose}>
      <Modal.Header>
        <h4 className="text-lg font-bold">View journey</h4>
        <div className="absolute right-0 pr-6">
          <button type="button" onClick={requestClose}>
            <svg viewBox="0 0 20 20" className="w-5 h-5">
              <path d="M16.6952 0.51282C17.4549 -0.17094 18.6705 -0.17094 19.4302 0.51282C20.1899 1.27255 20.1899 2.48813 19.4302 3.24786L12.7445 10.0095L19.4302 16.6952C20.1899 17.4549 20.1899 18.6705 19.4302 19.4302C18.6705 20.1899 17.4549 20.1899 16.6952 19.4302L10.0095 12.7445L3.24786 19.4302C2.48813 20.1899 1.27255 20.1899 0.51282 19.4302C-0.17094 18.6705 -0.17094 17.4549 0.51282 16.6952L7.27445 10.0095L0.51282 3.24786C-0.17094 2.48813 -0.17094 1.27255 0.51282 0.51282C1.27255 -0.17094 2.48813 -0.17094 3.24786 0.51282L10.0095 7.27445L16.6952 0.51282Z" />
            </svg>
          </button>
        </div>
      </Modal.Header>
      <Modal.Body className="px-6">
        {!error && !data && (
          <div className="flex items-center justify-center pb-6 text-primary-light">
            <Spinner />
          </div>
        )}
        {error && <div className="flex items-center justify-center">An error occured.</div>}
        {!error && data && (
          <Formik
            initialValues={{
              ...journey,
              assignments: data
                .sort((a, b) => a.email.localeCompare(b.email))
                .map((e) => {
                  const { topics: userTopics, ...user } = e;

                  return {
                    user,
                    topics: topics.map((t) => ({
                      ...t,
                      selected: userTopics ? userTopics.includes(t.topicMasterId) : false,
                    })),
                  };
                }),
            }}
            onSubmit={() => {}}
          >
            {({ values }) => (
              <div className="max-w-md pt-4 pb-12 mx-auto">
                <div className="flex flex-col items-center justify-center space-y-2">
                  <div className="flex items-center space-x-2">
                    <svg viewBox="0 0 70 70" className="flex-shrink-0 h-10">
                      <path d="M34.1388 40.9258H5.25L10.25 66.4813L34.1388 69.9998L58.0276 66.4813L63.0276 40.9258H34.1388Z" />
                      <path d="M60.7125 19.4444H51.9162V14.7222H40.3421L38.6755 2.49999H40.157V0H34.6014H33.4903H27.9348V2.49999H29.4162L27.7496 14.7222H16.3607V19.4444H7.56445V39.1666H60.7125V19.4444ZM19.4163 31.111H13.3978V25.0925H19.4163V31.111ZM28.3051 31.111H22.2866V25.0925H28.3051V31.111ZM37.1014 31.111H31.0829V25.0925H37.1014V31.111ZM45.9903 31.111H39.9718V25.0925H45.9903V31.111ZM54.8791 31.111H48.8606V25.0925H54.8791V31.111Z" />
                    </svg>
                    <p className="font-bold truncate-2" style={{ maxWidth: '200px' }}>
                      {values.ship.name}
                    </p>
                  </div>
                  <span>
                    <strong>Homeport:</strong> {journey.homeport}
                  </span>
                  <span>
                    <strong>Saildate:</strong>{' '}
                    {journey.sail.substring(0, journey.sail.indexOf('T'))}
                  </span>
                </div>
                <div className="pt-12 space-y-8">
                  <span className="text-xl font-bold">Assignements</span>

                  {values.assignments.map((assignment, i) => (
                    <AssignmentForm
                      key={assignment.user.id}
                      index={i}
                      user={assignment.user}
                      topics={assignment.topics}
                      editable={false}
                    />
                  ))}
                </div>
              </div>
            )}
          </Formik>
        )}
      </Modal.Body>
    </Modal>
  );
};

ModalViewJourney.propTypes = {
  open: PropTypes.bool.isRequired,
  requestClose: PropTypes.func.isRequired,
  journey: PropTypes.shape({
    ship: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    id: PropTypes.string.isRequired,
    homeport: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    timezone: PropTypes.string.isRequired,
    sail: PropTypes.string.isRequired,
  }).isRequired,
  topics: PropTypes.arrayOf(
    PropTypes.shape({
      topicMasterId: PropTypes.string.isRequired,
      topicName: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};

export default ModalViewJourney;
