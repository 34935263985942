import React from 'react';
import { Modal } from 'src/components';

const ModalUnderstandRoles = ({ open, requestClose }) => {
  return (
    <Modal open={open} requestClose={requestClose}>
      <Modal.Header>
        <h4 className="text-xl font-bold">Understand Roles</h4>
        <div className="absolute right-0 pr-6">
          <button type="button" onClick={requestClose}>
            <svg viewBox="0 0 20 20" className="w-5 h-5">
              <path d="M16.6952 0.51282C17.4549 -0.17094 18.6705 -0.17094 19.4302 0.51282C20.1899 1.27255 20.1899 2.48813 19.4302 3.24786L12.7445 10.0095L19.4302 16.6952C20.1899 17.4549 20.1899 18.6705 19.4302 19.4302C18.6705 20.1899 17.4549 20.1899 16.6952 19.4302L10.0095 12.7445L3.24786 19.4302C2.48813 20.1899 1.27255 20.1899 0.51282 19.4302C-0.17094 18.6705 -0.17094 17.4549 0.51282 16.6952L7.27445 10.0095L0.51282 3.24786C-0.17094 2.48813 -0.17094 1.27255 0.51282 0.51282C1.27255 -0.17094 2.48813 -0.17094 3.24786 0.51282L10.0095 7.27445L16.6952 0.51282Z" />
            </svg>
          </button>
        </div>
      </Modal.Header>
      <Modal.Body className="flex flex-col items-center px-4 pb-4 space-y-4 text-xs md:pb-8">
        <div
          className="p-4 mt-1 space-y-4 rounded-lg md:max-w-md"
          style={{ boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)' }}
        >
          <div className="flex items-center space-x-1">
            <svg className="w-4 h-4" viewBox="0 0 20 21">
              <path
                d="M10 0.5C4.48 0.5 0 4.98 0 10.5C0 16.02 4.48 20.5 10 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 10 0.5ZM10 3.5C11.66 3.5 13 4.84 13 6.5C13 8.16 11.66 9.5 10 9.5C8.34 9.5 7 8.16 7 6.5C7 4.84 8.34 3.5 10 3.5ZM10 17.7C7.5 17.7 5.29 16.42 4 14.48C4.03 12.49 8 11.4 10 11.4C11.99 11.4 15.97 12.49 16 14.48C14.71 16.42 12.5 17.7 10 17.7Z"
                fill="#19141A"
              />
            </svg>
            <span className="text-sm font-semibold">Administrator</span>
          </div>
          <p className=" text-secondary">
            The Administrator has the highest level of access to capabilities in our self-service
            administration portal.
          </p>
          <ul class=" text-secondary list-disc px-4">
            <li>Full access to the administration portal</li>
            <li>Can create facilities</li>
            <li>Access to the account’s dashboard</li>
            <li>
              Full access to the account’s facilities information (general information, QR codes,
              billing information)
            </li>
            <li>Can view the employee data and vaccination/testing status records </li>
            <li>Can view employees’ evidences</li>
            <li>Can add or edit users per facility</li>
          </ul>
        </div>
        <div
          className="p-4 space-y-4 rounded-lg md:max-w-md"
          style={{ boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)' }}
        >
          <div className="flex flex-row items-center space-x-1">
            <svg className="w-4 h-4" viewBox="0 0 20 21">
              <path
                d="M10 0.5C4.48 0.5 0 4.98 0 10.5C0 16.02 4.48 20.5 10 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 10 0.5ZM10 3.5C11.66 3.5 13 4.84 13 6.5C13 8.16 11.66 9.5 10 9.5C8.34 9.5 7 8.16 7 6.5C7 4.84 8.34 3.5 10 3.5ZM10 17.7C7.5 17.7 5.29 16.42 4 14.48C4.03 12.49 8 11.4 10 11.4C11.99 11.4 15.97 12.49 16 14.48C14.71 16.42 12.5 17.7 10 17.7Z"
                fill="#00BFA5"
              />
            </svg>
            <div className="text-sm font-semibold">Facility Manager</div>
          </div>
          <ul class=" text-secondary list-disc px-4">
            <li>
              Reduced access to the administration portal to enable facility and workforce
              management
            </li>
            <li>
              Access to the designated facility’s information (general information, QR codes){' '}
            </li>
            <li>Access to the designated facility’s dashboard </li>
            <li>
              Can view the designated facility’s employee data and vaccination/testing status
              records{' '}
            </li>
            <li>Can view the designated facility’s employees’ evidences </li>
            <li>Can add or edit users for their facility</li>
          </ul>
        </div>
        <div
          className="p-4 space-y-4 rounded-lg md:max-w-md"
          style={{ boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)' }}
        >
          <div className="flex flex-row items-center space-x-1">
            <svg className="w-4 h-4" viewBox="0 0 20 21">
              <path
                d="M10 0.5C4.48 0.5 0 4.98 0 10.5C0 16.02 4.48 20.5 10 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 10 0.5ZM10 3.5C11.66 3.5 13 4.84 13 6.5C13 8.16 11.66 9.5 10 9.5C8.34 9.5 7 8.16 7 6.5C7 4.84 8.34 3.5 10 3.5ZM10 17.7C7.5 17.7 5.29 16.42 4 14.48C4.03 12.49 8 11.4 10 11.4C11.99 11.4 15.97 12.49 16 14.48C14.71 16.42 12.5 17.7 10 17.7Z"
                fill="#5D6A74"
              />
            </svg>
            <div className="text-sm font-semibold">Supervisor</div>
          </div>
          <ul class=" text-secondary list-disc px-4">
            <li>
              Limited access to the administration portal to support facility and workforce
              management
            </li>
            <li>Access to the designated facility’s information (general information, QR codes)</li>
            <li>Access to the designated facility’s dashboard</li>
            <li>
              Can view the designated facility’s employee data and vaccination/testing/evidence
              status
            </li>
          </ul>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalUnderstandRoles;
