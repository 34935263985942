import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { httpClient } from 'src/helpers';
import { InputField, Snackbar, Button } from 'src/components';

const botSchema = Yup.array().of(
  Yup.object().shape({
    selected: Yup.bool(),
    delay: Yup.number().when('selected', { is: true, then: Yup.number().min(0).max(15) }),
  })
);

const Bot = ({ settings, canEditChatbot, mutate }) => {
  const user = useSelector((state) => state.user);

  const [snackbarState, setSnackbarState] = useState({ type: 'HIDDEN', msg: '' });

  const validateType = (values, type) => {
    if (process.env.GATSBY_VERTICAL !== 'GREENSHIELD') return true;
    const selectedType = values.filter((e) => e.type === type && e.selected === true);

    return selectedType.length > 0;
  };

  const handleSubmit = async (values, actions) => {
    if (!validateType(values, 'EVIDENCE')) {
      setSnackbarState({ type: 'ERROR', msg: 'Select at least one evidence' });
      actions.setSubmitting(false);
      return;
    }

    if (!validateType(values, 'EXEMPTION')) {
      setSnackbarState({ type: 'ERROR', msg: 'Select at least one exemption' });
      actions.setSubmitting(false);
      return;
    }

    if (window.gtag) window.gtag('event', 'Edit_bot_settings');
    setSnackbarState({ type: 'LOADING' });
    actions.setSubmitting(true);

    try {
      window.scroll({ top: 0, behavior: 'smooth' });
      const { status } = await httpClient.put(`/clients/${user.clientId}/settings`, values);

      if (status === 200) {
        setSnackbarState({ type: 'SUCCESS' });
        mutate();

        setTimeout(() => {
          setSnackbarState({ type: 'HIDDEN' });
        }, 1500);
      } else {
        throw Error('Facility creation failed.');
      }
    } catch (error) {
      console.log(error);
      setSnackbarState({ type: 'ERROR' });
      actions.setSubmitting(false);
    }
  };

  return (
    <section>
      <h5 className="pb-4 text-3xl font-bold">Bot Settings</h5>

      {canEditChatbot && settings ? (
        <Formik initialValues={[...settings]} validationSchema={botSchema} onSubmit={handleSubmit}>
          {({ isValid, isSubmitting, dirty, values, setFieldValue, errors }) => (
            <Form className="flex flex-col">
              <div className="md:max-w-md">
                <Snackbar
                  type={snackbarState.type}
                  message={snackbarState.msg}
                  requestClose={() => setSnackbarState({ ...snackbarState, type: 'HIDDEN' })}
                />
              </div>

              <div className="grid grid-cols-1 p-12 mt-4 border rounded-lg gap-y-12 gap-x-24 lg:grid-cols-2">
                {[
                  { key: 'EVIDENCE', title: 'Evidences', description: 'Description' },
                  { key: 'EXEMPTION', title: 'Exemptions', description: 'Description' },
                ].map((k) => (
                  <div key={k.key} className="space-y-8">
                    <span className="text-xl font-bold">{k.title}</span>

                    <div className="space-y-6">
                      {values.map((s, i) => {
                        if (s.type !== k.key) return null;
                        return (
                          <div
                            key={s.label}
                            className="items-center justify-between space-y-2 sm:flex sm:space-y-0"
                          >
                            <InputField
                              key={`${s.label}-toggle`}
                              type="toggle"
                              name={`[${i}.selected]`}
                              label={s.label}
                              translate={false}
                              disabled={!['ADMIN'].includes(user.role)}
                            />
                            <div className="flex items-center w-1/3 space-x-2 text-sm text-secondary">
                              <span className="flex-shrink-0">Valid for:</span>
                              <input
                                type="number"
                                value={s.delay}
                                onChange={(e) => {
                                  const inputValue = e.target.valueAsNumber;
                                  if (!Number.isNaN(inputValue))
                                    setFieldValue(`[${i}.delay]`, inputValue);
                                }}
                                className="w-10 px-1 text-black border-b rounded-sm focus:outline-none disabled:pointer-events-none disabled:opacity-40"
                                disabled={!s.selected || !['ADMIN'].includes(user.role)}
                                min={0}
                                max={15}
                              />
                              <span className="flex-shrink-0 lowercase">{s.unit}</span>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ))}
              </div>

              <div className="flex flex-col-reverse items-center justify-start pt-4 lg:flex-row">
                <div className="w-full lg:w-48">
                  <Button
                    type="submit"
                    disabled={!(isValid && dirty) || isSubmitting || !['ADMIN'].includes(user.role)}
                  >
                    Save
                  </Button>
                </div>
                {errors && !isValid && (
                  <div className="w-full px-6 py-2 mb-4 text-red-500 bg-red-500 rounded-lg lg:ml-4 lg:mb-0 lg:w-auto bg-opacity-20">
                    Please verify duration
                  </div>
                )}
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <div className="flex items-center justify-center px-12 py-24 border rounded-lg">
          <div className="flex flex-col items-center justify-center space-y-6">
            <span className="text-center text-secondary">
              Create your first facility to manage the bot settings
            </span>
            <Link
              to="/dashboard/"
              className="px-6 py-2 font-bold text-blue-500 transition rounded-md bg-blue-50 hover:bg-blue-100 focus:bg-blue-100 focus:outline-none focus:text-blue-600 hover:text-blue-600"
            >
              Create a facility
            </Link>
          </div>
        </div>
      )}
    </section>
  );
};

Bot.propTypes = {
  settings: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      delay: PropTypes.number.isRequired,
      unit: PropTypes.string.isRequired,
      selected: PropTypes.bool.isRequired,
    }).isRequired
  ),
  canEditChatbot: PropTypes.bool.isRequired,
  mutate: PropTypes.func.isRequired,
};

Bot.defaultProps = {
  settings: null,
};

export default Bot;
