import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { httpClient, mapRoles } from 'src/helpers';
import { Modal, Spinner, Snackbar, Avatar } from 'src/components';

const ModalReactivate = ({ open, requestClose, user, mutate }) => {
  const [snackbarState, setSnackbarState] = useState({ type: 'HIDDEN', msg: '' });
  const [isReactivating, setIsReactivating] = useState(false);

  const handleConfirm = async () => {
    setIsReactivating(true);

    try {
      const { status } = await httpClient.put(`/users/${user.id}/activate`);

      if (status === 200) {
        setTimeout(() => {
          setSnackbarState({ type: 'SUCCESS' });
        }, 1000);

        setTimeout(() => {
          mutate();
          requestClose();
        }, 2000);
      } else throw Error('Disabled user failed.');
    } catch (error) {
      setSnackbarState({ type: 'ERROR' });
      setIsReactivating(false);
    }
  };

  const handleCancel = () => {
    setIsReactivating(false);
    requestClose();
  };

  return (
    <Modal open={open} requestClose={requestClose}>
      {!isReactivating ? (
        <Modal.Body className="px-6 pt-6">
          <Snackbar
            type={snackbarState.type}
            message={snackbarState.msg}
            requestClose={() => setSnackbarState({ ...snackbarState, type: 'HIDDEN' })}
          />
          <div className="max-w-md mx-auto">
            <div className="max-w-md pt-6 pb-12 mx-auto">
              <h5 className="font-bold">Do you want to reactivate this user ?</h5>
              <div className="flex items-center pt-12 space-x-4">
                <Avatar
                  initials={`${user.firstName.slice(0, 1)}${user.lastName.slice(0, 1)}`}
                  role={user.roles[0]}
                />
                <div className="flex flex-col">
                  <span className="font-semibold capitalize">
                    {`${user.firstName} ${user.lastName}`}
                  </span>
                  <span className="text-xs capitalize lg:text-sm text-secondary">
                    {user.roles
                      .filter((r) => r !== 'USER')
                      .map(mapRoles)
                      .join(', ')}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center pb-6">
              <div className="flex items-center w-full space-x-4">
                <button
                  type="button"
                  onClick={() => handleCancel()}
                  className="w-1/2 py-4 font-semibold transition border-2 rounded-lg select-none focus:outline-none hover:bg-gray-200 focus:bg-gray-200"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={() => handleConfirm()}
                  className="w-full py-4 font-semibold transition border-2 rounded-lg select-none border-primary-light text-primary-light hover:border-primary-dark focus:border-primary-dark hover:bg-primary-dark focus:bg-primary-dark hover:text-white focus:text-white focus:outline-none"
                >
                  Reactivate user
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      ) : (
        <div className="flex justify-center p-6 text-primary-light">
          <Spinner />
        </div>
      )}
    </Modal>
  );
};

ModalReactivate.propTypes = {
  open: PropTypes.bool.isRequired,
  requestClose: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    roles: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }).isRequired,
  mutate: PropTypes.func.isRequired,
};

export default ModalReactivate;
