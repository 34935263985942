const initialValues = Object.freeze({
  facilityIndustry: '',
  facilityProduct: {},
  facilityName: '',
  facilityWebsiteUrl: '',
  facilityRooms: 0,
  facilityEmployees: 1,
  facilityAddress: '',
  facilityAdditionalAddress: '',
  facilityCity: '',
  facilityPostalCode: '',
  facilityCountry: 'US',
  facilityState: 'AL',
  facilityTimezone: '',
  manager: {
    newUser: false,
    firstName: '',
    lastName: '',
    title: '',
    email: '',
    phone: '',
  },
  marketing: {
    manager: {
      newUser: false,
      firstName: '',
      lastName: '',
      title: '',
      email: '',
      phone: '',
    },
    lead: {
      newUser: false,
      firstName: '',
      lastName: '',
      title: '',
      email: '',
      phone: '',
    },
  },
  contactEmail: '',
  contactPhone: '',
  preferedCurrency: 'USD',
  billingUseFacilityAddress: true,
  billingAddressee: '',
  billingAttention: '',
  billingAddresseePhone: '',
  billingAddress: '',
  billingAdditionalAddress: '',
  billingCity: '',
  billingPostalCode: '',
  billingCountry: 'US',
  billingState: 'AL',
  shippingUseBillingAddress: true,
  shippingAddressee: '',
  shippingAttention: '',
  shippingAddresseePhone: '',
  shippingAddress: '',
  shippingAdditionalAddress: '',
  shippingCity: '',
  shippingPostalCode: '',
  shippingCountry: 'US',
  shippingState: 'AL',
  acceptTerms: false,
});

export default initialValues;
