import React from 'react';
import { useSelector } from 'react-redux';
import { Router as ReachRouter, Redirect } from '@reach/router';

import views from 'src/constants/views';
import { NotFound } from 'src/components';

import Layout from './layout';

const Router = () => {
  const user = useSelector((state) => state.user);

  const load = (viewEnable, viewRoles) => {
    if (!viewEnable) return false;
    if (viewEnable && !viewRoles) return true;
    return viewRoles.includes(user.role);
  };

  return (
    <Layout userRole={user.role}>
      <ReachRouter>
        {views.dashboard.map(
          (v) => load(v.enable, v.roles) && <v.component key={v.path} path={v.path} />
        )}
        <Redirect
          from="/"
          to={views.dashboard.filter((v) => load(v.enable, v.roles))[0].path}
          noThrow
        />
        <NotFound default />
      </ReachRouter>
    </Layout>
  );
};

export default Router;
