import React from 'react';

import { InputField } from 'src/components';

const EditUserForm = () => (
  <div className="pb-12 space-y-12">
    <div className="space-y-6">
      <div className="space-y-6 md:flex md:space-x-6 md:space-y-0">
        <InputField name="firstName" label="forms.manager.firstName" namespaces={['dashboard']} />
        <InputField name="lastName" label="forms.manager.lastName" namespaces={['dashboard']} />
      </div>
      <InputField name="email" label="forms.manager.email" namespaces={['dashboard']} disabled />
      <InputField name="title" label="forms.manager.title" namespaces={['dashboard']} />
      <div className="flex w-1/2">
        <InputField name="phone" label="forms.manager.phone_optional" namespaces={['dashboard']} />
      </div>
    </div>
  </div>
);

export default EditUserForm;
