import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import URLS from 'src/constants/urls.json';

const CLASSNAMES = {
  VERIFIED: 'border-black hover:bg-secondary-grey-light focus:bg-secondary-grey-light',
  PENDING:
    'text-white border-primary-light bg-primary-light hover:bg-primary-light-hover hover:border-primary-light-hover focus:bg-primary-light-hover focus:border-primary-light-hover',
  UNVERIFIED:
    'text-white border-warning bg-warning hover:bg-warning-light hover:border-warning-light focus:bg-warning-light focus:border-warning-light',
  REVOKED:
    'text-white border-danger bg-danger hover:bg-danger-light hover:border-danger-light focus:bg-danger-light focus:border-danger-light',
};

const getBotLink = () => {
  const VERTICAL = process.env.GATSBY_VERTICAL;
  const DEPLOYMENT = process.env.GATSBY_DEPLOYMENT;

  switch (VERTICAL) {
    case 'FTG':
      return URLS.BOT[DEPLOYMENT];
    case 'DEKRA':
      return URLS.DEKRA[DEPLOYMENT];
    default:
      return URLS.ASSESSMENT[DEPLOYMENT];
  }
};

const Actions = ({ status, externalId }) => {
  const { t } = useTranslation('facility');

  // 2 or 3 buttons
  return (
    <div className="flex flex-col items-center space-y-4 md:flex-row md:space-y-0 md:space-x-6 md:max-w-screen-sm">
      <a
        href={`${getBotLink()}/healthsecuritycertification/healthSecurityverification.html`}
        onClick={() => {
          if (window.gtag) window.gtag('event', 'Clic_take_assessment');
        }}
        target="_blank"
        rel="noreferrer"
        className={`w-full md:w-3/5 max-w-md py-4 transition border-2 rounded-md text-center focus:outline-none ${CLASSNAMES[status]}`}
      >
        {t('badge.buttons.platform')}
      </a>

      <a
        href={`${getBotLink()}/certification/${externalId}`}
        className="w-full max-w-md py-4 text-center transition border-2 border-gray-300 rounded-md hover:bg-secondary-grey-light focus:bg-secondary-grey-light focus:outline-none md:w-2/5"
      >
        {t('badge.buttons.verification')}
      </a>

      {false && !['VACCINE', 'GREENSHIELD'].includes(process.env.GATSBY_VERTICAL) && (
        <a
          href={`${getBotLink()}/report?t=${dashboardId}`}
          className="w-full max-w-md py-4 text-center transition border-2 border-gray-300 rounded-md hover:bg-secondary-grey-light focus:bg-secondary-grey-light focus:outline-none md:w-2/5"
        >
          {t('badge.buttons.audit')}
        </a>
      )} */}
    </div>
  );
};

Actions.propTypes = {
  status: PropTypes.oneOf(['PENDING', 'VERIFIED', 'UNVERIFIED', 'REVOKED']).isRequired,
  externalId: PropTypes.string.isRequired,
  // dashboardId: PropTypes.string.isRequired,
};

export default Actions;
