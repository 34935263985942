import React from 'react';

import { InputField } from 'src/components';

const NewVehiculeForm = () => (
  <div className="pb-12 space-y-12">
    <div className="space-y-6">
      <span className="text-2xl font-bold">Add new vehicule</span>
      <div>
        <InputField name="name" label="forms.vehicule.name" namespaces={['dashboard']} />
        <span className="text-sm text-gray-500">Recommanded to put : Make - Model - Category</span>
      </div>
      <div className="w-1/2">
        <InputField
          name="internalId"
          label="forms.vehicule.internalId"
          namespaces={['dashboard']}
        />
      </div>
      <div className="w-1/2">
        <InputField name="plate" label="forms.vehicule.plate" namespaces={['dashboard']} />
      </div>
    </div>
    <div className="space-y-6">
      <span className="text-2xl font-bold">Region(s)</span>
      <div className="flex items-center space-x-4">
        <InputField type="checkbox" name="regions.LA" label="Los Angeles" translate={false} />
        <InputField type="checkbox" name="regions.NY" label="New York" translate={false} />
      </div>
    </div>
  </div>
);

export default NewVehiculeForm;
