import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import AsyncSelect from 'react-select/async';

import { httpClient } from 'src/helpers';
import { InputField, Avatar } from 'src/components';

const filterUsers = (input, me, data, youLabel, withMe) => {
  const match = (i) =>
    i.firstName.toLowerCase().includes(input.toLowerCase()) ||
    i.lastName.toLowerCase().includes(input.toLowerCase()) ||
    i.email.toLocaleLowerCase().includes(input.toLocaleLowerCase());

  const order = (a, b) => a.lastName.localeCompare(b.lastName);

  let users = [];

  if (!withMe) {
    if (input === '') users = [...data.filter(match).sort(order)];
    else users = [...data].filter(match).sort(order);
  }

  if (withMe) {
    if (input === '') users = [{ ...me, me: true, youLabel }, ...data.filter(match).sort(order)];
    else users = [{ ...me, me: true, youLabel }, ...data].filter(match).sort(order);
  }

  return users.map((u) => ({ ...u, value: u.email }));
};

const formatOptionLabel = ({ firstName, lastName, email, me, youLabel, roles }) => (
  <div className="flex items-center space-x-4">
    <Avatar initials={`${firstName.slice(0, 1)}${lastName.slice(0, 1)}`} role={roles[0]} />
    <div className="flex flex-col">
      <span className="text-base capitalize">
        {`${firstName} ${lastName} ${me ? youLabel : ''}`}
      </span>
      <p className="text-sm truncate text-secondary" style={{ maxWidth: '220px' }}>
        {email}
      </p>
    </div>
  </div>
);

const NewUserForm = ({ userRole, site, siteUsers, addUser, setAddUser }) => {
  const user = useSelector((state) => state.user);
  const { values, setValues } = useFormikContext();

  const [selectedUser, setSelectedUser] = useState(null);

  const fetchUsers = (input) =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`/users?clientId=${user.clientId}`)
        .then(({ data }) => {
          const siteIds = siteUsers.map((e) => e.id);
          const me = data.filter((e) => e.email === user.email && !siteIds.includes(e.id));
          const users = data.filter((e) => e.email !== user.email && !siteIds.includes(e.id));

          resolve(filterUsers(input, me.length > 0 ? me[0] : [], users, `(You)`, me.length > 0));
        })
        .catch((e) => reject(e));
    });

  const toggleSelect = (select) => {
    setSelectedUser(null);
    setValues(
      { ...values, newUser: select, firstName: '', lastName: '', email: '', phone: '' },
      true
    );
    setAddUser(select);
  };

  const handleSelect = (value) => {
    setSelectedUser(value);
    setValues(
      {
        ...values,
        firstName: value.firstName,
        lastName: value.lastName,
        email: value.email,
        phone: value.phone,
      },
      true
    );
  };

  return (
    <div className="pb-12 space-y-12">
      <div className="space-y-6">
        <div>
          <span className="text-2xl font-bold">Add user</span>
          <div className="flex items-center pt-4 space-x-4">
            <button
              type="button"
              onClick={() => toggleSelect(true)}
              className={`flex transition items-center space-x-3 text-sm hover:text-black focus:text-black focus:outline-none ${
                addUser ? 'text-black' : 'text-secondary'
              }`}
            >
              {addUser ? (
                <svg viewBox="0 0 18 18" className="w-5 h-5">
                  <rect width="18" height="18" rx="9" fill="#00BFA5" />
                  <path
                    d="M12.8981 6L12.3471 6.55583C10.8284 8.07785 9.20805 9.80543 7.69088 11.3486L5.59434 9.62263L4.98976 9.1253L4 10.3296L4.59971 10.8269L7.25207 13.0112L7.80302 13.4647L8.30034 12.9625C9.98054 11.2787 11.8008 9.30956 13.449 7.65773L14 7.1019L12.8981 6Z"
                    fill="white"
                  />
                </svg>
              ) : (
                <svg viewBox="0 0 18 18" className="w-5 h-5">
                  <rect
                    x="0.5"
                    y="0.5"
                    width="17"
                    height="17"
                    rx="8.5"
                    fill="white"
                    stroke="#CCD6DD"
                  />
                </svg>
              )}
              <span className="text-left">Create user</span>
            </button>
            <button
              type="button"
              onClick={() => toggleSelect(false)}
              className={`flex transition items-center space-x-3 text-sm hover:text-black focus:text-black focus:outline-none ${
                addUser ? 'text-secondary' : 'text-black'
              }`}
            >
              {addUser ? (
                <svg viewBox="0 0 18 18" className="w-5 h-5">
                  <rect
                    x="0.5"
                    y="0.5"
                    width="17"
                    height="17"
                    rx="8.5"
                    fill="white"
                    stroke="#CCD6DD"
                  />
                </svg>
              ) : (
                <svg viewBox="0 0 18 18" className="w-5 h-5">
                  <rect width="18" height="18" rx="9" fill="#00BFA5" />
                  <path
                    d="M12.8981 6L12.3471 6.55583C10.8284 8.07785 9.20805 9.80543 7.69088 11.3486L5.59434 9.62263L4.98976 9.1253L4 10.3296L4.59971 10.8269L7.25207 13.0112L7.80302 13.4647L8.30034 12.9625C9.98054 11.2787 11.8008 9.30956 13.449 7.65773L14 7.1019L12.8981 6Z"
                    fill="white"
                  />
                </svg>
              )}
              <span className="text-left">Select existing user</span>
            </button>
          </div>
        </div>
        {addUser ? (
          <>
            <div className="space-y-6 md:flex md:space-x-6 md:space-y-0">
              <InputField
                name="firstName"
                label="forms.manager.firstName"
                namespaces={['dashboard']}
              />
              <InputField
                name="lastName"
                label="forms.manager.lastName"
                namespaces={['dashboard']}
              />
            </div>
            <InputField name="email" label="forms.manager.email" namespaces={['dashboard']} />
            <div className="flex w-1/2">
              <InputField
                name="phone"
                label="forms.manager.phone_optional"
                namespaces={['dashboard']}
              />
            </div>
          </>
        ) : (
          <div>
            <div className="pb-2">
              <span className="text-sm font-bold text-secondary">USERS</span>
            </div>
            <AsyncSelect
              cacheOptions
              defaultOptions
              id="new-provider"
              classNamePrefix="r-select"
              loadOptions={fetchUsers}
              formatOptionLabel={formatOptionLabel}
              noOptionsMessage={() => 'No user'}
              loadingMessage={() => 'Loading...'}
              placeholder=""
              onChange={handleSelect}
              value={selectedUser}
              closeMenuOnSelect
            />
          </div>
        )}
      </div>
      <div>
        <span className="text-2xl font-bold">Site</span>
        <div className="pt-4 space-y-4">
          <div className="flex items-center px-2 py-4 border rounded bg-secondary-grey-light">
            <svg viewBox="0 0 75 80" className="h-12 fill-current text-secondary">
              <path d="M68.7458 21.718C65.9837 16.9349 42.6068 3.44434 37.0826 3.44434C31.5668 3.44434 8.18158 16.9349 5.41948 21.718C2.85106 26.1643 2.85106 53.8359 5.41948 58.2822C8.19001 63.0654 31.5668 76.5559 37.0826 76.5559C42.6068 76.5559 65.9837 63.0654 68.7458 58.2822C71.3142 53.8359 71.3142 26.1643 68.7458 21.718Z" />
              <path d="M71.7263 20C68.3747 14.2063 44.0379 0 37.0821 0C30.1263 0 5.78947 14.2063 2.43789 19.9916C-0.812631 25.6084 -0.812631 54.3916 2.43789 60C5.78947 65.7937 30.1263 80 37.0821 80C44.0379 80 68.3747 65.7937 71.7263 60.0084C74.9768 54.3916 74.9768 25.6084 71.7263 20ZM69.941 58.9726C66.8589 64.2863 43.2211 77.9368 37.0821 77.9368C30.9432 77.9368 7.29684 64.2863 4.22316 58.9642C1.35158 54.0042 1.35158 25.9874 4.22316 21.0274C7.30526 15.7137 30.9516 2.06316 37.0821 2.06316C43.2211 2.06316 66.8589 15.7053 69.941 21.0274C72.8126 25.9958 72.8126 54.0042 69.941 58.9726Z" />
            </svg>
            <div className="pl-4 text-sm text-left">
              <p className="font-bold truncate-2" style={{ maxWidth: '320px' }}>
                {site.name}
              </p>
              <div className="text-secondary">
                <span className="capitalize">{site.address.city}</span>
                <span>{`, `}</span>
                <span className="uppercase">{site.address.country}</span>
              </div>
            </div>
          </div>
          <div className="flex items-center space-x-2">
            <span className="whitespace-nowrap">Role * :</span>
            <InputField
              type="checkbox"
              name="sites[0].role.drm"
              label="Site manager"
              translation={false}
              disabled={userRole !== 'admin'}
            />
            <InputField
              type="checkbox"
              name="sites[0].role.sme"
              label="Provider"
              translation={false}
              disabled={userRole !== 'admin'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

NewUserForm.propTypes = {
  userRole: PropTypes.string.isRequired,
  site: PropTypes.shape({
    name: PropTypes.string.isRequired,
    address: PropTypes.shape({
      city: PropTypes.string.isRequired,
      country: PropTypes.string,
    }).isRequired,
  }).isRequired,
  siteUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,
  addUser: PropTypes.bool.isRequired,
  setAddUser: PropTypes.func.isRequired,
};

export default NewUserForm;
