import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { httpClient } from 'src/helpers';
import { Modal, Snackbar, Button } from 'src/components';
import EditUserForm from './EditUserForm';

const editUserSchema = Yup.object().shape({
  firstName: Yup.string().max(250, 'forms.too_long').required('forms.required'),
  lastName: Yup.string().max(250, 'forms.too_long').required('forms.required'),
  phone: Yup.string()
    .max(250, 'forms.too_long')
    .test('phone-regex', 'forms.format', (phone) => {
      if (!phone) return true;
      return phone.match(
        /^([+(]?|\d{1}|(\+?\d{2}))[ ]?(\(?\d{1,4}\)?)[- ]?\d{1,4}[- ]?\d{1,4}([- ]?\d{1,5})?$/
      );
    }),
  sites: Yup.array()
    .of(
      Yup.object().shape({
        data: Yup.object().shape({
          id: Yup.string().required('forms.required'),
          name: Yup.string().required('forms.required'),
          address: Yup.object().shape({
            city: Yup.string().required('forms.required'),
            country: Yup.string().required('forms.required'),
          }),
        }),
        role: Yup.object().shape(
          {
            sme: Yup.boolean().when('drm', { is: false, then: Yup.boolean().oneOf([true]) }),
            drm: Yup.boolean().when('sme', { is: false, then: Yup.boolean().oneOf([true]) }),
          },
          [['sme', 'drm']]
        ),
      })
    )
    .min(1, 'forms.required'),
});

const ModalEditUser = ({ open, requestClose, mutate, user, site, userRole }) => {
  const [snackbarState, setSnackbarState] = useState({ type: 'HIDDEN', msg: '' });

  const handleSubmit = async (values, actions) => {
    if (window.gtag) window.gtag('event', 'Edit_user');
    setSnackbarState({ type: 'LOADING' });
    actions.setSubmitting(true);

    try {
      document.getElementById('modal-body').scroll({ top: 0, behavior: 'smooth' });
      const { status } = await httpClient.put(`/facilities/${site.id}/users/${user.id}`, values);

      if (status === 200) {
        setSnackbarState({ type: 'SUCCESS' });
        mutate();

        setTimeout(() => {
          requestClose();
        }, 1000);
      } else throw Error('Facility creation failed.');
    } catch (err) {
      setSnackbarState({ type: 'ERROR' });
      actions.setSubmitting(false);
    }
  };

  return (
    <Modal open={open} requestClose={requestClose}>
      <Modal.Header>
        <div className="h-5" />
        <div className="absolute right-0 pr-6">
          <button type="button" onClick={requestClose}>
            <svg viewBox="0 0 20 20" className="w-5 h-5">
              <path d="M16.6952 0.51282C17.4549 -0.17094 18.6705 -0.17094 19.4302 0.51282C20.1899 1.27255 20.1899 2.48813 19.4302 3.24786L12.7445 10.0095L19.4302 16.6952C20.1899 17.4549 20.1899 18.6705 19.4302 19.4302C18.6705 20.1899 17.4549 20.1899 16.6952 19.4302L10.0095 12.7445L3.24786 19.4302C2.48813 20.1899 1.27255 20.1899 0.51282 19.4302C-0.17094 18.6705 -0.17094 17.4549 0.51282 16.6952L7.27445 10.0095L0.51282 3.24786C-0.17094 2.48813 -0.17094 1.27255 0.51282 0.51282C1.27255 -0.17094 2.48813 -0.17094 3.24786 0.51282L10.0095 7.27445L16.6952 0.51282Z" />
            </svg>
          </button>
        </div>
      </Modal.Header>
      <Modal.Body className="px-6">
        <Formik
          initialValues={{
            ...user,
            sites: [
              {
                data: {
                  id: Number.parseInt(site.id, 10),
                  name: site.name,
                  address: { city: site.address.city, country: site.address.country },
                },
                role: {
                  sme: user.roles.includes('VIEWER'),
                  drm: user.roles.includes('MANAGER'),
                },
              },
            ],
          }}
          validationSchema={editUserSchema}
          onSubmit={handleSubmit}
        >
          {({ isValid, isSubmitting, dirty }) => (
            <Form className="max-w-md mx-auto" autoComplete="off">
              <Snackbar
                type={snackbarState.type}
                message={snackbarState.msg}
                requestClose={() => setSnackbarState({ ...snackbarState, type: 'HIDDEN' })}
              />
              <EditUserForm site={site} userRole={userRole} />

              <div className="flex items-center justify-center pb-6">
                <div className="w-full max-w-md">
                  <div className="flex">
                    <Button
                      type="submit"
                      size="md"
                      disabled={
                        !(isValid && dirty) || isSubmitting || snackbarState.type === 'SUCCESS'
                      }
                    >
                      Update User
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

ModalEditUser.propTypes = {
  open: PropTypes.bool.isRequired,
  requestClose: PropTypes.func.isRequired,
  mutate: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    roles: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }).isRequired,
  site: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    address: PropTypes.shape({
      city: PropTypes.string.isRequired,
      country: PropTypes.string,
    }).isRequired,
  }).isRequired,
  userRole: PropTypes.string.isRequired,
};

export default ModalEditUser;
