import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import * as Yup from 'yup';
import debounce from 'lodash.debounce';
import ct from 'city-timezones';

import { httpClient } from 'src/helpers';
import { InputField } from 'src/components';
import countries from 'src/constants/countries.json';
import states from 'src/constants/states.json';

const addressLiteSchema = Yup.object().shape({
  facilityName: Yup.string()
    .max(250, 'forms.errors.facility.name')
    .required('forms.required')
    .test(
      'facility-name-backend-validation',
      'forms.errors.facility.taken',
      debounce(async (name) => {
        try {
          if (!name || name === '') return true;
          const { data } = await httpClient.get(`/facilities/${encodeURIComponent(name)}/exists`);
          return !data.exists;
        } catch (error) {
          return false;
        }
      }, 750)
    ),
  facilityRooms: Yup.number().min(1, 'forms.min.rooms').required('forms.required'),
  facilityAddress: Yup.string().required('forms.required').max(250, 'forms.too_long'),
  facilityAdditionalAddress: Yup.string().max(250, 'forms.too_long'),
  facilityCity: Yup.string().required('forms.required').max(250, 'forms.too_long'),
  facilityPostalCode: Yup.string().required('forms.required').max(250, 'forms.too_long'),
  facilityCountry: Yup.string().required('forms.required').max(250, 'forms.too_long'),
  facilityState: Yup.string().when('facilityCountry', {
    is: (val) => val === 'US' || val === 'CA',
    then: Yup.string().required('forms.required').max(250, 'forms.too_long'),
  }),
  facilityTimezone: Yup.string().required('forms.required').max(250, 'forms.too_long'),
});

const AddressFormLite = ({ product }) => {
  const { t } = useTranslation(['dashboard', 'countries', 'states']);
  const { values, setFieldValue } = useFormikContext();

  const [timezones, setTimezones] = useState([]);

  useEffect(() => {
    let tzs = ct.cityMapping.filter(
      (e) => (e.iso2 === values.facilityCountry || e.iso3 === values.facilityCountry) && e.timezone
    );

    // TODO refactor
    // handle countries not supported by city-timezones (ie: Anguilla)
    if (values.facilityCountry === 'AI') {
      tzs = [{ timezone: 'America/Anguilla' }];
    }

    const uniqueTzs = [...new Set(tzs.map((i) => i.timezone))];
    setTimezones(uniqueTzs);
    setFieldValue('facilityTimezone', uniqueTzs[0], true);
  }, [values.facilityCountry]);

  return (
    <div className="py-12">
      <div className="space-y-6">
        <span className="text-xl font-bold">{t('dashboard:forms.address.titles.information')}</span>
        <InputField name="facilityName" label="forms.address.name" namespaces={['dashboard']} />
        <div className="flex w-1/2">
          <InputField
            type="number"
            name="facilityRooms"
            label={`forms.units.${product.policy.unit}.quantity`}
            namespaces={['dashboard']}
          />
        </div>
      </div>
      <div className="pt-12 space-y-6">
        <span className="text-xl font-bold">{t(`forms.address.titles.address`)}</span>
        <InputField
          name="facilityAddress"
          label="forms.address.address"
          namespaces={['dashboard']}
        />
        <InputField
          name="facilityAdditionalAddress"
          label="forms.address.additionalAddress"
          namespaces={['dashboard']}
        />
        <InputField
          name="facilityCity"
          label="forms.address.city"
          autoComplete="city"
          namespaces={['dashboard']}
        />
        <div className="flex w-1/2">
          <InputField
            id="label-facility-postal"
            name="facilityPostalCode"
            label="forms.address.postal"
            autoComplete="zip"
            namespaces={['dashboard']}
          />
        </div>
        <InputField
          type="select"
          name="facilityCountry"
          label="forms.address.country"
          namespaces={['dashboard']}
          options={countries.map((country) => ({
            ...country,
            label: t(`countries:${country.label}`),
          }))}
        />
        {(values.facilityCountry === 'CA' || values.facilityCountry === 'US') &&
          states[values.facilityCountry] && (
            <InputField
              type="select"
              name="facilityState"
              label="forms.address.state"
              namespaces={['dashboard']}
              options={states[values.facilityCountry].map((state) => ({
                ...state,
                label: t(`states:${values.facilityCountry}.${state.label}`),
              }))}
            />
          )}
        {values.facilityCountry && timezones.length > 1 && (
          <InputField
            type="select"
            name="facilityTimezone"
            label="forms.address.timezone"
            namespaces={['dashboard']}
            options={timezones.map((tz) => ({ label: tz, value: tz }))}
          />
        )}
      </div>
    </div>
  );
};

AddressFormLite.propTypes = {
  product: PropTypes.shape({
    policy: PropTypes.shape({
      unit: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export { addressLiteSchema };

export default AddressFormLite;
