import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { v1 as uuidv1 } from 'uuid';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { DateTime } from 'luxon';

import { httpClient, homeportDups, topicsDups, everyDays, everyWeeks } from 'src/helpers';
import { Modal, Snackbar, Button } from 'src/components';
import NewJourneyForm from './NewJourneyForm';

const newShipSchema = Yup.object().shape({
  ship: Yup.object().shape({
    name: Yup.string().max(250, 'forms.too_long').required('forms.required'),
  }),
  trips: Yup.array()
    .of(
      Yup.object().shape({
        data: Yup.object().shape({
          homeport: Yup.string().max(250, 'forms.too_long').required('forms.required'),
          country: Yup.string().required('forms.required'),
          timezone: Yup.string().required('forms.required'),
          sails: Yup.array().of(Yup.string().required('forms.required')),
          repeat: Yup.string().when({
            is: (v) => v && v.length > 0,
            then: Yup.string().oneOf(['DAYS', 'WEEKS']).required(),
          }),
          end: Yup.string().when('repeat', {
            is: (v) => v && v.length > 0,
            then: Yup.string().required(),
          }),
        }),
      })
    )
    .min(1, 'forms.required'),
});

const initialValues = {
  ship: null,
  trips: [
    {
      id: uuidv1(),
      data: { homeport: '', country: 'US', timezone: '', sails: [''], repeat: '', end: '' },
    },
  ],
  assignments: [],
};

const formatValues = (values) => {
  const { ship, trips, assignments } = values;
  const payload = { ship, assignments, trips: [] };

  trips.forEach((trip) => {
    const { end, repeat, ...data } = trip.data;
    let sails = [];

    switch (repeat) {
      case 'DAYS': {
        sails = [...everyDays(trip.data.sails[0], trip.data.end)];
        break;
      }
      case 'WEEKS': {
        sails = [...everyWeeks(trip.data.sails[0], trip.data.end)];
        break;
      }
      default: {
        sails = [
          ...trip.data.sails.map((sail) => DateTime.fromJSDate(sail).toFormat('yyyy-MM-dd')),
        ];
        break;
      }
    }

    payload.trips.push({ ...trip, data: { ...data, sails } });
  });

  return payload;
};

const ModalNewJourney = ({ open, requestClose, mutate, topics, industries }) => {
  const [snackbarState, setSnackbarState] = useState({ type: 'HIDDEN', msg: '' });

  const handleSubmit = async (values, actions) => {
    actions.setSubmitting(true);
    if (window.gtag) window.gtag('event', 'Add_journey');
    setSnackbarState({ type: 'LOADING' });
    document.getElementById('modal-body').scroll({ top: 0, behavior: 'smooth' });

    if (homeportDups(values)) {
      setSnackbarState({ type: 'ERROR', msg: 'Please verify homeport names' });
      actions.setSubmitting(false);
      return;
    }

    if (topicsDups(topics, values)) {
      setSnackbarState({
        type: 'ERROR',
        msg: 'Each section must be at least selected once. Verify your assignment',
      });
      actions.setSubmitting(false);
      return;
    }

    try {
      const formatedValues = formatValues(values);
      const { status } = await httpClient.post('/journeys', formatedValues);

      if (status === 200) {
        setSnackbarState({ type: 'SUCCESS' });
        mutate();

        setTimeout(() => {
          requestClose();
        }, 1000);
      } else throw Error('Journey creation failed.');
    } catch (err) {
      if (err.response.status === 409 && err.response.data && err.response.data.code)
        switch (err.response.data.code) {
          case 'NAME':
            setSnackbarState({
              type: 'ERROR',
              msg:
                'One or more journey(s) already exist for this combinaison of ship, homeport and saildate(s)',
            });
            break;
          default:
            setSnackbarState({ type: 'ERROR' });
        }
      else setSnackbarState({ type: 'ERROR' });
      actions.setSubmitting(false);
    }
  };

  return (
    <Modal open={open} requestClose={requestClose}>
      <Modal.Header>
        <h4 className="text-lg font-bold">Create journey</h4>
        <div className="absolute right-0 pr-6">
          <button type="button" onClick={requestClose}>
            <svg viewBox="0 0 20 20" className="w-5 h-5">
              <path d="M16.6952 0.51282C17.4549 -0.17094 18.6705 -0.17094 19.4302 0.51282C20.1899 1.27255 20.1899 2.48813 19.4302 3.24786L12.7445 10.0095L19.4302 16.6952C20.1899 17.4549 20.1899 18.6705 19.4302 19.4302C18.6705 20.1899 17.4549 20.1899 16.6952 19.4302L10.0095 12.7445L3.24786 19.4302C2.48813 20.1899 1.27255 20.1899 0.51282 19.4302C-0.17094 18.6705 -0.17094 17.4549 0.51282 16.6952L7.27445 10.0095L0.51282 3.24786C-0.17094 2.48813 -0.17094 1.27255 0.51282 0.51282C1.27255 -0.17094 2.48813 -0.17094 3.24786 0.51282L10.0095 7.27445L16.6952 0.51282Z" />
            </svg>
          </button>
        </div>
      </Modal.Header>
      <Modal.Body className="px-6">
        <Formik
          initialValues={{ ...initialValues, industryId: industries[0].id }}
          validationSchema={newShipSchema}
          onSubmit={handleSubmit}
        >
          {({ isValid, isSubmitting, dirty }) => (
            <Form className="max-w-md mx-auto" autoComplete="off">
              <Snackbar
                type={snackbarState.type}
                message={snackbarState.msg}
                requestClose={() => setSnackbarState({ ...snackbarState, type: 'HIDDEN' })}
              />
              <NewJourneyForm topics={topics} />

              <div className="flex flex-col items-center justify-center pb-6">
                <div className="flex w-full">
                  <Button
                    type="submit"
                    size="md"
                    disabled={
                      !(isValid && dirty) || isSubmitting || snackbarState.type === 'SUCCESS'
                    }
                  >
                    Create Journey
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

ModalNewJourney.propTypes = {
  open: PropTypes.bool.isRequired,
  requestClose: PropTypes.func.isRequired,
  mutate: PropTypes.func.isRequired,
  topics: PropTypes.arrayOf(
    PropTypes.shape({
      topicMasterId: PropTypes.string.isRequired,
      topicName: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  industries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default ModalNewJourney;
