import React from 'react';
import PropTypes from 'prop-types';

import URLS from 'src/constants/urls.json';
import { Page } from 'src/components';

const getDashboardLink = () => {
  const VERTICAL = process.env.GATSBY_VERTICAL;
  const DEPLOYMENT = process.env.GATSBY_DEPLOYMENT;

  switch (VERTICAL) {
    case 'FTG':
      return URLS.BOT[DEPLOYMENT];
    case 'DEKRA':
      return URLS.DEKRA[DEPLOYMENT];
    case 'VULCAIN':
      return URLS.VULCAIN[DEPLOYMENT];
    default:
      return URLS.ASSESSMENT[DEPLOYMENT];
  }
};

const Dashboard = ({ facility }) => (
  <Page title={facility.name || 'Facility'}>
    <Page.Main className="h-screen pt-16">
      <iframe
        id="iframe"
        title="Dashboard"
        src={`${getDashboardLink()}/dashboard/?t=${encodeURIComponent(facility.dashboardId || '')}`}
        className="w-full h-full"
      />
    </Page.Main>
  </Page>
);

Dashboard.propTypes = {
  facility: PropTypes.shape({
    name: PropTypes.string.isRequired,
    dashboardId: PropTypes.string,
  }),
};

Dashboard.defaultProps = {
  facility: {
    dashboardId: undefined,
  },
};

export default Dashboard;
