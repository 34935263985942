import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import AsyncSelect from 'react-select/async';

import { httpClient } from 'src/helpers';
import { InputField, Avatar } from 'src/components';

const filterUsers = (input, me, data, youLabel, withMe) => {
  const match = (i) =>
    i.firstName.toLowerCase().includes(input.toLowerCase()) ||
    i.lastName.toLowerCase().includes(input.toLowerCase()) ||
    i.email.toLocaleLowerCase().includes(input.toLocaleLowerCase()) ||
    i.title.toLocaleLowerCase().includes(input.toLocaleLowerCase());

  const order = (a, b) => a.lastName.localeCompare(b.lastName);

  let users = [];

  if (!withMe) {
    if (input === '') users = [...data.filter(match).sort(order)];
    else users = [...data].filter(match).sort(order);
  }

  if (withMe) {
    if (input === '') users = [{ ...me, me: true, youLabel }, ...data.filter(match).sort(order)];
    else users = [{ ...me, me: true, youLabel }, ...data].filter(match).sort(order);
  }

  return users.map((u) => ({ ...u, value: u.email }));
};

const formatOptionLabel = ({ firstName, lastName, email, me, youLabel, roles }) => (
  <div className="flex items-center space-x-4">
    <Avatar initials={`${firstName.slice(0, 1)}${lastName.slice(0, 1)}`} role={roles[0]} />
    <div className="flex flex-col">
      <span className="text-base capitalize">
        {`${firstName} ${lastName} ${me ? youLabel : ''}`}
      </span>
      <p className="text-sm truncate text-secondary" style={{ maxWidth: '220px' }}>
        {email}
      </p>
    </div>
  </div>
);

const NewUserForm = ({ shipUsers }) => {
  const user = useSelector((state) => state.user);
  const { values, setValues, setTouched } = useFormikContext();

  const [selectedUser, setSelectedUser] = useState(null);

  const fetchUsers = (input) =>
    new Promise((resolve, reject) => {
      httpClient
        .get(`/users?clientId=${user.clientId}`)
        .then(({ data }) => {
          const siteIds = shipUsers.map((e) => e.id);
          const me = data.filter((e) => e.email === user.email && !siteIds.includes(e.id));
          const users = data.filter((e) => e.email !== user.email && !siteIds.includes(e.id));

          resolve(filterUsers(input, me.length > 0 ? me[0] : [], users, `(You)`, me.length > 0));
        })
        .catch((e) => reject(e));
    });

  const toggleSelect = (select) => {
    setSelectedUser(null);
    setTouched({});

    setValues(
      {
        ...values,
        user: { newUser: select, firstName: '', lastName: '', email: '', phone: '', title: '' },
      },
      true
    );
  };

  const handleSelect = (value) => {
    setSelectedUser(value);
    setValues({ ...values, user: { ...value } }, true);
  };

  return (
    <div className="pb-12">
      <div className="space-y-6">
        <div>
          <span className="text-2xl font-bold">Add SME</span>
          <div className="flex items-center pt-4 space-x-4">
            <button
              type="button"
              onClick={() => toggleSelect(true)}
              className={`flex transition items-center space-x-3 text-sm hover:text-black focus:text-black focus:outline-none ${
                values.user.newUser ? 'text-black' : 'text-secondary'
              }`}
            >
              {values.user.newUser ? (
                <svg viewBox="0 0 18 18" className="w-5 h-5">
                  <rect width="18" height="18" rx="9" fill="#00BFA5" />
                  <path
                    d="M12.8981 6L12.3471 6.55583C10.8284 8.07785 9.20805 9.80543 7.69088 11.3486L5.59434 9.62263L4.98976 9.1253L4 10.3296L4.59971 10.8269L7.25207 13.0112L7.80302 13.4647L8.30034 12.9625C9.98054 11.2787 11.8008 9.30956 13.449 7.65773L14 7.1019L12.8981 6Z"
                    fill="white"
                  />
                </svg>
              ) : (
                <svg viewBox="0 0 18 18" className="w-5 h-5">
                  <rect
                    x="0.5"
                    y="0.5"
                    width="17"
                    height="17"
                    rx="8.5"
                    fill="white"
                    stroke="#CCD6DD"
                  />
                </svg>
              )}
              <span className="text-left">Create user</span>
            </button>
            <button
              type="button"
              onClick={() => toggleSelect(false)}
              className={`flex transition items-center space-x-3 text-sm hover:text-black focus:text-black focus:outline-none ${
                values.user.newUser ? 'text-secondary' : 'text-black'
              }`}
            >
              {values.user.newUser ? (
                <svg viewBox="0 0 18 18" className="w-5 h-5">
                  <rect
                    x="0.5"
                    y="0.5"
                    width="17"
                    height="17"
                    rx="8.5"
                    fill="white"
                    stroke="#CCD6DD"
                  />
                </svg>
              ) : (
                <svg viewBox="0 0 18 18" className="w-5 h-5">
                  <rect width="18" height="18" rx="9" fill="#00BFA5" />
                  <path
                    d="M12.8981 6L12.3471 6.55583C10.8284 8.07785 9.20805 9.80543 7.69088 11.3486L5.59434 9.62263L4.98976 9.1253L4 10.3296L4.59971 10.8269L7.25207 13.0112L7.80302 13.4647L8.30034 12.9625C9.98054 11.2787 11.8008 9.30956 13.449 7.65773L14 7.1019L12.8981 6Z"
                    fill="white"
                  />
                </svg>
              )}
              <span className="text-left">Select existing user</span>
            </button>
          </div>
        </div>
        {values.user.newUser ? (
          <>
            <div className="space-y-6 md:flex md:space-x-6 md:space-y-0">
              <InputField
                name="user.firstName"
                label="forms.manager.firstName"
                namespaces={['dashboard']}
              />
              <InputField
                name="user.lastName"
                label="forms.manager.lastName"
                namespaces={['dashboard']}
              />
            </div>
            <InputField name="user.email" label="forms.manager.email" namespaces={['dashboard']} />
            <InputField name="user.title" label="forms.manager.title" namespaces={['dashboard']} />
            <div className="flex w-1/2">
              <InputField
                name="user.phone"
                label="forms.manager.phone_optional"
                namespaces={['dashboard']}
              />
            </div>
          </>
        ) : (
          <div>
            <div className="pb-2">
              <span className="text-sm font-bold text-secondary">USERS</span>
            </div>
            <AsyncSelect
              cacheOptions
              defaultOptions
              id="new-provider"
              classNamePrefix="r-select"
              loadOptions={fetchUsers}
              formatOptionLabel={formatOptionLabel}
              noOptionsMessage={() => 'No user'}
              loadingMessage={() => 'Loading...'}
              placeholder=""
              onChange={handleSelect}
              value={selectedUser}
              closeMenuOnSelect
            />
          </div>
        )}
      </div>
      <div className="pt-12 space-y-6">
        <div>
          <span className="text-xl font-bold">Assignments</span>
          <div className="pt-4">
            <span>Add user to all existing and not started journeys? *</span>
            <div className="flex w-1/2 pt-2 space-x-2">
              <InputField
                type="radio"
                name="assignments.previous"
                label="Yes"
                namespaces={['dashboard']}
                translate={false}
                value="1"
              />
              <InputField
                type="radio"
                name="assignments.previous"
                label="No"
                namespaces={['dashboard']}
                translate={false}
                value="0"
              />
            </div>
          </div>
        </div>
        {values.assignments.previous === '1' && (
          <div>
            <span className="text-sm font-bold">
              Select the applicable Health Security sections for this user*:
            </span>

            <div className="grid grid-cols-2 pt-4 gap-y-4 gap-x-6">
              {values.assignments.topics.map((topic, i) => (
                <InputField
                  key={topic.topicMasterId}
                  type="checkbox"
                  name={`assignments.topics[${i}].selected]`}
                  label={topic.topicName}
                  namespaces={['dashboard']}
                  translate={false}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

NewUserForm.propTypes = {
  shipUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,
};

export default NewUserForm;
