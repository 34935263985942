import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { httpClient, findDupeIndex } from 'src/helpers';
import { Modal, Snackbar, Button } from 'src/components';
import NewShipForm from './NewShipForm';

const newShipSchema = Yup.object().shape({
  shipName: Yup.string().max(250, 'forms.errors.facility.name').required('forms.required'),
  drm: Yup.object().shape({
    firstName: Yup.string().max(250, 'forms.too_long').required('forms.required'),
    lastName: Yup.string().max(250, 'forms.too_long').required('forms.required'),
    email: Yup.string().max(250, 'forms.too_long').email('forms.format').required('forms.required'),
    title: Yup.string().max(250, 'forms.too_long').required('forms.required'),
    phone: Yup.string()
      .max(250, 'forms.too_long')
      .test('phone-match-regex', 'forms.format', (phone) => {
        if (!phone || phone === '') return true;
        return phone.match(
          /^([+(]?|\d{1}|(\+?\d{2}))[ ]?(\(?\d{1,4}\)?)[- ]?\d{1,4}[- ]?\d{1,4}([- ]?\d{1,5})?$/
        );
      })
      .nullable(),
  }),
  sme: Yup.array().of(
    Yup.object().shape({
      firstName: Yup.string().max(250, 'forms.too_long').required('forms.required'),
      lastName: Yup.string().max(250, 'forms.too_long').required('forms.required'),
      email: Yup.string()
        .email('forms.format')
        .max(250, 'forms.too_long')
        .required('forms.required'),
      title: Yup.string().max(250, 'forms.too_long').required('forms.required'),
      phone: Yup.string()
        .max(250, 'forms.too_long')
        .test('phone-match-regex', 'forms.format', (phone) => {
          if (!phone || phone === '') return true;
          return phone.match(
            /^([+(]?|\d{1}|(\+?\d{2}))[ ]?(\(?\d{1,4}\)?)[- ]?\d{1,4}[- ]?\d{1,4}([- ]?\d{1,5})?$/
          );
        })
        .nullable(),
    })
  ),
});

const initialValues = {
  shipName: '',
  drm: {
    firstName: '',
    lastName: '',
    email: '',
    title: '',
    phone: '',
  },
  sme: [],
};

const formatValues = (values) => ({
  type: 'ship',
  name: values.shipName,
  drmUser: {
    role: 'manager',
    id: values.drm.id,
    firstName: values.drm.firstName,
    lastName: values.drm.lastName,
    email: values.drm.email,
    title: values.drm.title,
    phone: { number: values.drm.phone },
  },
  smeUsers: values.sme.map((sme) => ({
    role: 'viewer',
    id: sme.id,
    firstName: sme.firstName,
    lastName: sme.lastName,
    email: sme.email,
    title: sme.title,
    phone: { number: sme.phone },
  })),
});

const emailDups = (users, values) => {
  const payload = { error: false, email: '' };

  const emails = users.map((u) => u.email.toLowerCase());
  values.sme.filter((s) => !s.id).forEach((sme) => emails.push(sme.email.toLowerCase()));
  if (!values.drm.id) emails.push(values.drm.email.toLowerCase());

  const dupe = findDupeIndex(emails);

  if (dupe !== -1) {
    payload.email = emails[dupe];
    payload.error = true;
  }

  return payload;
};

const ModalNewShip = ({ open, requestClose, mutate, users }) => {
  const user = useSelector((state) => state.user);
  const [snackbarState, setSnackbarState] = useState({ type: 'HIDDEN', msg: '' });

  const handleSubmit = async (values, actions) => {
    actions.setSubmitting(true);
    setSnackbarState({ type: 'LOADING' });
    document.getElementById('modal-body').scroll({ top: 0, behavior: 'smooth' });

    const dedupsEmail = emailDups(users, values);

    if (dedupsEmail.error) {
      setSnackbarState({
        type: 'ERROR',
        msg: `${dedupsEmail.email} is already used. Please select existing user or change email.`,
      });
      actions.setSubmitting(false);
      return;
    }

    try {
      if (window.gtag) window.gtag('event', 'Add_ship');

      const formatedValues = formatValues(values);
      formatedValues.clientId = user.clientId;

      const { status } = await httpClient.post('/subclients', formatedValues);

      if (status === 200) {
        setSnackbarState({ type: 'SUCCESS' });
        mutate();

        setTimeout(() => {
          requestClose();
        }, 1000);
      } else throw Error('Facility creation failed.');
    } catch (err) {
      if (err.response.status === 409 && err.response.data && err.response.data.code)
        switch (err.response.data.code) {
          case 'EMAIL':
            setSnackbarState({
              type: 'ERROR',
              msg: `${err.response.data.data} is already used. Please select existing user or change email.`,
            });
            break;
          case 'NAME':
            setSnackbarState({ type: 'ERROR', msg: 'Ship name already exists' });
            break;
          default:
            setSnackbarState({ type: 'ERROR' });
        }
      else setSnackbarState({ type: 'ERROR' });
      actions.setSubmitting(false);
    }
  };

  return (
    <Modal open={open} requestClose={requestClose}>
      <Modal.Header>
        <h4 className="text-lg font-bold">Add new ship</h4>
        <div className="absolute right-0 pr-6">
          <button type="button" onClick={requestClose}>
            <svg viewBox="0 0 20 20" className="w-5 h-5">
              <path d="M16.6952 0.51282C17.4549 -0.17094 18.6705 -0.17094 19.4302 0.51282C20.1899 1.27255 20.1899 2.48813 19.4302 3.24786L12.7445 10.0095L19.4302 16.6952C20.1899 17.4549 20.1899 18.6705 19.4302 19.4302C18.6705 20.1899 17.4549 20.1899 16.6952 19.4302L10.0095 12.7445L3.24786 19.4302C2.48813 20.1899 1.27255 20.1899 0.51282 19.4302C-0.17094 18.6705 -0.17094 17.4549 0.51282 16.6952L7.27445 10.0095L0.51282 3.24786C-0.17094 2.48813 -0.17094 1.27255 0.51282 0.51282C1.27255 -0.17094 2.48813 -0.17094 3.24786 0.51282L10.0095 7.27445L16.6952 0.51282Z" />
            </svg>
          </button>
        </div>
      </Modal.Header>
      <Modal.Body className="px-6">
        <Formik
          initialValues={initialValues}
          validationSchema={newShipSchema}
          onSubmit={handleSubmit}
        >
          {({ isValid, isSubmitting, dirty }) => (
            <Form className="max-w-md mx-auto" autoComplete="off">
              <Snackbar
                type={snackbarState.type}
                message={snackbarState.msg}
                requestClose={() => setSnackbarState({ type: 'HIDDEN' })}
              />
              <NewShipForm
                users={users.map((u) => {
                  if (u.email === user.email) return { ...u, me: true };
                  return u;
                })}
              />

              <div className="flex flex-col items-center justify-center pb-6">
                <div className="flex w-full">
                  <Button
                    type="submit"
                    size="md"
                    disabled={
                      !(isValid && dirty) || isSubmitting || snackbarState.type === 'SUCCESS'
                    }
                  >
                    Create Ship
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

ModalNewShip.propTypes = {
  open: PropTypes.bool.isRequired,
  requestClose: PropTypes.func.isRequired,
  mutate: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
};

export default ModalNewShip;
