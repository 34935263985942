import React from 'react';
import PropTypes from 'prop-types';

import { Page } from 'src/components';
import { PropertyBadge, PropertyRessources, PropertyInformations } from './components';

const Facility = ({ facility, mutate }) => (
  <Page title={facility.name || 'Facility'}>
    <Page.Main className="pt-16">
      <div className="flex flex-col">
        {process.env.GATSBY_VERTICAL !== 'GREENSHIELD' && (
          <PropertyBadge
            id={facility.id}
            externalId={facility.externalId}
            dashboardId={facility.dashboardId}
          />
        )}
        <PropertyRessources facilityId={facility.id} />
        <PropertyInformations facility={facility} mutate={mutate} />
      </div>
    </Page.Main>
  </Page>
);

Facility.propTypes = {
  facility: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    employees: PropTypes.number.isRequired,
    rooms: PropTypes.number.isRequired.isRequired,
    unit: PropTypes.string.isRequired,
    address: PropTypes.shape({
      line1: PropTypes.string.isRequired,
      line2: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      zipCode: PropTypes.string.isRequired,
    }),
    drmUser: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      roles: PropTypes.arrayOf(PropTypes.string.isRequired),
    }).isRequired,
    gmUser: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      roles: PropTypes.arrayOf(PropTypes.string.isRequired),
    }),
    prUser: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      roles: PropTypes.arrayOf(PropTypes.string.isRequired),
    }),
    status: PropTypes.oneOf(['PENDING', 'VERIFIED', 'UNVERIFIED', 'REVOKED']),
    timezone: PropTypes.string.isRequired,
    dashboardId: PropTypes.string.isRequired,
    externalId: PropTypes.string.isRequired,
  }),
  mutate: PropTypes.func.isRequired,
};

Facility.defaultProps = {
  facility: {
    status: 'PENDING',
    address: {},
    gmUser: null,
    prUser: null,
  },
};

export default Facility;
