import React from 'react';
import PropTypes from 'prop-types';

import URLS from 'src/constants/urls.json';
import { STATUS } from 'src/helpers';

const Journey = ({ journey, status, openView, openEdit, openDelete, editable, removable }) => (
  <div className="grid grid-cols-2 text-sm transition rounded-md bg-secondary-grey-light sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-7 hover:bg-secondary-hover">
    <div className="p-4 xl:hidden">
      <p className="font-bold capitalize">{journey.ship.name}</p>
      <div className="flex flex-col">
        {journey.externalChatbotFacilityId && (
          <span className="text-sm lowercase">({journey.externalChatbotFacilityId})</span>
        )}
        <span className="text-sm capitalize">{journey.homeport}</span>
        <span className="text-sm">{journey.sail.substring(0, journey.sail.indexOf('T'))}</span>
      </div>
    </div>
    <div className="flex justify-end p-4 sm:hidden">
      <div>
        {status && (
          <div className="flex items-center space-x-2">
            <strong className={`uppercase ${STATUS[status].color}`}>{status}</strong>
            <span>{STATUS[status].icon}</span>
          </div>
        )}
        <a
          href={`${URLS.CRUISES[process.env.GATSBY_DEPLOYMENT]}/dashboard?t=${encodeURIComponent(
            journey.externalDashboard || ''
          )}`}
          target="_blank"
          rel="noreferrer"
          className="text-blue-500 transition hover:text-blue-600 focus:text-blue-600 hover:underline focus:underline"
        >
          Access dashboard
        </a>
      </div>
    </div>

    <div className="items-center hidden py-4 pl-4 xl:space-x-2 xl:flex">
      <p className="font-bold">{journey.ship.name}</p>
      {journey.externalChatbotFacilityId && (
        <span className="text-sm lowercase">({journey.externalChatbotFacilityId})</span>
      )}
    </div>
    <div className="items-center justify-center hidden py-4 capitalize xl:flex">
      {journey.homeport}
    </div>
    <div className="items-center justify-center hidden py-4 xl:flex">
      {journey.sail.substring(0, journey.sail.indexOf('T'))}
    </div>
    <div className="items-center justify-center hidden py-4 sm:flex">
      {status && (
        <div className="flex items-center space-x-2">
          <strong className={`uppercase ${STATUS[status].color}`}>{status}</strong>
          <span>{STATUS[status].icon}</span>
        </div>
      )}
    </div>
    <div className="items-center justify-center hidden py-4 capitalize md:flex">
      {`${journey.owner.firstName} ${journey.owner.lastName}`}
    </div>
    <div className="items-center justify-center hidden py-4 sm:flex">
      <a
        href={`${URLS.CRUISES[process.env.GATSBY_DEPLOYMENT]}/dashboard?t=${encodeURIComponent(
          journey.externalDashboard || ''
        )}`}
        target="_blank"
        rel="noreferrer"
        className="text-blue-500 transition hover:text-blue-600 focus:text-blue-600 hover:underline focus:underline"
      >
        Access dashboard
      </a>
    </div>
    {editable || removable ? (
      <div className="items-center justify-center hidden py-4 space-x-6 lg:flex">
        {editable ? (
          <button type="button" onClick={openEdit} className="focus:outline-none">
            <svg viewBox="0 0 21 21" className="w-5 h-5 fill-current text-secondary">
              <path d="M19.1364 9.63636C18.6364 9.63636 18.2727 10.0455 18.2727 10.5V17.8636C18.2727 18.3636 17.8636 18.7727 17.3636 18.7727H2.63636C2.13636 18.7727 1.72727 18.3636 1.72727 17.8636V3.13636C1.72727 2.63636 2.13636 2.22727 2.63636 2.22727H10C10.5 2.22727 10.8636 1.81818 10.8636 1.36364C10.8636 0.909091 10.5 0.5 10 0.5H2.63636C1.18182 0.5 0 1.68182 0 3.13636V17.8182C0 19.3182 1.18182 20.5 2.63636 20.5H17.3182C18.7727 20.5 20 19.3182 20 17.8182V10.5C20 10 19.5909 9.63636 19.1364 9.63636Z" />
              <path d="M19.4996 4.45452C20.136 3.81816 20.136 2.77271 19.4996 2.09089L18.4087 0.999978C17.7724 0.363614 16.7269 0.363614 16.0451 0.999978L14.8633 2.1818L18.3178 5.63634L19.4996 4.45452Z" />
              <path d="M6.63681 10.4091L5.72772 14.4091C5.68226 14.6364 5.86408 14.8636 6.09135 14.7727L10.0459 13.8636L17.3186 6.5909L13.8641 3.13635L6.63681 10.4091Z" />
            </svg>
          </button>
        ) : (
          <div className="w-5" />
        )}
        {removable ? (
          <button type="button" onClick={openDelete} className="focus:outline-none">
            <svg viewBox="0 0 21 21" className="w-5 h-5 fill-current text-secondary">
              <path d="M17.565 19.005L18.1649 7H18.1992C18.7562 7 19.2903 6.77875 19.6841 6.38492C20.078 5.9911 20.2992 5.45695 20.2992 4.9C20.2992 4.34305 20.078 3.8089 19.6841 3.41508C19.2903 3.02125 18.7562 2.8 18.1992 2.8H14.5452L14.2435 1.5904C14.1299 1.13604 13.8676 0.732708 13.4984 0.444538C13.1291 0.156367 12.6742 -0.000103913 12.2058 8.11262e-07H8.82482C8.36241 -0.000405499 7.9128 0.15182 7.54575 0.433057C7.1787 0.714293 6.91474 1.10881 6.79482 1.5554L6.46232 2.8H2.79922C2.24226 2.8 1.70812 3.02125 1.31429 3.41508C0.920468 3.8089 0.699219 4.34305 0.699219 4.9C0.699219 5.45695 0.920468 5.9911 1.31429 6.38492C1.70812 6.77875 2.24226 7 2.79922 7H2.83352L3.43342 19.0043C3.46017 19.5423 3.6926 20.0494 4.08266 20.4208C4.47273 20.7922 4.9906 20.9996 5.52922 21H15.4692C16.0077 20.9996 16.5255 20.7923 16.9155 20.4211C17.3056 20.0498 17.5381 19.5428 17.565 19.005ZM8.14932 1.918C8.18932 1.76942 8.27718 1.63816 8.39928 1.54452C8.52139 1.45089 8.67094 1.40009 8.82482 1.4H12.2058C12.3621 1.39989 12.5139 1.45206 12.6371 1.54822C12.7602 1.64438 12.8477 1.77898 12.8855 1.9306L13.1032 2.8H7.90922L8.14932 1.918ZM2.09922 4.9C2.09922 4.71435 2.17297 4.5363 2.30424 4.40503C2.43552 4.27375 2.61357 4.2 2.79922 4.2H18.1992C18.3849 4.2 18.5629 4.27375 18.6942 4.40503C18.8255 4.5363 18.8992 4.71435 18.8992 4.9C18.8992 5.08565 18.8255 5.2637 18.6942 5.39497C18.5629 5.52625 18.3849 5.6 18.1992 5.6H2.79922C2.61357 5.6 2.43552 5.52625 2.30424 5.39497C2.17297 5.2637 2.09922 5.08565 2.09922 4.9ZM4.82922 18.935L4.23492 7H16.7649L16.1692 18.9357C16.1601 19.1151 16.0823 19.2841 15.952 19.4078C15.8217 19.5314 15.6488 19.6002 15.4692 19.6H5.52922C5.34947 19.6002 5.17653 19.5313 5.04622 19.4075C4.9159 19.2837 4.83821 19.1145 4.82922 18.935Z" />
              <path d="M14.0335 9.79999C13.9415 9.79528 13.8495 9.80875 13.7628 9.83964C13.676 9.87053 13.5963 9.91822 13.528 9.98C13.4597 10.0418 13.4043 10.1164 13.3649 10.1996C13.3255 10.2829 13.3029 10.373 13.2985 10.465L13.0185 16.065C13.0092 16.2506 13.074 16.4324 13.1987 16.5702C13.3234 16.7081 13.4978 16.7907 13.6835 16.8C13.8691 16.8093 14.0508 16.7444 14.1887 16.6197C14.3265 16.495 14.4092 16.3206 14.4185 16.135L14.6985 10.535C14.7032 10.443 14.6897 10.3511 14.6588 10.2643C14.6279 10.1776 14.5802 10.0978 14.5184 10.0295C14.4567 9.96125 14.382 9.90583 14.2988 9.86644C14.2156 9.82705 14.1254 9.80447 14.0335 9.79999Z" />
              <path d="M6.96475 9.80004C6.87278 9.80452 6.78261 9.8271 6.69938 9.86649C6.61616 9.90588 6.54153 9.96129 6.47975 10.0296C6.41798 10.0978 6.37028 10.1776 6.3394 10.2644C6.30851 10.3511 6.29503 10.4431 6.29975 10.535L6.57975 16.135C6.59165 16.3197 6.67508 16.4925 6.81232 16.6167C6.94956 16.7408 7.12979 16.8066 7.31475 16.8C7.40671 16.7956 7.49688 16.773 7.58011 16.7336C7.66333 16.6942 7.73797 16.6388 7.79974 16.5705C7.86151 16.5022 7.90921 16.4224 7.9401 16.3357C7.97099 16.249 7.98446 16.157 7.97975 16.065L7.69975 10.465C7.6896 10.2797 7.6067 10.1059 7.46906 9.98133C7.33141 9.8568 7.15017 9.79164 6.96475 9.80004Z" />
              <path d="M10.4988 9.80005C10.3132 9.80005 10.1351 9.8738 10.0039 10.0051C9.87258 10.1363 9.79883 10.3144 9.79883 10.5V16.1C9.79883 16.2857 9.87258 16.4637 10.0039 16.595C10.1351 16.7263 10.3132 16.8 10.4988 16.8C10.6845 16.8 10.8625 16.7263 10.9938 16.595C11.1251 16.4637 11.1988 16.2857 11.1988 16.1V10.5C11.1988 10.3144 11.1251 10.1363 10.9938 10.0051C10.8625 9.8738 10.6845 9.80005 10.4988 9.80005Z" />
            </svg>
          </button>
        ) : (
          <div className="w-5" />
        )}
      </div>
    ) : (
      <div className="items-center justify-center hidden py-4 space-x-6 lg:flex">
        <button type="button" onClick={openView} className="focus:outline-none">
          <svg viewBox="0 0 24 24" className="w-5 h-5 fill-current stroke-current text-secondary">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              fill="none"
              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              fill="none"
              d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
            />
          </svg>
        </button>
      </div>
    )}
  </div>
);

Journey.propTypes = {
  journey: PropTypes.shape({
    ship: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    homeport: PropTypes.string.isRequired,
    sail: PropTypes.string.isRequired,
    externalDashboard: PropTypes.string.isRequired,
    externalChatbotFacilityId: PropTypes.string,
    owner: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  status: PropTypes.oneOf(['VERIFIED', 'PENDING', 'UNVERIFIED', 'REVOKED', 'DELETED']),
  openView: PropTypes.func.isRequired,
  openEdit: PropTypes.func.isRequired,
  openDelete: PropTypes.func.isRequired,
  editable: PropTypes.bool.isRequired,
  removable: PropTypes.bool.isRequired,
};

Journey.defaultProps = {
  status: null,
};

export default Journey;
