import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { Router as ReachRouter } from '@reach/router';
import useSWR from 'swr';

import views from 'src/constants/views';
import { Page, NotFound, Spinner } from 'src/components';
import Layout from './layout';

const Router = ({ siteId }) => {
  const { data, error } = useSWR(siteId ? [`/facilities/${siteId}`] : null);

  if (error) {
    return <NotFound />;
  }

  if (!data) {
    return (
      <Page title="Site">
        <Page.Main className="container pt-24">
          <div className="flex justify-center text-primary-light">
            <Spinner />
          </div>
        </Page.Main>
      </Page>
    );
  }

  if (data.statusChatbot === 'PENDING' || data.statusChatbot === 'FAILED') {
    navigate('/dashboard/', { replace: true });
    return null;
  }

  return (
    <Layout site={{ ...data, id: `${siteId}` }}>
      <ReachRouter>
        {views.site.map(
          (v) =>
            v.enable && (
              <v.component
                key={v.path}
                path={v.path}
                site={{ ...data, id: `${siteId.toString()}` }}
                userRole={data.userRole}
              />
            )
        )}
        <NotFound default />
      </ReachRouter>
    </Layout>
  );
};

Router.propTypes = {
  siteId: PropTypes.string,
};

Router.defaultProps = {
  siteId: null,
};

export default Router;
