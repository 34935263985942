import React from 'react';
import { useField } from 'formik';

import { AssignmentForm } from 'src/views/app/shared';

const UpdateAssignmentsForm = () => {
  const [{ value: facility }] = useField('facility');
  const [{ value: assignments }] = useField('assignments');

  return (
    <div className="max-w-md pt-4 pb-12">
      <div className="flex items-center p-2 border rounded bg-secondary-grey-light">
        <svg viewBox="0 0 75 80" className="h-12 fill-current text-secondary">
          <path d="M68.7458 21.718C65.9837 16.9349 42.6068 3.44434 37.0826 3.44434C31.5668 3.44434 8.18158 16.9349 5.41948 21.718C2.85106 26.1643 2.85106 53.8359 5.41948 58.2822C8.19001 63.0654 31.5668 76.5559 37.0826 76.5559C42.6068 76.5559 65.9837 63.0654 68.7458 58.2822C71.3142 53.8359 71.3142 26.1643 68.7458 21.718Z" />
          <path d="M71.7263 20C68.3747 14.2063 44.0379 0 37.0821 0C30.1263 0 5.78947 14.2063 2.43789 19.9916C-0.812631 25.6084 -0.812631 54.3916 2.43789 60C5.78947 65.7937 30.1263 80 37.0821 80C44.0379 80 68.3747 65.7937 71.7263 60.0084C74.9768 54.3916 74.9768 25.6084 71.7263 20ZM69.941 58.9726C66.8589 64.2863 43.2211 77.9368 37.0821 77.9368C30.9432 77.9368 7.29684 64.2863 4.22316 58.9642C1.35158 54.0042 1.35158 25.9874 4.22316 21.0274C7.30526 15.7137 30.9516 2.06316 37.0821 2.06316C43.2211 2.06316 66.8589 15.7053 69.941 21.0274C72.8126 25.9958 72.8126 54.0042 69.941 58.9726Z" />
        </svg>
        <div className="pl-4 text-sm text-left">
          <p className="font-bold truncate-2" style={{ maxWidth: '320px' }}>
            {facility.name}
          </p>
        </div>
      </div>

      <div className="pt-12 space-y-8">
        <div>
          <span className="text-xl font-bold">Assignments</span>
          <p className="pt-1 text-sm text-secondary">
            Select the application Health Security sections for each user.
          </p>
        </div>
        {assignments.map((assignment, i) => (
          <AssignmentForm
            key={assignment.user.id}
            index={i}
            user={assignment.user}
            topics={assignment.topics}
          />
        ))}
      </div>
    </div>
  );
};

export default UpdateAssignmentsForm;
