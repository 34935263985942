import React from 'react';
import PropTypes from 'prop-types';

import { Accordion, Avatar, InputField } from 'src/components';

const AssignmentForm = ({ index, user, topics, editable, defaultOpen }) => (
  <Accordion defaultOpen={defaultOpen}>
    <Accordion.Header className="px-4 rounded-sm bg-secondary-grey-light">
      <div className="flex items-center py-1 text-sm">
        <Avatar
          initials={`${user.firstName.slice(0, 1)}${user.lastName.slice(0, 1)}`}
          role={user.roles[0]}
        />
        <div className="flex items-center pl-4 space-x-2">
          <span>{`${user.firstName} ${user.lastName}`}</span>
          {user.title && (
            <>
              <span className="text-xs font-normal capitalize text-secondary">&bull;</span>
              <span className="text-xs font-normal capitalize text-secondary">{user.title}</span>
            </>
          )}
        </div>
      </div>
    </Accordion.Header>
    <Accordion.Body>
      <div className="grid grid-cols-2 px-6 gap-y-4 gap-x-6">
        {topics.map((topic, i) => (
          <InputField
            key={`${user.id + topic.topicName}`}
            type="checkbox"
            name={`assignments[${index}.topics[${i}].selected]`}
            label={topic.topicName}
            namespaces={['dashboard']}
            translate={false}
            disabled={!editable}
          />
        ))}
      </div>
    </Accordion.Body>
  </Accordion>
);

AssignmentForm.propTypes = {
  index: PropTypes.number.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    title: PropTypes.string,
    roles: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }).isRequired,
  topics: PropTypes.arrayOf(
    PropTypes.shape({
      topicMasterId: PropTypes.string.isRequired,
      topicName: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  editable: PropTypes.bool,
  defaultOpen: PropTypes.bool,
};

AssignmentForm.defaultProps = {
  editable: true,
  defaultOpen: false,
};

export default AssignmentForm;
