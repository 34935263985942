import React from 'react';

// Common helpers for formatting Dates, text to look pretty, and other things like that

// Show the words 'Not Exposed', 'Exposed', or '' depending on the data I get from API call
export const exposureText = (status) => {
  switch (status) {
    case 'EXPOSED':
      return (
        <div className="flex flex-row items-center gap-1">
          <svg
            className="w-4 h-4"
            width="21"
            height="20"
            viewBox="0 0 11 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.17806 0.205128C7.48196 -0.0683761 7.96819 -0.0683761 8.27208 0.205128C8.57597 0.509022 8.57597 0.995252 8.27208 1.29915L5.59782 4.0038L8.27208 6.67806C8.57597 6.98196 8.57597 7.46819 8.27208 7.77208C7.96819 8.07597 7.48196 8.07597 7.17806 7.77208L4.5038 5.09782L1.79915 7.77208C1.49525 8.07597 1.00902 8.07597 0.705128 7.77208C0.431624 7.46819 0.431624 6.98196 0.705128 6.67806L3.40978 4.0038L0.705128 1.29915C0.431624 0.995252 0.431624 0.509022 0.705128 0.205128C1.00902 -0.0683761 1.49525 -0.0683761 1.79915 0.205128L4.5038 2.90978L7.17806 0.205128Z"
              fill="#E2574C"
            />
          </svg>
          <span>Exposed</span>
        </div>
      );
    case 'NOT EXPOSED':
      return (
        <div className="flex flex-row items-center gap-1">
          <svg
            className="w-4 h-4"
            width="21"
            height="20"
            viewBox="0 0 11 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.5 5C10.5 7.76143 8.26143 10 5.5 10C2.73857 10 0.5 7.76143 0.5 5C0.5 2.23857 2.73857 0 5.5 0C8.26143 0 10.5 2.23857 10.5 5ZM4.92165 7.64746L8.63133 3.93778C8.7573 3.81181 8.7573 3.60756 8.63133 3.48159L8.17514 3.0254C8.04917 2.89942 7.84492 2.89942 7.71893 3.0254L4.69355 6.05077L3.28107 4.63829C3.1551 4.51232 2.95085 4.51232 2.82486 4.63829L2.36867 5.09448C2.2427 5.22044 2.2427 5.4247 2.36867 5.55066L4.46544 7.64744C4.59143 7.77343 4.79567 7.77343 4.92165 7.64746Z"
              fill="#00BFA5"
            />
          </svg>
          <span>Not Exposed</span>
        </div>
      );
    default:
  }
};

export const protectionStatusText = (age) => {
  // from miliseconds to minutes
  let newAge = Math.round(age / 1000 / 60);
  let units = 'm';

  if (Math.abs(newAge) < 1) {
    newAge < 0 ? (newAge = -1) : (newAge = 1);
  }

  if (Math.abs(newAge) >= 60) {
    newAge = Math.round(newAge / 60);
    units = 'h';
  }

  if (Math.abs(newAge) >= 24) {
    newAge = Math.round(newAge / 24);
    units = 'd';
  }

  let pastOrFuture = 'left';
  if (newAge < 0) {
    pastOrFuture = 'ago';
    newAge *= -1;
  }

  return `${newAge}${units} ${pastOrFuture}`;
};

export const exposureAgeText = (age) => {
  // from miliseconds to minutes
  let newAge = Math.round(age / 1000 / 60);
  let units = 'm';

  if (newAge < 1) {
    newAge = 1;
  }

  if (Math.abs(newAge) >= 60) {
    newAge = Math.round(newAge / 60);
    units = 'h';
  }

  if (Math.abs(newAge) >= 24) {
    newAge = Math.round(newAge / 24);
    units = 'd';
  }

  return `${newAge}${units} ago`;
};

// Convert the evidenceType data into some pretty looking words
export const textFromEvidenceType = (et) => {
  switch (et) {
    case 'VACCINE_CARD':
      return 'Vaccination Card';
    case 'PCR_TEST_RESULT':
      return 'Swab Test';
    case 'ANTI_BODY_SEROLOGY_TEST_RESULT':
      return 'Antibody Test';
    case 'MONOCLONAL_INFUSION_REPORT':
      return 'Monoclonal Infusion';
    case 'RELIGIOUS_EXEMPTION':
      return 'Religious Exemption';
    case 'ADMINISTRATIVE_EXEMPTION':
      return 'Administrative Exemption';
    case 'MEDICAL_EXEMPTION':
      return 'Medical Exemption';
    case 'EMPLOYER_EXEMPTION':
      return 'Employer Exemption';
    default:
      return '';
  }
};

// From epoch format to 'YYYY-mm-dd HH:mm:ss'
export const formatEpochToPrettyDate = (et) => {
  if (et) {
    const myDate = new Date(et);
    return `${myDate.getFullYear()}-${('0' + (myDate.getMonth() + 1)).slice(-2)}-${(
      '0' + myDate.getDate()
    ).slice(-2)}\u00A0\u00A0${('0' + myDate.getHours()).slice(-2)}:${(
      '0' + myDate.getMinutes()
    ).slice(-2)}:${('0' + myDate.getSeconds()).slice(-2)}`;
  } else {
    // Looping through the data, maybe this field is null (data from api call).
    // In that case show an empty string on the screen
    return '';
  }
};

// Show the words 'Valid', 'Missing', 'Expired', or '' depending on the data I get from API call
export const protectionStatusBadgeText = (status) => {
  switch (status) {
    case 'EXPIRED':
      return 'Expired';
    case 'VALID':
      return 'Valid';
    case 'MISSING':
      return 'Missing';
    default:
  }
};

// Add some formatting text to a string literal (in a className=' ... ')
// depending on the data I get from API call
export const protectionStatusBadgeClass = (status) => {
  switch (status) {
    case 'EXPIRED':
      return 'bg-red-300 bg-opacity-60 text-red-600';
    case 'VALID':
      return 'bg-primary-light bg-opacity-20 text-primary-light';
    case 'MISSING':
      return 'bg-gray-100 bg-opacity-80 text-gray-500';
    default:
  }
};
