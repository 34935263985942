import {
  Facilities,
  Users as ClientUsers,
  Dashboard as ClientDashboard,
  Powerbi as MultiClientDashboard,
  Sites as ClientSites,
  Vehicules as ClientVehicules,
  Ships as ClientShips,
  Journeys as ClientJourneys,
  Settings as ClientSettings,
} from 'src/views/app/pages/dashboard/pages';
import {
  Dashboard as FacilityDashboard,
  Facility,
  Billing,
  Users as FacilityUsers,
  Assessment as FacilityAssessment,
  EmployeeStatus as FacilityEmployeeStatus,
} from 'src/views/app/pages/facility/pages';
import { Users as SiteUsers } from 'src/views/app/pages/site/pages';
import { Journeys as ShipJourneys, Users as ShipUsers } from 'src/views/app/pages/ship/pages';

import VERTICALS from './verticals.json';

export default Object.freeze(
  {
    [VERTICALS.FTG]: {
      dashboard: [
        {
          enable: true,
          path: '/',
          component: Facilities,
          header: {
            to: '/dashboard/',
            value: 'header.tabs.facility',
          },
        },
        {
          enable: false,
          path: 'users',
          component: ClientUsers,
          header: {
            to: '/dashboard/users',
            value: 'header.tabs.users',
          },
        },
        {
          enable: true,
          path: '/settings',
          component: ClientSettings,
          header: {
            to: '/dashboard/settings',
            value: 'header.tabs.settings',
          },
        },
      ],
      facility: [
        {
          enable: true,
          path: '/',
          component: Facility,
          sidebar: {
            to: '',
            value: 'layout.facility',
            iconPaths: [
              'M26.2905 23.7853V3.44842L10.1389 0V23.7853H8.98947V6.04211L1.70947 7.86947V23.7853H0V25.4063H28V23.7853H26.2905ZM4.12632 10.5221H6.66105V13.0568H4.12632V10.5221ZM4.12632 14.9137H6.66105V17.4484H4.12632V14.9137ZM4.12632 19.2758H6.66105V21.84H4.12632V19.2758ZM21.3389 6.10105H23.8737V8.63579H21.3389V6.10105ZM21.3389 10.4926H23.8737V13.0274H21.3389V10.4926ZM21.3389 14.8842H23.8737V17.4189H21.3389V14.8842ZM21.3389 19.2758H23.8737V21.8105H21.3389V19.2758ZM16.9474 6.10105H19.4821V8.63579H16.9474V6.10105ZM16.9474 10.4926H19.4821V13.0274H16.9474V10.4926ZM16.9474 14.8842H19.4821V17.4189H16.9474V14.8842ZM12.5558 6.10105H15.0905V8.63579H12.5558V6.10105ZM12.5558 10.4926H15.0905V13.0274H12.5558V10.4926ZM12.5558 14.8842H15.0905V17.4189H12.5558V14.8842ZM12.5558 19.2758H15.0905V21.8105H12.5558V19.2758ZM16.9474 19.2758H19.4821V23.7853H16.9474V19.2758Z',
            ],
          },
        },
        {
          enable: true,
          path: 'dashboard',
          component: FacilityDashboard,
          sidebar: {
            to: '/dashboard',
            value: 'layout.dashboard',
            iconPaths: [
              'M14.5098 2.75001C11.8408 2.74695 9.22667 3.50775 6.97616 4.94256C4.72565 6.37737 2.93275 8.42625 1.8092 10.8472C0.685656 13.2682 0.278398 15.9601 0.635525 18.6051C0.992653 21.2501 2.09925 23.7376 3.82462 25.7739L4.07903 26.0708H24.9405L25.1949 25.7739C26.9203 23.7376 28.0269 21.2501 28.384 18.6051C28.7411 15.9601 28.3339 13.2682 27.2103 10.8472C26.0868 8.42625 24.2939 6.37737 22.0434 4.94256C19.7929 3.50775 17.1787 2.74695 14.5098 2.75001ZM21.8028 10.7893L16.7825 15.7163C17.0391 16.2588 17.0957 16.8745 16.9422 17.4547C16.7888 18.0348 16.4352 18.5421 15.944 18.8867C15.4527 19.2314 14.8555 19.3914 14.2577 19.3383C13.66 19.2852 13.1002 19.0226 12.6774 18.5967C12.2546 18.1709 11.9959 17.6093 11.9471 17.0112C11.8982 16.4131 12.0624 15.817 12.4105 15.3282C12.7587 14.8394 13.2684 14.4895 13.8496 14.3401C14.4308 14.1908 15.0461 14.2517 15.5868 14.5121L20.5986 9.58511L21.8028 10.7893ZM2.29817 16.0216H5.18146V17.7177H2.26425C2.26425 17.387 2.22185 17.0647 2.22185 16.7255C2.22185 16.3863 2.23881 16.2591 2.25577 16.0216H2.29817ZM6.43654 7.54136L8.52269 9.62751L7.27609 10.7978L5.18146 8.72012C5.55741 8.29121 5.96261 7.88885 6.39414 7.51592L6.43654 7.54136ZM15.3578 7.44808H13.6617V4.47998H14.5098C14.8235 4.47998 15.1034 4.47998 15.3578 4.52239V7.44808ZM26.7977 16.734C26.7977 17.0647 26.7977 17.4039 26.7553 17.7262H23.7702V16.0301H26.7638C26.7807 16.2591 26.7977 16.4965 26.7977 16.734Z',
            ],
          },
        },
        {
          enable: false,
          path: 'assessment',
          component: FacilityAssessment,
          sidebar: {
            to: '/assessment',
            value: 'layout.assessment',
            iconPaths: [
              'M25.5392 8.56272H19.797V4.46106C19.797 3.10424 18.693 2 17.3359 2H2.46106C1.10424 2 0 3.10395 0 4.46106V14.3047C0 15.6615 1.10395 16.7655 2.46106 16.7655H3.28151V19.2265C3.28151 19.8834 4.01533 20.2701 4.55666 19.909L8.20333 17.478V20.8671C8.20333 22.224 9.30728 23.3282 10.6644 23.3282H18.7283L23.4433 26.4717C23.9855 26.8334 24.7185 26.4464 24.7185 25.7893V23.3282H25.5389C26.8958 23.3282 28 22.2243 28 20.8671V11.0235C28 9.66667 26.8961 8.56243 25.5392 8.56243V8.56272ZM8.56645 15.2646C8.56558 15.2652 8.56499 15.2657 8.56412 15.266L4.92211 17.6938V15.9453C4.92211 15.4924 4.55491 15.1249 4.10167 15.1249H2.46106C2.00869 15.1249 1.64061 14.7568 1.64061 14.3044V4.46077C1.64061 4.0084 2.00869 3.64032 2.46106 3.64032H17.3359C17.7883 3.64032 18.1564 4.0084 18.1564 4.46077V14.3044C18.1564 14.7568 17.7883 15.1249 17.3359 15.1249H9.02349C8.86482 15.1249 8.70062 15.1739 8.56616 15.2643L8.56645 15.2646Z',
            ],
          },
        },
        {
          enable: false,
          path: 'users',
          component: FacilityUsers,
          sidebar: {
            to: '/users',
            value: 'layout.users',
            iconPaths: [
              'M13.9999 12.5033C16.0719 12.5033 17.7515 10.8236 17.7515 8.75163C17.7515 6.67966 16.0719 5 13.9999 5C11.928 5 10.2483 6.67966 10.2483 8.75163C10.2483 10.8236 11.928 12.5033 13.9999 12.5033Z',
              'M22.4247 12.4046C23.9696 12.4046 25.222 11.1522 25.222 9.60732C25.222 8.06244 23.9696 6.81006 22.4247 6.81006C20.8798 6.81006 19.6274 8.06244 19.6274 9.60732C19.6274 11.1522 20.8798 12.4046 22.4247 12.4046Z',
              'M27.9862 19.0193C27.4268 15.498 25.5839 13.3918 22.4246 13.3918C21.207 13.3918 20.2197 13.688 19.397 14.2804C19.4957 14.3791 19.5944 14.4778 19.7261 14.5766C21.0753 15.9917 21.9968 18.032 22.4246 20.6318C22.4246 20.7306 22.4575 20.8293 22.4575 20.8951C25.518 20.928 28.2166 20.4015 27.9862 19.0193Z',
              'M5.57522 12.4046C7.12011 12.4046 8.37249 11.1522 8.37249 9.60732C8.37249 8.06244 7.12011 6.81006 5.57522 6.81006C4.03033 6.81006 2.77795 8.06244 2.77795 9.60732C2.77795 11.1522 4.03033 12.4046 5.57522 12.4046Z',
              'M8.30667 14.5766C8.40539 14.4778 8.50412 14.3791 8.60285 14.2804C7.78012 13.688 6.75994 13.3918 5.57522 13.3918C2.44886 13.3918 0.605959 15.4651 0.0135968 19.0193C-0.216766 20.4015 2.51468 20.928 5.57522 20.928C5.57522 20.8293 5.57522 20.7306 5.60813 20.6647C6.00304 18.0649 6.92449 16.0246 8.30667 14.5766Z',
              'M14 23.3632C9.88633 23.3632 6.26634 22.6392 6.56252 20.8292C7.35234 16.0903 9.82051 13.3259 14 13.3259C18.1794 13.3259 20.6476 16.1232 21.4374 20.8292C21.7336 22.6392 18.1136 23.3632 14 23.3632Z',
            ],
          },
        },
        {
          enable: true,
          path: 'billing',
          component: Billing,
          sidebar: {
            to: '/billing',
            value: 'layout.billing',
            iconPaths: [
              'M4 4C2.93913 4 1.92172 4.42143 1.17157 5.17157C0.421427 5.92172 0 6.93913 0 8V10H28V8C28 6.93913 27.5786 5.92172 26.8284 5.17157C26.0783 4.42143 25.0609 4 24 4H4Z',
              'M0 20V12H28V20C28 21.0609 27.5786 22.0783 26.8284 22.8284C26.0783 23.5786 25.0609 24 24 24H4C2.93913 24 1.92172 23.5786 1.17157 22.8284C0.421427 22.0783 0 21.0609 0 20ZM19 18C18.7348 18 18.4804 18.1054 18.2929 18.2929C18.1054 18.4804 18 18.7348 18 19C18 19.2652 18.1054 19.5196 18.2929 19.7071C18.4804 19.8946 18.7348 20 19 20H23C23.2652 20 23.5196 19.8946 23.7071 19.7071C23.8946 19.5196 24 19.2652 24 19C24 18.7348 23.8946 18.4804 23.7071 18.2929C23.5196 18.1054 23.2652 18 23 18H19Z',
            ],
          },
        },
      ],
    },
    [VERTICALS.VENUE]: {
      dashboard: [],
      facility: [],
    },
    [VERTICALS.VACCINE]: {
      dashboard: [
        {
          enable: true,
          roles: ['ADMIN'],
          path: '/',
          component: ClientDashboard,
          header: {
            to: '/dashboard/',
            value: 'header.tabs.dashboard',
          },
        },
        {
          enable: true,
          path: 'sites',
          component: ClientSites,
          header: {
            to: '/dashboard/sites',
            value: 'header.tabs.sites',
          },
        },
        {
          enable: true,
          path: 'users',
          component: ClientUsers,
          header: {
            to: '/dashboard/users',
            value: 'header.tabs.users',
          },
        },
        {
          enable: true,
          path: '/settings',
          component: ClientSettings,
          header: {
            to: '/dashboard/settings',
            value: 'header.tabs.settings',
          },
        },
      ],
      site: [
        {
          enable: true,
          path: '/',
          component: SiteUsers,
          sidebar: {
            to: '',
            value: 'layout.users',
            iconPaths: [
              'M13.9999 12.5033C16.0719 12.5033 17.7515 10.8236 17.7515 8.75163C17.7515 6.67966 16.0719 5 13.9999 5C11.928 5 10.2483 6.67966 10.2483 8.75163C10.2483 10.8236 11.928 12.5033 13.9999 12.5033Z',
              'M22.4247 12.4046C23.9696 12.4046 25.222 11.1522 25.222 9.60732C25.222 8.06244 23.9696 6.81006 22.4247 6.81006C20.8798 6.81006 19.6274 8.06244 19.6274 9.60732C19.6274 11.1522 20.8798 12.4046 22.4247 12.4046Z',
              'M27.9862 19.0193C27.4268 15.498 25.5839 13.3918 22.4246 13.3918C21.207 13.3918 20.2197 13.688 19.397 14.2804C19.4957 14.3791 19.5944 14.4778 19.7261 14.5766C21.0753 15.9917 21.9968 18.032 22.4246 20.6318C22.4246 20.7306 22.4575 20.8293 22.4575 20.8951C25.518 20.928 28.2166 20.4015 27.9862 19.0193Z',
              'M5.57522 12.4046C7.12011 12.4046 8.37249 11.1522 8.37249 9.60732C8.37249 8.06244 7.12011 6.81006 5.57522 6.81006C4.03033 6.81006 2.77795 8.06244 2.77795 9.60732C2.77795 11.1522 4.03033 12.4046 5.57522 12.4046Z',
              'M8.30667 14.5766C8.40539 14.4778 8.50412 14.3791 8.60285 14.2804C7.78012 13.688 6.75994 13.3918 5.57522 13.3918C2.44886 13.3918 0.605959 15.4651 0.0135968 19.0193C-0.216766 20.4015 2.51468 20.928 5.57522 20.928C5.57522 20.8293 5.57522 20.7306 5.60813 20.6647C6.00304 18.0649 6.92449 16.0246 8.30667 14.5766Z',
              'M14 23.3632C9.88633 23.3632 6.26634 22.6392 6.56252 20.8292C7.35234 16.0903 9.82051 13.3259 14 13.3259C18.1794 13.3259 20.6476 16.1232 21.4374 20.8292C21.7336 22.6392 18.1136 23.3632 14 23.3632Z',
            ],
          },
        },
      ],
    },
    [VERTICALS.HOSPITALITY]: {
      dashboard: [
        {
          enable: true,
          path: '/',
          component: Facilities,
          header: {
            to: '/dashboard/',
            value: 'header.tabs.facility',
          },
        },
        {
          enable: false,
          path: 'users',
          component: ClientUsers,
          header: {
            to: '/dashboard/users',
            value: 'header.tabs.users',
          },
        },
        {
          enable: true,
          path: '/settings',
          component: ClientSettings,
          header: {
            to: '/dashboard/settings',
            value: 'header.tabs.settings',
          },
        },
      ],
      facility: [
        {
          enable: true,
          path: '/',
          component: Facility,
          sidebar: {
            to: '',
            value: 'layout.facility',
            iconPaths: [
              'M26.2905 23.7853V3.44842L10.1389 0V23.7853H8.98947V6.04211L1.70947 7.86947V23.7853H0V25.4063H28V23.7853H26.2905ZM4.12632 10.5221H6.66105V13.0568H4.12632V10.5221ZM4.12632 14.9137H6.66105V17.4484H4.12632V14.9137ZM4.12632 19.2758H6.66105V21.84H4.12632V19.2758ZM21.3389 6.10105H23.8737V8.63579H21.3389V6.10105ZM21.3389 10.4926H23.8737V13.0274H21.3389V10.4926ZM21.3389 14.8842H23.8737V17.4189H21.3389V14.8842ZM21.3389 19.2758H23.8737V21.8105H21.3389V19.2758ZM16.9474 6.10105H19.4821V8.63579H16.9474V6.10105ZM16.9474 10.4926H19.4821V13.0274H16.9474V10.4926ZM16.9474 14.8842H19.4821V17.4189H16.9474V14.8842ZM12.5558 6.10105H15.0905V8.63579H12.5558V6.10105ZM12.5558 10.4926H15.0905V13.0274H12.5558V10.4926ZM12.5558 14.8842H15.0905V17.4189H12.5558V14.8842ZM12.5558 19.2758H15.0905V21.8105H12.5558V19.2758ZM16.9474 19.2758H19.4821V23.7853H16.9474V19.2758Z',
            ],
          },
        },
        {
          enable: false,
          path: 'users',
          component: FacilityUsers,
          sidebar: {
            to: '/users',
            value: 'layout.users',
            iconPaths: [
              'M13.9999 12.5033C16.0719 12.5033 17.7515 10.8236 17.7515 8.75163C17.7515 6.67966 16.0719 5 13.9999 5C11.928 5 10.2483 6.67966 10.2483 8.75163C10.2483 10.8236 11.928 12.5033 13.9999 12.5033Z',
              'M22.4247 12.4046C23.9696 12.4046 25.222 11.1522 25.222 9.60732C25.222 8.06244 23.9696 6.81006 22.4247 6.81006C20.8798 6.81006 19.6274 8.06244 19.6274 9.60732C19.6274 11.1522 20.8798 12.4046 22.4247 12.4046Z',
              'M27.9862 19.0193C27.4268 15.498 25.5839 13.3918 22.4246 13.3918C21.207 13.3918 20.2197 13.688 19.397 14.2804C19.4957 14.3791 19.5944 14.4778 19.7261 14.5766C21.0753 15.9917 21.9968 18.032 22.4246 20.6318C22.4246 20.7306 22.4575 20.8293 22.4575 20.8951C25.518 20.928 28.2166 20.4015 27.9862 19.0193Z',
              'M5.57522 12.4046C7.12011 12.4046 8.37249 11.1522 8.37249 9.60732C8.37249 8.06244 7.12011 6.81006 5.57522 6.81006C4.03033 6.81006 2.77795 8.06244 2.77795 9.60732C2.77795 11.1522 4.03033 12.4046 5.57522 12.4046Z',
              'M8.30667 14.5766C8.40539 14.4778 8.50412 14.3791 8.60285 14.2804C7.78012 13.688 6.75994 13.3918 5.57522 13.3918C2.44886 13.3918 0.605959 15.4651 0.0135968 19.0193C-0.216766 20.4015 2.51468 20.928 5.57522 20.928C5.57522 20.8293 5.57522 20.7306 5.60813 20.6647C6.00304 18.0649 6.92449 16.0246 8.30667 14.5766Z',
              'M14 23.3632C9.88633 23.3632 6.26634 22.6392 6.56252 20.8292C7.35234 16.0903 9.82051 13.3259 14 13.3259C18.1794 13.3259 20.6476 16.1232 21.4374 20.8292C21.7336 22.6392 18.1136 23.3632 14 23.3632Z',
            ],
          },
        },
        {
          enable: true,
          path: 'dashboard',
          component: FacilityDashboard,
          sidebar: {
            to: '/dashboard',
            value: 'layout.dashboard',
            iconPaths: [
              'M14.5098 2.75001C11.8408 2.74695 9.22667 3.50775 6.97616 4.94256C4.72565 6.37737 2.93275 8.42625 1.8092 10.8472C0.685656 13.2682 0.278398 15.9601 0.635525 18.6051C0.992653 21.2501 2.09925 23.7376 3.82462 25.7739L4.07903 26.0708H24.9405L25.1949 25.7739C26.9203 23.7376 28.0269 21.2501 28.384 18.6051C28.7411 15.9601 28.3339 13.2682 27.2103 10.8472C26.0868 8.42625 24.2939 6.37737 22.0434 4.94256C19.7929 3.50775 17.1787 2.74695 14.5098 2.75001ZM21.8028 10.7893L16.7825 15.7163C17.0391 16.2588 17.0957 16.8745 16.9422 17.4547C16.7888 18.0348 16.4352 18.5421 15.944 18.8867C15.4527 19.2314 14.8555 19.3914 14.2577 19.3383C13.66 19.2852 13.1002 19.0226 12.6774 18.5967C12.2546 18.1709 11.9959 17.6093 11.9471 17.0112C11.8982 16.4131 12.0624 15.817 12.4105 15.3282C12.7587 14.8394 13.2684 14.4895 13.8496 14.3401C14.4308 14.1908 15.0461 14.2517 15.5868 14.5121L20.5986 9.58511L21.8028 10.7893ZM2.29817 16.0216H5.18146V17.7177H2.26425C2.26425 17.387 2.22185 17.0647 2.22185 16.7255C2.22185 16.3863 2.23881 16.2591 2.25577 16.0216H2.29817ZM6.43654 7.54136L8.52269 9.62751L7.27609 10.7978L5.18146 8.72012C5.55741 8.29121 5.96261 7.88885 6.39414 7.51592L6.43654 7.54136ZM15.3578 7.44808H13.6617V4.47998H14.5098C14.8235 4.47998 15.1034 4.47998 15.3578 4.52239V7.44808ZM26.7977 16.734C26.7977 17.0647 26.7977 17.4039 26.7553 17.7262H23.7702V16.0301H26.7638C26.7807 16.2591 26.7977 16.4965 26.7977 16.734Z',
            ],
          },
        },
      ],
    },
    [VERTICALS.CARS]: {
      dashboard: [
        {
          enable: true,
          path: 'vehicules',
          component: ClientVehicules,
          header: {
            to: '/dashboard/vehicules',
            value: 'header.tabs.sites',
          },
        },
      ],
    },
    [VERTICALS.SCHOOLS]: {
      dashboard: [],
      facility: [],
    },
    [VERTICALS.CRUISES]: {
      dashboard: [
        {
          enable: true,
          path: '/',
          component: ClientDashboard,
          header: {
            to: '/dashboard/',
            value: 'header.tabs.dashboard',
          },
        },
        {
          enable: true,
          path: 'ships',
          component: ClientShips,
          header: {
            to: '/dashboard/ships',
            value: 'header.tabs.ships',
          },
        },
        {
          enable: true,
          path: 'journeys',
          component: ClientJourneys,
          header: {
            to: '/dashboard/journeys',
            value: 'header.tabs.journeys',
          },
        },
      ],
      ship: [
        {
          enable: true,
          path: '/',
          component: ShipJourneys,
          sidebar: {
            to: '',
            value: 'layout.journeys',
            iconPaths: [
              'M0 4.75C0 3.78351 0.78351 3 1.75 3H3.5C4.46649 3 5.25 3.78351 5.25 4.75C5.25 5.71649 4.46649 6.5 3.5 6.5H1.75C0.78351 6.5 0 5.71649 0 4.75ZM8.75 4.75C8.75 3.78351 9.53351 3 10.5 3H26.25C27.2165 3 28 3.78351 28 4.75C28 5.71649 27.2165 6.5 26.25 6.5H10.5C9.53351 6.5 8.75 5.71649 8.75 4.75ZM0 13.5C0 12.5335 0.78351 11.75 1.75 11.75H3.5C4.46649 11.75 5.25 12.5335 5.25 13.5C5.25 14.4665 4.46649 15.25 3.5 15.25H1.75C0.78351 15.25 0 14.4665 0 13.5ZM8.75 13.5C8.75 12.5335 9.53351 11.75 10.5 11.75H26.25C27.2165 11.75 28 12.5335 28 13.5C28 14.4665 27.2165 15.25 26.25 15.25H10.5C9.53351 15.25 8.75 14.4665 8.75 13.5ZM0 22.25C0 21.2835 0.78351 20.5 1.75 20.5H3.5C4.46649 20.5 5.25 21.2835 5.25 22.25C5.25 23.2165 4.46649 24 3.5 24H1.75C0.78351 24 0 23.2165 0 22.25ZM8.75 22.25C8.75 21.2835 9.53351 20.5 10.5 20.5H26.25C27.2165 20.5 28 21.2835 28 22.25C28 23.2165 27.2165 24 26.25 24H10.5C9.53351 24 8.75 23.2165 8.75 22.25Z',
            ],
          },
        },
        {
          enable: true,
          path: '/users',
          component: ShipUsers,
          sidebar: {
            to: '/users',
            value: 'layout.crew',
            iconPaths: [
              'M13.9999 12.5033C16.0719 12.5033 17.7515 10.8236 17.7515 8.75163C17.7515 6.67966 16.0719 5 13.9999 5C11.928 5 10.2483 6.67966 10.2483 8.75163C10.2483 10.8236 11.928 12.5033 13.9999 12.5033Z',
              'M22.4247 12.4046C23.9696 12.4046 25.222 11.1522 25.222 9.60732C25.222 8.06244 23.9696 6.81006 22.4247 6.81006C20.8798 6.81006 19.6274 8.06244 19.6274 9.60732C19.6274 11.1522 20.8798 12.4046 22.4247 12.4046Z',
              'M27.9862 19.0193C27.4268 15.498 25.5839 13.3918 22.4246 13.3918C21.207 13.3918 20.2197 13.688 19.397 14.2804C19.4957 14.3791 19.5944 14.4778 19.7261 14.5766C21.0753 15.9917 21.9968 18.032 22.4246 20.6318C22.4246 20.7306 22.4575 20.8293 22.4575 20.8951C25.518 20.928 28.2166 20.4015 27.9862 19.0193Z',
              'M5.57522 12.4046C7.12011 12.4046 8.37249 11.1522 8.37249 9.60732C8.37249 8.06244 7.12011 6.81006 5.57522 6.81006C4.03033 6.81006 2.77795 8.06244 2.77795 9.60732C2.77795 11.1522 4.03033 12.4046 5.57522 12.4046Z',
              'M8.30667 14.5766C8.40539 14.4778 8.50412 14.3791 8.60285 14.2804C7.78012 13.688 6.75994 13.3918 5.57522 13.3918C2.44886 13.3918 0.605959 15.4651 0.0135968 19.0193C-0.216766 20.4015 2.51468 20.928 5.57522 20.928C5.57522 20.8293 5.57522 20.7306 5.60813 20.6647C6.00304 18.0649 6.92449 16.0246 8.30667 14.5766Z',
              'M14 23.3632C9.88633 23.3632 6.26634 22.6392 6.56252 20.8292C7.35234 16.0903 9.82051 13.3259 14 13.3259C18.1794 13.3259 20.6476 16.1232 21.4374 20.8292C21.7336 22.6392 18.1136 23.3632 14 23.3632Z',
            ],
          },
        },
      ],
    },
    [VERTICALS.VULCAIN]: {
      dashboard: [],
    },
    [VERTICALS.DEKRA]: {
      dashboard: [
        {
          enable: true,
          path: '/',
          component: Facilities,
          header: {
            to: '/dashboard/',
            value: 'header.tabs.facility',
          },
        },
        {
          enable: false,
          path: 'users',
          component: ClientUsers,
          header: {
            to: '/dashboard/users',
            value: 'header.tabs.users',
          },
        },
        {
          enable: true,
          path: '/settings',
          component: ClientSettings,
          header: {
            to: '/dashboard/settings',
            value: 'header.tabs.settings',
          },
        },
      ],
      facility: [
        {
          enable: true,
          path: '/',
          component: Facility,
          sidebar: {
            to: '',
            value: 'layout.facility',
            iconPaths: [
              'M26.2905 23.7853V3.44842L10.1389 0V23.7853H8.98947V6.04211L1.70947 7.86947V23.7853H0V25.4063H28V23.7853H26.2905ZM4.12632 10.5221H6.66105V13.0568H4.12632V10.5221ZM4.12632 14.9137H6.66105V17.4484H4.12632V14.9137ZM4.12632 19.2758H6.66105V21.84H4.12632V19.2758ZM21.3389 6.10105H23.8737V8.63579H21.3389V6.10105ZM21.3389 10.4926H23.8737V13.0274H21.3389V10.4926ZM21.3389 14.8842H23.8737V17.4189H21.3389V14.8842ZM21.3389 19.2758H23.8737V21.8105H21.3389V19.2758ZM16.9474 6.10105H19.4821V8.63579H16.9474V6.10105ZM16.9474 10.4926H19.4821V13.0274H16.9474V10.4926ZM16.9474 14.8842H19.4821V17.4189H16.9474V14.8842ZM12.5558 6.10105H15.0905V8.63579H12.5558V6.10105ZM12.5558 10.4926H15.0905V13.0274H12.5558V10.4926ZM12.5558 14.8842H15.0905V17.4189H12.5558V14.8842ZM12.5558 19.2758H15.0905V21.8105H12.5558V19.2758ZM16.9474 19.2758H19.4821V23.7853H16.9474V19.2758Z',
            ],
          },
        },
        {
          enable: true,
          path: 'users',
          component: FacilityUsers,
          sidebar: {
            to: '/users',
            value: 'layout.users',
            iconPaths: [
              'M13.9999 12.5033C16.0719 12.5033 17.7515 10.8236 17.7515 8.75163C17.7515 6.67966 16.0719 5 13.9999 5C11.928 5 10.2483 6.67966 10.2483 8.75163C10.2483 10.8236 11.928 12.5033 13.9999 12.5033Z',
              'M22.4247 12.4046C23.9696 12.4046 25.222 11.1522 25.222 9.60732C25.222 8.06244 23.9696 6.81006 22.4247 6.81006C20.8798 6.81006 19.6274 8.06244 19.6274 9.60732C19.6274 11.1522 20.8798 12.4046 22.4247 12.4046Z',
              'M27.9862 19.0193C27.4268 15.498 25.5839 13.3918 22.4246 13.3918C21.207 13.3918 20.2197 13.688 19.397 14.2804C19.4957 14.3791 19.5944 14.4778 19.7261 14.5766C21.0753 15.9917 21.9968 18.032 22.4246 20.6318C22.4246 20.7306 22.4575 20.8293 22.4575 20.8951C25.518 20.928 28.2166 20.4015 27.9862 19.0193Z',
              'M5.57522 12.4046C7.12011 12.4046 8.37249 11.1522 8.37249 9.60732C8.37249 8.06244 7.12011 6.81006 5.57522 6.81006C4.03033 6.81006 2.77795 8.06244 2.77795 9.60732C2.77795 11.1522 4.03033 12.4046 5.57522 12.4046Z',
              'M8.30667 14.5766C8.40539 14.4778 8.50412 14.3791 8.60285 14.2804C7.78012 13.688 6.75994 13.3918 5.57522 13.3918C2.44886 13.3918 0.605959 15.4651 0.0135968 19.0193C-0.216766 20.4015 2.51468 20.928 5.57522 20.928C5.57522 20.8293 5.57522 20.7306 5.60813 20.6647C6.00304 18.0649 6.92449 16.0246 8.30667 14.5766Z',
              'M14 23.3632C9.88633 23.3632 6.26634 22.6392 6.56252 20.8292C7.35234 16.0903 9.82051 13.3259 14 13.3259C18.1794 13.3259 20.6476 16.1232 21.4374 20.8292C21.7336 22.6392 18.1136 23.3632 14 23.3632Z',
            ],
          },
        },
        {
          enable: true,
          path: 'dashboard',
          component: FacilityDashboard,
          sidebar: {
            to: '/dashboard',
            value: 'layout.dashboard',
            iconPaths: [
              'M14.5098 2.75001C11.8408 2.74695 9.22667 3.50775 6.97616 4.94256C4.72565 6.37737 2.93275 8.42625 1.8092 10.8472C0.685656 13.2682 0.278398 15.9601 0.635525 18.6051C0.992653 21.2501 2.09925 23.7376 3.82462 25.7739L4.07903 26.0708H24.9405L25.1949 25.7739C26.9203 23.7376 28.0269 21.2501 28.384 18.6051C28.7411 15.9601 28.3339 13.2682 27.2103 10.8472C26.0868 8.42625 24.2939 6.37737 22.0434 4.94256C19.7929 3.50775 17.1787 2.74695 14.5098 2.75001ZM21.8028 10.7893L16.7825 15.7163C17.0391 16.2588 17.0957 16.8745 16.9422 17.4547C16.7888 18.0348 16.4352 18.5421 15.944 18.8867C15.4527 19.2314 14.8555 19.3914 14.2577 19.3383C13.66 19.2852 13.1002 19.0226 12.6774 18.5967C12.2546 18.1709 11.9959 17.6093 11.9471 17.0112C11.8982 16.4131 12.0624 15.817 12.4105 15.3282C12.7587 14.8394 13.2684 14.4895 13.8496 14.3401C14.4308 14.1908 15.0461 14.2517 15.5868 14.5121L20.5986 9.58511L21.8028 10.7893ZM2.29817 16.0216H5.18146V17.7177H2.26425C2.26425 17.387 2.22185 17.0647 2.22185 16.7255C2.22185 16.3863 2.23881 16.2591 2.25577 16.0216H2.29817ZM6.43654 7.54136L8.52269 9.62751L7.27609 10.7978L5.18146 8.72012C5.55741 8.29121 5.96261 7.88885 6.39414 7.51592L6.43654 7.54136ZM15.3578 7.44808H13.6617V4.47998H14.5098C14.8235 4.47998 15.1034 4.47998 15.3578 4.52239V7.44808ZM26.7977 16.734C26.7977 17.0647 26.7977 17.4039 26.7553 17.7262H23.7702V16.0301H26.7638C26.7807 16.2591 26.7977 16.4965 26.7977 16.734Z',
            ],
          },
        },
      ],
    },
    [VERTICALS.GREENSHIELD]: {
      dashboard: [
        {
          enable: true,
          path: '/',
          component: Facilities,
          header: {
            to: '/dashboard/',
            value: 'header.tabs.facility',
          },
        },
        {
          enable: true,
          path: '/metrics',
          component: MultiClientDashboard,
          header: {
            to: '/dashboard/metrics',
            value: 'header.tabs.dashboard',
          },
        },
        {
          enable: true,
          path: '/settings',
          component: ClientSettings,
          header: {
            to: '/dashboard/settings',
            value: 'header.tabs.settings',
          },
        },
        {
          enable: false,
          path: 'users',
          component: ClientUsers,
          header: {
            to: '/dashboard/users',
            value: 'header.tabs.users',
          },
        },
      ],
      facility: [
        {
          enable: true,
          path: '/',
          component: Facility,
          sidebar: {
            to: '',
            value: 'layout.facility',
            iconPaths: [
              'M26.2905 23.7853V3.44842L10.1389 0V23.7853H8.98947V6.04211L1.70947 7.86947V23.7853H0V25.4063H28V23.7853H26.2905ZM4.12632 10.5221H6.66105V13.0568H4.12632V10.5221ZM4.12632 14.9137H6.66105V17.4484H4.12632V14.9137ZM4.12632 19.2758H6.66105V21.84H4.12632V19.2758ZM21.3389 6.10105H23.8737V8.63579H21.3389V6.10105ZM21.3389 10.4926H23.8737V13.0274H21.3389V10.4926ZM21.3389 14.8842H23.8737V17.4189H21.3389V14.8842ZM21.3389 19.2758H23.8737V21.8105H21.3389V19.2758ZM16.9474 6.10105H19.4821V8.63579H16.9474V6.10105ZM16.9474 10.4926H19.4821V13.0274H16.9474V10.4926ZM16.9474 14.8842H19.4821V17.4189H16.9474V14.8842ZM12.5558 6.10105H15.0905V8.63579H12.5558V6.10105ZM12.5558 10.4926H15.0905V13.0274H12.5558V10.4926ZM12.5558 14.8842H15.0905V17.4189H12.5558V14.8842ZM12.5558 19.2758H15.0905V21.8105H12.5558V19.2758ZM16.9474 19.2758H19.4821V23.7853H16.9474V19.2758Z',
            ],
          },
        },
        {
          enable: true,
          path: 'employeestatus',
          component: FacilityEmployeeStatus,
          sidebar: {
            to: '/employeestatus',
            value: 'layout.employeestatus',
            iconPaths: [
              'M0 1.75C0 0.78351 0.78351 0 1.75 0H3.5C4.46649 0 5.25 0.78351 5.25 1.75C5.25 2.71649 4.46649 3.5 3.5 3.5H1.75C0.78351 3.5 0 2.71649 0 1.75ZM8.75 1.75C8.75 0.78351 9.53351 0 10.5 0H26.25C27.2165 0 28 0.78351 28 1.75C28 2.71649 27.2165 3.5 26.25 3.5H10.5C9.53351 3.5 8.75 2.71649 8.75 1.75ZM0 10.5C0 9.53347 0.78351 8.75 1.75 8.75H3.5C4.46649 8.75 5.25 9.53347 5.25 10.5C5.25 11.4665 4.46649 12.25 3.5 12.25H1.75C0.78351 12.25 0 11.4665 0 10.5ZM8.75 10.5C8.75 9.53347 9.53351 8.75 10.5 8.75H26.25C27.2165 8.75 28 9.53347 28 10.5C28 11.4665 27.2165 12.25 26.25 12.25H10.5C9.53351 12.25 8.75 11.4665 8.75 10.5ZM0 19.25C0 18.2835 0.78351 17.5 1.75 17.5H3.5C4.46649 17.5 5.25 18.2835 5.25 19.25C5.25 20.2165 4.46649 21 3.5 21H1.75C0.78351 21 0 20.2165 0 19.25ZM8.75 19.25C8.75 18.2835 9.53351 17.5 10.5 17.5H26.25C27.2165 17.5 28 18.2835 28 19.25C28 20.2165 27.2165 21 26.25 21H10.5C9.53351 21 8.75 20.2165 8.75 19.25Z',
            ],
          },
        },
        {
          enable: true,
          path: 'users',
          component: FacilityUsers,
          sidebar: {
            to: '/users',
            value: 'layout.users',
            iconPaths: [
              'M13.9999 12.5033C16.0719 12.5033 17.7515 10.8236 17.7515 8.75163C17.7515 6.67966 16.0719 5 13.9999 5C11.928 5 10.2483 6.67966 10.2483 8.75163C10.2483 10.8236 11.928 12.5033 13.9999 12.5033Z',
              'M22.4247 12.4046C23.9696 12.4046 25.222 11.1522 25.222 9.60732C25.222 8.06244 23.9696 6.81006 22.4247 6.81006C20.8798 6.81006 19.6274 8.06244 19.6274 9.60732C19.6274 11.1522 20.8798 12.4046 22.4247 12.4046Z',
              'M27.9862 19.0193C27.4268 15.498 25.5839 13.3918 22.4246 13.3918C21.207 13.3918 20.2197 13.688 19.397 14.2804C19.4957 14.3791 19.5944 14.4778 19.7261 14.5766C21.0753 15.9917 21.9968 18.032 22.4246 20.6318C22.4246 20.7306 22.4575 20.8293 22.4575 20.8951C25.518 20.928 28.2166 20.4015 27.9862 19.0193Z',
              'M5.57522 12.4046C7.12011 12.4046 8.37249 11.1522 8.37249 9.60732C8.37249 8.06244 7.12011 6.81006 5.57522 6.81006C4.03033 6.81006 2.77795 8.06244 2.77795 9.60732C2.77795 11.1522 4.03033 12.4046 5.57522 12.4046Z',
              'M8.30667 14.5766C8.40539 14.4778 8.50412 14.3791 8.60285 14.2804C7.78012 13.688 6.75994 13.3918 5.57522 13.3918C2.44886 13.3918 0.605959 15.4651 0.0135968 19.0193C-0.216766 20.4015 2.51468 20.928 5.57522 20.928C5.57522 20.8293 5.57522 20.7306 5.60813 20.6647C6.00304 18.0649 6.92449 16.0246 8.30667 14.5766Z',
              'M14 23.3632C9.88633 23.3632 6.26634 22.6392 6.56252 20.8292C7.35234 16.0903 9.82051 13.3259 14 13.3259C18.1794 13.3259 20.6476 16.1232 21.4374 20.8292C21.7336 22.6392 18.1136 23.3632 14 23.3632Z',
            ],
          },
        },
        {
          enable: false,
          path: 'dashboard',
          component: FacilityDashboard,
          sidebar: {
            to: '/dashboard',
            value: 'layout.dashboard',
            iconPaths: [
              'M14.5098 2.75001C11.8408 2.74695 9.22667 3.50775 6.97616 4.94256C4.72565 6.37737 2.93275 8.42625 1.8092 10.8472C0.685656 13.2682 0.278398 15.9601 0.635525 18.6051C0.992653 21.2501 2.09925 23.7376 3.82462 25.7739L4.07903 26.0708H24.9405L25.1949 25.7739C26.9203 23.7376 28.0269 21.2501 28.384 18.6051C28.7411 15.9601 28.3339 13.2682 27.2103 10.8472C26.0868 8.42625 24.2939 6.37737 22.0434 4.94256C19.7929 3.50775 17.1787 2.74695 14.5098 2.75001ZM21.8028 10.7893L16.7825 15.7163C17.0391 16.2588 17.0957 16.8745 16.9422 17.4547C16.7888 18.0348 16.4352 18.5421 15.944 18.8867C15.4527 19.2314 14.8555 19.3914 14.2577 19.3383C13.66 19.2852 13.1002 19.0226 12.6774 18.5967C12.2546 18.1709 11.9959 17.6093 11.9471 17.0112C11.8982 16.4131 12.0624 15.817 12.4105 15.3282C12.7587 14.8394 13.2684 14.4895 13.8496 14.3401C14.4308 14.1908 15.0461 14.2517 15.5868 14.5121L20.5986 9.58511L21.8028 10.7893ZM2.29817 16.0216H5.18146V17.7177H2.26425C2.26425 17.387 2.22185 17.0647 2.22185 16.7255C2.22185 16.3863 2.23881 16.2591 2.25577 16.0216H2.29817ZM6.43654 7.54136L8.52269 9.62751L7.27609 10.7978L5.18146 8.72012C5.55741 8.29121 5.96261 7.88885 6.39414 7.51592L6.43654 7.54136ZM15.3578 7.44808H13.6617V4.47998H14.5098C14.8235 4.47998 15.1034 4.47998 15.3578 4.52239V7.44808ZM26.7977 16.734C26.7977 17.0647 26.7977 17.4039 26.7553 17.7262H23.7702V16.0301H26.7638C26.7807 16.2591 26.7977 16.4965 26.7977 16.734Z',
            ],
          },
        },
      ],
    },
  }[process.env.GATSBY_VERTICAL]
);
