import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { v1 as uuidv1 } from 'uuid';
import { FieldArray, useFormikContext } from 'formik';

import { InputField } from 'src/components';
import UserPicker from './UserPicker';

const NewShipForm = ({ users }) => {
  const { values } = useFormikContext();
  const [selectableUsers, setSelectableUsers] = useState(
    [...users].sort((a, b) => a.email.localeCompare(b.email))
  );

  const resetUsers = (usedIds) => {
    setSelectableUsers(
      users.filter((s) => !usedIds.includes(s.id)).sort((a, b) => a.email.localeCompare(b.email))
    );
  };

  const addUserWithId = (id) => {
    if (!id) return;

    const ids = values.sme.filter((e) => e.id !== undefined).map((s) => s.id);
    if (values.drm.id) ids.push(values.drm.id);

    const index = ids.indexOf(id);
    if (index > -1) ids.splice(index, 1);

    resetUsers(ids);
  };

  const removeUserWithId = (id) => {
    const ids = values.sme.filter((e) => e.id !== undefined).map((s) => s.id);
    if (values.drm.id) ids.push(values.drm.id);
    ids.push(id);

    resetUsers(ids);
  };

  return (
    <div className="max-w-md pt-6 pb-12 space-y-12">
      <InputField
        name="shipName"
        label="forms.ship.name"
        namespaces={['dashboard']}
        autoComplete="ada"
      />
      <div>
        <span className="text-lg font-bold">Select a Designated Readiness Manager</span>
        <p className="pt-1 pb-6 text-sm text-gray-500">
          The Designated Readiness Manager (DRM) is the administrator of the facility. The DRM can
          complete sections of the facility compliance checklist and/or assign team members to
          complete one or more sections. The DRM will also have access to the progress dashboard.
        </p>
        <UserPicker
          name="drm"
          options={selectableUsers}
          addUserWithId={addUserWithId}
          removeUserWithId={removeUserWithId}
          remove={() => {}}
          removable={false}
        />
      </div>

      <div>
        <span className="text-lg font-bold">Add SME (optional)</span>
        <p className="pt-1 pb-6 text-sm text-gray-500">
          The Subject Matter Expert is responsible for completing and ensuring adherence to one or
          more assigned sections of the facility compliance checklist.
          <br />
          Assign one or more SMEs to the applicable Health Security sections.
        </p>

        <FieldArray name="sme">
          {({ remove, push }) => (
            <div className="space-y-6">
              <div className="space-y-10">
                {values.sme.map((sme, index) => (
                  <UserPicker
                    key={sme.key}
                    name={`sme[${index}]`}
                    options={selectableUsers}
                    addUserWithId={addUserWithId}
                    removeUserWithId={removeUserWithId}
                    remove={() => remove(index)}
                    removable={values.sme.length > 0}
                  />
                ))}
              </div>

              <div className="flex items-center justify-end">
                <button
                  type="button"
                  className="flex flex-row items-center space-x-2 font-bold text-blue-400 hover:underline focus:underline focus:outline-none focus:text-blue-900 hover:text-blue-900"
                  onClick={() => {
                    push({ key: uuidv1(), firstName: '', lastName: '', email: '', phone: '' });
                  }}
                >
                  <svg viewBox="0 0 17 17" className="w-4 h-4 fill-current">
                    <path d="M7.31339 1.62251C7.34568 1.00902 7.8623 0.492403 8.47578 0.460114C9.12156 0.460114 9.63818 0.976734 9.63818 1.62251L9.67046 7.33761H15.3533C15.999 7.33761 16.5157 7.85423 16.5157 8.5C16.5157 9.14577 15.999 9.66239 15.3533 9.66239H9.67046L9.63818 15.3775C9.63818 16.0233 9.12156 16.5399 8.47578 16.5399C7.8623 16.5076 7.34568 15.991 7.31339 15.3775L7.34568 9.66239L1.59829 9.66239C0.984805 9.6301 0.468186 9.11349 0.435897 8.5C0.468186 7.88651 0.984805 7.3699 1.59829 7.33761L7.34568 7.33761L7.31339 1.62251Z" />
                  </svg>
                  <span>Add a SME</span>
                </button>
              </div>
            </div>
          )}
        </FieldArray>
      </div>
    </div>
  );
};

NewShipForm.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
};

export default NewShipForm;
