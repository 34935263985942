import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { Page, Spinner } from 'src/components';
import Ship from 'src/assets/images/ship.svg';
import { CardList, CardItem, ModalNewShip } from './components';

const Ships = () => {
  const user = useSelector((state) => state.user);

  const { data, error, mutate } = useSWR(
    user.clientId ? [`/clients/${user.clientId}/subclients`] : null
  );
  const { data: dataUsers, error: errorUsers } = useSWR(
    user.clientId ? [`/users?clientId=${user.clientId}`] : null
  );

  const [modalNewShipOpen, setModalNewshipOpen] = useState(false);

  if (error) {
    return (
      <Page title="Ships">
        <Page.Main className="container pt-12">
          <div>An error has occurred.</div>
        </Page.Main>
      </Page>
    );
  }

  if (!data) {
    return (
      <Page title="Dashboard">
        <Page.Main className="container pt-12">
          <div className="flex justify-center text-primary-light">
            <Spinner />
          </div>
        </Page.Main>
      </Page>
    );
  }

  return (
    <>
      <Page title="Ships">
        <Page.Main className="container py-12">
          {data && data.length > 0 ? (
            <>
              <div className="pb-12">
                <button
                  type="button"
                  onClick={() => setModalNewshipOpen(true)}
                  disabled={errorUsers || !dataUsers || user.role !== 'ADMIN'}
                  className="w-full px-6 py-2 font-bold text-blue-500 transition rounded-md select-none sm:w-auto bg-blue-50 hover:bg-blue-100 focus:bg-blue-100 hover:text-blue-600 focus:text-blue-600 focus:outline-none"
                >
                  Add ship
                </button>
              </div>
              <CardList>
                {data
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((ship) => (
                    <CardItem key={ship.id} id={ship.id} name={ship.name} />
                  ))}
              </CardList>
            </>
          ) : (
            <div className="flex items-center justify-center">
              <div className="flex flex-col items-center justify-center space-y-4">
                <img src={Ship} alt="Ship" />
                <button
                  type="button"
                  onClick={() => setModalNewshipOpen(true)}
                  disabled={errorUsers || !dataUsers}
                  className="px-6 py-4 font-bold text-blue-500 transition rounded-md select-none sm:px-24 bg-blue-50 hover:bg-blue-100 focus:bg-blue-100 hover:text-blue-600 focus:text-blue-600 focus:outline-none"
                >
                  Add your first ship
                </button>
              </div>
            </div>
          )}
        </Page.Main>
      </Page>
      {modalNewShipOpen && (
        <ModalNewShip
          open={modalNewShipOpen}
          requestClose={() => setModalNewshipOpen(false)}
          mutate={mutate}
          users={dataUsers}
        />
      )}
    </>
  );
};

export default Ships;
