import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const Address = ({ title, addressee, attention, phone, address, email }) => {
  const { t } = useTranslation('facility');

  return (
    <section className="px-8 py-6 md:px-6 md:py-12 lg:px-12 xl:px-24">
      <h5 className="font-bold">{t(`billing.titles.${title}`)}</h5>
      <div className="grid max-w-screen-sm grid-cols-2 gap-4 pt-4">
        <span className="text-secondary">{t('billing.address.attention')}</span>
        <span>{attention}</span>
        <span className="text-secondary">{t('billing.address.addressee')}</span>
        <span>{addressee}</span>
        {email && (
          <>
            <span className="text-secondary">{t('billing.address.email')}</span>
            <span>{email}</span>
          </>
        )}
        <span className="text-secondary">{t('billing.address.phone')}</span>
        <span>{phone}</span>
        <span className="text-secondary">{t('informations.items.address')}</span>
        <span>
          {address.line1}
          {address.line2 && (
            <>
              <br />
              {address.line2}
            </>
          )}
          <br />
          <span>{`${address.zipCode}, ${address.city} `}</span>
          {address.country && <span className="uppercase">{address.country}</span>}
        </span>
      </div>
    </section>
  );
};

Address.propTypes = {
  title: PropTypes.string.isRequired,
  addressee: PropTypes.string.isRequired,
  attention: PropTypes.string.isRequired,
  email: PropTypes.string,
  phone: PropTypes.string.isRequired,
  address: PropTypes.shape({
    line1: PropTypes.string.isRequired,
    line2: PropTypes.string,
    zipCode: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
  }).isRequired,
};

Address.defaultProps = {
  email: undefined,
};

export default Address;
