import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { Page, Pagination, Spinner, Searcher } from 'src/components';
import { fetchOnce } from 'src/helpers';
import { CardList, CardItem, CardAdd, ModalAdd } from './components';

// MULTISTEP ADD FACILITY
import ModalAddMultistep from './components/modal-add-withMultistep/ModalAddMultistep';

import VERTICALS from 'src/constants/verticals.json';

const Facilities = () => {
  const user = useSelector((state) => state.user);

  const [search, setSearch] = useState('');

  const [newFacilityModalOpen, setNewFacilityModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const pageSize = 12;
  const { data: facilitiesData, error: errorFacilities, mutate: mutateFacilities } = useSWR(
    user.clientId
      ? [
          `/facilities?clientId=${user.clientId}&pages=true&page=${
            search ? 1 : page
          }&size=${pageSize}&${search ? `&search=${encodeURIComponent(search)}` : ''}`,
        ]
      : null
  );
  const { data: users, error: errorUsers } = useSWR(
    user.clientId ? [`/users?clientId=${user.clientId}`] : null,
    fetchOnce
  );
  const { data: products, error: errorProducts } = useSWR(
    user.endorser ? [`/products/${user.endorser}`] : null,
    fetchOnce
  );
  const { data: industries, error: errorIndustries } = useSWR('/facilities/mt/contexts', fetchOnce);

  // MULTISTEP ADD FACILITY
  const { GATSBY_VERTICAL } = process.env;

  if (errorFacilities || errorProducts || errorUsers || errorIndustries) {
    return (
      <Page title="Dashboard">
        <Page.Main className="container pt-12">
          <div>An error has occurred.</div>
        </Page.Main>
      </Page>
    );
  }

  if (!facilitiesData || !products || !industries) {
    return (
      <Page title="Dashboard">
        <Page.Main className="container pt-12">
          <div className="flex justify-center text-primary-light">
            <Spinner />
          </div>
        </Page.Main>
      </Page>
    );
  }

  const triggerHandler = () => {
    setNewFacilityModalOpen(true);
  };

  return (
    <Page title="Dashboard">
      <Page.Main className="container pt-8 pb-12">
        <div className="flex flex-row items-center pb-8">
          {user.role === 'ADMIN' && GATSBY_VERTICAL !== VERTICALS.VACCINE && (
            <button
              type="button"
              onClick={triggerHandler}
              className="px-8 py-2 mr-8 font-bold text-blue-500 transition rounded-md bg-blue-50 hover:bg-blue-100 focus:bg-blue-100 focus:outline-none focus:text-blue-600 hover:text-blue-600"
            >
              New Facility
            </button>
          )}
          <div className="mr-8 w-60">
            <Searcher search={search} setSearch={setSearch} placeholder="Search a Facility..." />
          </div>
          {search && facilitiesData.facilities && (
            <span className="text-sm text-secondary whitespace-nowrap">
              {`${
                facilitiesData.facilities.length === 1
                  ? `${facilitiesData.facilities.length} result`
                  : `${facilitiesData.facilities.length} results`
              }`}
            </span>
          )}
        </div>
        <CardList>
          {facilitiesData.facilities
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((facility) => (
              <CardItem
                key={facility.id}
                id={facility.id}
                name={facility.name}
                address={facility.address}
                logo={facility.logo}
                status={facility.statusChatbot}
                badge={facility.badge}
              />
            ))}
        </CardList>
        {facilitiesData.pages > 1 && (
          <Pagination
            page={page}
            setPage={setPage}
            pages={facilitiesData.pages}
            result={facilitiesData.result}
            size={pageSize}
          />
        )}
      </Page.Main>

      {GATSBY_VERTICAL === 'GREENSHIELD' ? (
        <ModalAddMultistep
          open={newFacilityModalOpen}
          requestClose={() => setNewFacilityModalOpen(false)}
          users={users}
          products={products.map(({ product: e }) => ({ ...e }))}
          industries={industries}
          mutate={mutateFacilities}
        />
      ) : (
        <ModalAdd
          open={newFacilityModalOpen}
          requestClose={() => setNewFacilityModalOpen(false)}
          users={users}
          products={products.map(({ product: e }) => ({ ...e }))}
          industries={industries}
          mutate={mutateFacilities}
        />
      )}
    </Page>
  );
};

export default Facilities;
