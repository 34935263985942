import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Formik, Form } from 'formik';

import { httpClient } from 'src/helpers';
import { Modal, Snackbar, Button, Spinner } from 'src/components';
import NewLogoForm from './NewLogoForm';

const ModalNewLogo = ({ open, requestClose, logo, mutate }) => {
  const user = useSelector((state) => state.user);

  const [snackbarState, setSnackbarState] = useState({ type: 'HIDDEN', msg: '' });
  const [isDeleting, setIsDeleting] = useState(false);

  const handleSubmit = async (values, actions) => {
    if (window.gtag) window.gtag('event', 'Edit_client_logo_upload');

    try {
      const formData = new FormData();
      formData.append('logo', values.logo);

      const { status } = await httpClient.post(`/clients/${user.clientId}/logo`, formData);

      if (status === 201) {
        setTimeout(() => {
          setSnackbarState({ type: 'SUCCESS' });
          mutate();
        }, 1000);

        setTimeout(() => {
          requestClose();
        }, 2000);
      } else throw Error('Logo upload failed.');
    } catch (err) {
      setSnackbarState({ type: 'ERROR' });
      actions.setSubmitting(false);
    }
  };

  const handleDelete = async (setLogo) => {
    setSnackbarState({ type: 'HIDDEN' });
    setIsDeleting(true);

    if (window.gtag) window.gtag('event', 'Edit_client_logo_remove');

    try {
      const { status } = await httpClient.post(`/clients/${user.clientId}/logo`);

      if (status === 200) {
        mutate();

        setTimeout(() => {
          setIsDeleting(false);
          setLogo(null);
        }, 2000);
      } else throw Error('Logo deletion failed.');
    } catch (err) {
      setSnackbarState({ type: 'ERROR' });
      setIsDeleting(false);
    }
  };

  return (
    <Modal open={open} requestClose={requestClose}>
      <Modal.Header>
        <h4 className="text-lg font-bold">Logo</h4>
        <div className="absolute right-0 pr-6">
          <button type="button" onClick={requestClose}>
            <svg viewBox="0 0 20 20" className="w-5 h-5">
              <path d="M16.6952 0.51282C17.4549 -0.17094 18.6705 -0.17094 19.4302 0.51282C20.1899 1.27255 20.1899 2.48813 19.4302 3.24786L12.7445 10.0095L19.4302 16.6952C20.1899 17.4549 20.1899 18.6705 19.4302 19.4302C18.6705 20.1899 17.4549 20.1899 16.6952 19.4302L10.0095 12.7445L3.24786 19.4302C2.48813 20.1899 1.27255 20.1899 0.51282 19.4302C-0.17094 18.6705 -0.17094 17.4549 0.51282 16.6952L7.27445 10.0095L0.51282 3.24786C-0.17094 2.48813 -0.17094 1.27255 0.51282 0.51282C1.27255 -0.17094 2.48813 -0.17094 3.24786 0.51282L10.0095 7.27445L16.6952 0.51282Z" />
            </svg>
          </button>
        </div>
      </Modal.Header>

      <Modal.Body className="px-6">
        <Formik
          initialValues={{ logo }}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
            handleSubmit(values, actions);
          }}
        >
          {({ isValid, isSubmitting, dirty, values }) => (
            <Form className="max-w-md mx-auto" autoComplete="off">
              <Snackbar
                type={snackbarState.type}
                message={snackbarState.msg}
                requestClose={() => setSnackbarState({ ...snackbarState, type: 'HIDDEN' })}
              />

              <div
                style={{
                  opacity: isSubmitting || isDeleting ? '0' : '1',
                  height: isSubmitting || isDeleting ? '0' : 'auto',
                  pointerEvents: isSubmitting || isDeleting ? 'none' : 'auto',
                }}
              >
                <NewLogoForm handleDelete={handleDelete} />
              </div>

              {(isSubmitting || isDeleting) && (
                <div
                  className="flex items-center justify-center py-4 text-primary-light"
                  style={{ height: '324px' }}
                >
                  <Spinner />
                </div>
              )}

              {values.logo && !isSubmitting && !isDeleting && (
                <div className="flex justify-center pb-6">
                  <Button type="submit" size="md" disabled={!(isValid && dirty)}>
                    Save
                  </Button>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

ModalNewLogo.propTypes = {
  open: PropTypes.bool.isRequired,
  requestClose: PropTypes.func.isRequired,
  logo: PropTypes.string,
  mutate: PropTypes.func.isRequired,
};

ModalNewLogo.defaultProps = {
  logo: null,
};

export default ModalNewLogo;
