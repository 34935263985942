import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { Router as ReachRouter } from '@reach/router';
import useSWR from 'swr';
import { useDispatch } from 'react-redux';
import { useMsal } from '@azure/msal-react';

import views from 'src/constants/views';
import { Page, NotFound, Spinner } from 'src/components';
import { signOut } from 'src/store';
import Layout from './layout';

const Router = ({ facilityId }) => {
  const { data, error, mutate } = useSWR(facilityId ? [`/facilities/${facilityId}`] : null);

  if (error) {
    const { sessionLost } = error;

    if (sessionLost) {
      const { instance } = useMsal();
      const dispatch = useDispatch();

      // trigger sign out if session lost
      dispatch(signOut())
        .then(async () => instance.logout())
        .catch(async () => instance.logout());
    } else {
      return <NotFound />;
    }
  }

  if (!data) {
    return (
      <Page title="Facility">
        <Page.Main className="container pt-24">
          <div className="flex justify-center text-primary-light">
            <Spinner />
          </div>
        </Page.Main>
      </Page>
    );
  }

  if (data.statusChatbot === 'PENDING' || data.statusChatbot === 'FAILED') {
    navigate('/dashboard/', { replace: true });
    return null;
  }

  return (
    <Layout facility={{ ...data, id: facilityId }}>
      <ReachRouter>
        {views.facility.map(
          (v) =>
            v.enable && (
              <v.component
                key={v.path}
                path={v.path}
                facility={{ ...data, id: facilityId }}
                mutate={mutate}
              />
            )
        )}
        <NotFound default />
      </ReachRouter>
    </Layout>
  );
};

Router.propTypes = {
  facilityId: PropTypes.string,
};

Router.defaultProps = {
  facilityId: null,
};

export default Router;
