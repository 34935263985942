import React from 'react';
import { useSelector } from 'react-redux';
import { Router as ReachRouter } from '@reach/router';

import views from 'src/constants/views';
import URLS from 'src/constants/urls.json';
import { NotFound, Spinner } from 'src/components';
import { DashboardRouter, FacilityRouter, SiteRouter, ShipRouter } from 'src/views/app/pages';

const permissions = {
  CARS: ['ADMIN', 'USER'],
  DEFAULT: ['ADMIN', 'MANAGER'],
  GREENSHIELD: ['ADMIN', 'MANAGER', 'VIEWER'],
};

const Router = () => {
  const VERTICAL = process.env.GATSBY_VERTICAL;
  const user = useSelector((state) => state.user);

  if (typeof window === 'undefined' || !user.role)
    return (
      <div className="flex items-center justify-center h-12 pt-24 text-primary-light">
        <Spinner />
      </div>
    );

  if (!(permissions[VERTICAL] || permissions.DEFAULT).includes(user.role)) {
    return (
      <div className="container flex items-center justify-center h-screen py-24">
        <div className="max-w-screen-sm mx-auto">
          <div className="flex items-center md:space-x-6">
            <svg viewBox="0 0 180.000000 161.000000" className="hidden h-56 lg:block">
              <g transform="translate(0.000000,161.000000) scale(0.100000,-0.100000)">
                <path
                  d="M775 1589 c-276 -43 -513 -225 -619 -477 -43 -101 -59 -187 -59 -307 0 -139 19 -220 82 -350 131 -268 400 -435 702 -436 138 0 230 21 354 81 160 77 282 197 360 355 64 131 79 196 79 350 0 153 -14 217 -79 350 -126 259 -377 423 -673 439 -48 3 -114 1 -147 -5z m254 -194 c210 -50 390 -227 446 -440 19 -76 19 -224 0 -299 -41 -158 -172 -319 -316 -390 -281 -138 -609 -49 -781 213 -118 180 -129 406 -30 604 71 143 229 268 392 311 72 19 209 19 289 1z"
                  fill="#4a4a4a"
                />
                <path
                  d="M605 1131 c-108 -49 -159 -185 -113 -306 35 -92 145 -221 278 -328 88 -70 79 -80 105 119 l17 131 -40 39 -41 39 40 85 40 85 -25 18 c-69 50 -70 52 -58 85 11 31 10 32 -26 42 -56 15 -132 12 -177 -9z"
                  fill="#029099"
                />
                <path
                  d="M976 1136 c-22 -8 -48 -24 -60 -35 l-21 -21 42 -42 41 -41 -31 -64 -32 -64 44 -37 44 -38 -63 -164 c-34 -91 -66 -175 -71 -187 -27 -70 114 18 256 161 121 123 169 211 170 311 0 88 -31 156 -92 199 -35 24 -54 30 -115 33 -48 2 -87 -2 -112 -11z"
                  fill="#029099"
                />
              </g>
            </svg>
            <div className="flex flex-col w-full">
              <h1 className="font-bold uppercase text-secondary">Oops!</h1>
              <p className="text-lg text-secondary">
                It seems that you don&apos;t have access to this page.
              </p>
            </div>
          </div>
          {VERTICAL === 'FTG' && (
            <div className="flex items-center justify-center w-full pt-6">
              <a
                href={`${
                  URLS.BOT[process.env.GATSBY_DEPLOYMENT]
                }/healthsecuritycertification/healthsecurityverification.html`}
                target="_blank"
                rel="noreferrer"
                className="w-1/2 py-4 font-semibold text-center text-white transition rounded-md bg-primary-light hover:bg-primary-dark focus:bg-primary-dark focus:outline-none"
              >
                Open Assessment Bot
              </a>
            </div>
          )}
          {VERTICAL === 'HOSPITALITY' && (
            <div className="flex items-center justify-center w-full pt-6">
              <a
                href={`${
                  URLS.ASSESSMENT[process.env.GATSBY_DEPLOYMENT]
                }/healthsecuritycertification/healthsecurityverification.html`}
                target="_blank"
                rel="noreferrer"
                className="w-1/2 py-4 font-semibold text-center text-white transition rounded-md bg-primary-light hover:bg-primary-dark focus:bg-primary-dark focus:outline-none"
              >
                Open Assessment Bot
              </a>
            </div>
          )}
          {VERTICAL === 'VACCINE' && (
            <div className="flex items-center justify-center w-full pt-6">
              <a
                href={`${URLS.ASSISTANT[process.env.GATSBY_DEPLOYMENT]}/provider`}
                target="_blank"
                rel="noreferrer"
                className="w-1/2 py-4 font-semibold text-center text-white transition rounded-md bg-primary-light hover:bg-primary-dark focus:bg-primary-dark focus:outline-none"
              >
                Provider Assistant
              </a>
            </div>
          )}
          {/* {VERTICAL === 'CARS' && (
            <div className="flex items-center justify-center w-full pt-6">
              <a
                href={URLS.ASSISTANT[process.env.GATSBY_DEPLOYMENT]}
                target="_blank"
                rel="noreferrer"
                className="w-1/2 py-4 font-semibold text-center text-white transition rounded-md bg-primary-light hover:bg-primary-dark focus:bg-primary-dark focus:outline-none"
              >
                Access Provider Assistant Bot
              </a>
            </div>
          )} */}
        </div>
      </div>
    );
  }

  return (
    <ReachRouter basepath="dashboard">
      {views.site && <SiteRouter path="sites/:siteId/*" />}
      {views.facility && <FacilityRouter path="facilities/:facilityId/*" />}
      {views.ship && <ShipRouter path="ships/:shipId/*" />}
      <DashboardRouter path="/*" />
      <NotFound default />
    </ReachRouter>
  );
};

export default Router;
