import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useSWR from 'swr';

import { fetchOnce } from 'src/helpers';
import { Page, Pagination, Sorter } from 'src/components';
import {
  ModalViewJourney,
  ModalUpdateJourney,
  ModalDeleteJourney,
  JourneysLoader,
} from 'src/views/app/shared';
import Ship from 'src/assets/images/ship.svg';
import STATE from 'src/constants/journey-states';
import { Journey, ModalNewJourneyForShip } from './components';

const sorts = [
  { label: 'Sail date', func: (a, b) => a.sail.localeCompare(b.sail) },
  { label: 'Homeport', func: (a, b) => a.homeport.localeCompare(b.homeport) },
];

const Journeys = ({ ship }) => {
  const [filter, setFilter] = useState(STATE.PENDING);
  const [sort, setSort] = useState(sorts[0]);
  const [page, setPage] = useState(1);
  const [modalNewJourneyOpen, setModalNewJourneyOpen] = useState(false);
  const [viewJourney, setViewJourney] = useState(null);
  const [updateJourney, setUpdateJourney] = useState(null);
  const [deleteJourney, setDeleteJourney] = useState(null);

  const { data, error, mutate } = useSWR(
    ship.id ? [`/subclients/${ship.id}/facilities?state=${filter}&page=${page}`] : null
  );
  const { data: dataTopics, error: errorTopics } = useSWR('/facilities/mt/topics', fetchOnce);
  const { data: dataIndusties, error: errorIndustries } = useSWR(
    '/facilities/mt/contexts',
    fetchOnce
  );

  useEffect(() => {
    setPage(1);
  }, [sort.label]);

  if (error) {
    return (
      <Page title="Journeys">
        <Page.Main className="container pt-24 pb-24 lg:pt-32">
          <div>An error has occurred.</div>
        </Page.Main>
      </Page>
    );
  }

  return (
    <>
      <Page title="Journeys">
        <Page.Main className="container pt-24 pb-24 lg:pt-32">
          <div className="flex flex-col-reverse lg:items-center lg:flex-row lg:justify-between">
            <div className="flex items-center pt-4 space-x-6 lg:pt-2">
              <button
                type="button"
                onClick={() => setFilter(STATE.PENDING)}
                className={`w-full whitespace-nowrap transition border-b-2 pb-2 hover:text-blue-600 focus:text-blue-600 focus:outline-none ${
                  filter === STATE.PENDING
                    ? 'text-blue-500 border-blue-500'
                    : 'text-secondary border-transparent'
                }`}
              >
                Evaluation in progress
              </button>
              <button
                type="button"
                onClick={() => setFilter(STATE.FUTURE)}
                className={`w-full whitespace-nowrap transition border-b-2 pb-2 hover:text-blue-600 focus:text-blue-600 focus:outline-none ${
                  filter === STATE.FUTURE
                    ? 'text-blue-500 border-blue-500'
                    : 'text-secondary border-transparent'
                }`}
              >
                Future journeys
              </button>
              <button
                type="button"
                onClick={() => setFilter(STATE.VERIFIED)}
                className={`w-full whitespace-nowrap transition border-b-2 pb-2 hover:text-blue-600 focus:text-blue-600 focus:outline-none ${
                  filter === STATE.VERIFIED
                    ? 'text-blue-500 border-blue-500'
                    : 'text-secondary border-transparent'
                }`}
              >
                Passed Journeys
              </button>
            </div>
            <div className="flex items-center justify-between space-x-6">
              <button
                type="button"
                onClick={() => setModalNewJourneyOpen(true)}
                disabled={errorTopics || errorIndustries || !dataTopics || !dataIndusties}
                className="w-full px-6 py-2 font-bold text-blue-500 transition rounded-md select-none md:w-auto bg-blue-50 hover:bg-blue-100 focus:bg-blue-100 hover:text-blue-600 focus:text-blue-600 focus:outline-none"
              >
                Create Journey
              </button>
              <div className="flex-shrink-0">
                <Sorter sorts={sorts} sort={sort} setSort={setSort} />
              </div>
            </div>
          </div>
          {!data && <JourneysLoader />}
          {data && data.facilities && data.facilities.length <= 0 && (
            <div className="flex items-center justify-center pt-6">
              <div>
                <img src={Ship} alt="Ship" />
                <span className="block text-sm font-bold text-center">No journey yet</span>
              </div>
            </div>
          )}
          {data && data.facilities && data.facilities.length > 0 && (
            <>
              <div className="hidden grid-cols-2 pt-12 md:grid sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-7">
                <span className="pl-4 text-sm font-bold uppercase text-secondary">Ship</span>
                <span className="hidden text-xs font-bold text-center uppercase xl:block text-secondary">
                  Homeport
                </span>
                <span className="hidden text-xs font-bold text-center uppercase xl:block text-secondary">
                  Sail date
                </span>
                <span className="text-xs font-bold text-center uppercase text-secondary">
                  Status
                </span>
                <span className="text-xs font-bold text-center uppercase text-secondary">Drm</span>
              </div>
              <div className="grid pt-6 gap-y-4">
                {data.facilities.sort(sort.func).map((journey) => (
                  <Journey
                    key={journey.id}
                    journey={journey}
                    status={journey.badge || 'PENDING'}
                    openView={() => setViewJourney({ ...journey, id: journey.id.toString() })}
                    openEdit={() => setUpdateJourney({ ...journey, id: journey.id.toString() })}
                    openDelete={() => setDeleteJourney(journey.id.toString())}
                    editable={filter === STATE.FUTURE && !errorTopics && dataTopics.length > 0}
                    removable={filter === STATE.FUTURE}
                  />
                ))}
              </div>
              {data.pages > 1 && (
                <Pagination page={page} setPage={setPage} pages={data.pages} result={data.result} />
              )}
            </>
          )}
        </Page.Main>
      </Page>
      {modalNewJourneyOpen && (
        <ModalNewJourneyForShip
          open={modalNewJourneyOpen}
          requestClose={() => setModalNewJourneyOpen(false)}
          mutate={mutate}
          ship={ship}
          topics={dataTopics}
          industries={dataIndusties}
        />
      )}
      {viewJourney && (
        <ModalViewJourney
          open={viewJourney !== null}
          requestClose={() => setViewJourney(null)}
          journey={viewJourney}
          topics={dataTopics}
        />
      )}
      {updateJourney && (
        <ModalUpdateJourney
          open={updateJourney !== null}
          requestClose={() => setUpdateJourney(null)}
          journey={updateJourney}
          mutate={mutate}
          topics={dataTopics}
        />
      )}
      {deleteJourney && (
        <ModalDeleteJourney
          open={deleteJourney !== null}
          requestClose={() => setDeleteJourney(null)}
          journeyId={deleteJourney}
          mutate={mutate}
        />
      )}
    </>
  );
};

Journeys.propTypes = {
  ship: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default Journeys;
