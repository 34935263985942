import React from 'react';
import PropTypes from 'prop-types';
import { Router as ReachRouter } from '@reach/router';
import useSWR from 'swr';

import views from 'src/constants/views';
import { Page, NotFound, Spinner } from 'src/components';
import Layout from './layout';

const Router = ({ shipId }) => {
  const { data, error } = useSWR(shipId ? [`/subclients/${shipId}`] : null);

  if (error) {
    return <NotFound />;
  }

  if (!data) {
    return (
      <Page title="Site">
        <Page.Main className="container pt-24">
          <div className="flex justify-center text-primary-light">
            <Spinner />
          </div>
        </Page.Main>
      </Page>
    );
  }

  return (
    <Layout ship={{ ...data, id: `${shipId}` }}>
      <ReachRouter>
        {views.ship.map(
          (v) =>
            v.enable && (
              <v.component
                key={v.path}
                path={v.path}
                ship={{ ...data, id: `${shipId.toString()}` }}
              />
            )
        )}
        <NotFound default />
      </ReachRouter>
    </Layout>
  );
};

Router.propTypes = {
  shipId: PropTypes.string,
};

Router.defaultProps = {
  shipId: null,
};

export default Router;
