import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';

import { InputField } from 'src/components';

import debounce from 'lodash.debounce';
import { httpClient } from 'src/helpers';
import UserPicker from '../../UserPicker';

import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import PropTypes from 'prop-types';

const translation = () => {
  return [
    'greenshield:forms.manager.titles.introduction',
    'greenshield:forms.manager.titles.description',
  ];
};

const initialValues = {
  manager: 'Sam',
  marketing: 'marketingThingy',
};

const schema = Yup.object().shape({
  manager: Yup.object().shape({
    firstName: Yup.string().max(250, 'forms.too_long').required('forms.required'),
    lastName: Yup.string().max(250, 'forms.too_long').required('forms.required'),
    title: Yup.string().max(250, 'forms.too_long').required('forms.required'),
    email: Yup.string()
      .email('forms.format')
      .required('forms.required')
      .when('newUser', {
        is: true,
        then: Yup.string()
          .email('forms.format')
          .max(250, 'forms.too_long')
          .required('forms.required')
          .test(
            'manager-email-backend-validation',
            'forms.emailUsed',
            debounce(async (email) => {
              try {
                if (!email || email === '') return true;
                const { data } = await httpClient.get(`/users?email=${email.toLowerCase()}`);
                return !data.length;
              } catch (error) {
                return false;
              }
            }, 750)
          ),
      }),
    phone: Yup.string()
      .max(250, 'forms.too_long')
      .test('phone-match-regex', 'forms.format', (phone) => {
        if (!phone || phone === '') return true;
        return phone.match(
          /^([+(]?|\d{1}|(\+?\d{2}))[ ]?(\(?\d{1,4}\)?)[- ]?\d{1,4}[- ]?\d{1,4}([- ]?\d{1,5})?$/
        );
      })
      .nullable(),
  }),
});

const DEFAULT_MANAGER = { newUser: false, isManager: true, roles: ['MANAGER'] };

const Step = ({ users }) => {
  const { t } = useTranslation(['dashboard', 'greenshield']);

  const [{ value }] = useField('manager');
  const [{ value: markets }, , { setValue }] = useField('marketing');

  const [selectableUsers, setSelectableUsers] = useState(
    [...users].sort((a, b) => a.email.localeCompare(b.email))
  );

  const resetUsers = (userId) => {
    setSelectableUsers(
      users.filter((s) => s.id !== userId).sort((a, b) => a.email.localeCompare(b.email))
    );
  };

  useEffect(() => {
    // if (markets.manager?.isManager && markets.lead?.isManager)
    //   setValue({
    //     ...markets,
    //     manager: { ...value, ...DEFAULT_MANAGER },
    //     lead: { ...value, ...DEFAULT_MANAGER },
    //   });
    // else if (markets.manager?.isManager)
    //   setValue({ ...markets, manager: { ...value, ...DEFAULT_MANAGER } });
    // else if (markets.lead?.isManager)
    //   setValue({ ...markets, lead: { ...value, ...DEFAULT_MANAGER } });
  }, [value.firstName, value.lastName, value.email, value.title, value.phone]);

  return (
    <div className="pb-24 space-y-6">
      <div>
        <div className="sm:w-2/3">
          <span className="text-xl font-bold">{t(translation()[0])}</span>
          <p className="pt-1 text-sm text-gray-500">{t(translation()[1])}</p>
        </div>
      </div>

      <div className="sm:w-2/3">
        <UserPicker
          name="manager"
          options={selectableUsers}
          addUserWithId={resetUsers}
          removeUserWithId={resetUsers}
        />
      </div>
    </div>
  );
};

export { initialValues, schema };
export default Step;
