import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ModalNewLogo } from './components';

const Logo = ({ logo, canEditLogo, mutate }) => {
  const [modalNewLogoOpen, setModalNewLogoOpen] = useState(false);

  return (
    <>
      <section>
        <div className="flex flex-col items-start space-y-6 sm:space-x-6 sm:flex-row">
          <div className="p-4 bg-gray-100 rounded-lg sm:p-6">
            {logo ? (
              <img src={logo} alt={logo} className="object-contain w-24 h-24 sm:w-32 sm:h-32 " />
            ) : (
              <svg
                viewBox="0 0 75 80"
                className="w-24 h-24 fill-current sm:w-32 sm:h-32 text-secondary"
              >
                <path d="M68.7458 21.718C65.9837 16.9349 42.6068 3.44434 37.0826 3.44434C31.5668 3.44434 8.18158 16.9349 5.41948 21.718C2.85106 26.1643 2.85106 53.8359 5.41948 58.2822C8.19001 63.0654 31.5668 76.5559 37.0826 76.5559C42.6068 76.5559 65.9837 63.0654 68.7458 58.2822C71.3142 53.8359 71.3142 26.1643 68.7458 21.718Z" />
                <path d="M71.7263 20C68.3747 14.2063 44.0379 0 37.0821 0C30.1263 0 5.78947 14.2063 2.43789 19.9916C-0.812631 25.6084 -0.812631 54.3916 2.43789 60C5.78947 65.7937 30.1263 80 37.0821 80C44.0379 80 68.3747 65.7937 71.7263 60.0084C74.9768 54.3916 74.9768 25.6084 71.7263 20ZM69.941 58.9726C66.8589 64.2863 43.2211 77.9368 37.0821 77.9368C30.9432 77.9368 7.29684 64.2863 4.22316 58.9642C1.35158 54.0042 1.35158 25.9874 4.22316 21.0274C7.30526 15.7137 30.9516 2.06316 37.0821 2.06316C43.2211 2.06316 66.8589 15.7053 69.941 21.0274C72.8126 25.9958 72.8126 54.0042 69.941 58.9726Z" />
              </svg>
            )}
          </div>
          <div className="flex flex-col space-y-4">
            <h5 className="font-bold">Upload logo</h5>
            <span className="text-gray-500">
              Add a logo to your facilities to customize your bot.
            </span>
            <div>
              <button
                type="button"
                disabled={!canEditLogo}
                onClick={() => setModalNewLogoOpen(true)}
                className="px-6 py-2 font-bold text-blue-500 transition rounded-md bg-blue-50 hover:bg-blue-100 focus:bg-blue-100 focus:outline-none focus:text-blue-600 hover:text-blue-600"
              >
                Edit
              </button>
            </div>
          </div>
        </div>
      </section>
      {modalNewLogoOpen && (
        <ModalNewLogo
          open={modalNewLogoOpen}
          requestClose={() => setModalNewLogoOpen(false)}
          logo={logo}
          mutate={mutate}
        />
      )}
    </>
  );
};

Logo.propTypes = {
  logo: PropTypes.string,
  canEditLogo: PropTypes.bool.isRequired,
  mutate: PropTypes.func.isRequired,
};

Logo.defaultProps = {
  logo: null,
};

export default Logo;
