import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { timestampToDateFormat, STATUS } from 'src/helpers';

const Informations = ({ data }) => {
  const { t, i18n } = useTranslation('facility');

  return (
    <div className="flex items-center justify-center md:justify-start">
      <div>
        <p className="flex items-center space-x-2 text-lg">
          <span className="text-secondary">{t('badge.title')}</span>
          <strong className="uppercase">{t(`badge.status.${STATUS[data.status].label}`)}</strong>
          <span>{STATUS[data.status].icon}</span>
        </p>
        {data.status !== 'DELETED' && (
          <div className="flex flex-col items-center justify-center pt-2 md:items-start text-secondary">
            {data.status === 'VERIFIED' && (
              <>
                {data.compliantSince && data.validUntil && (
                  <p className="text-sm">
                    {t('badge.from')}
                    {`${timestampToDateFormat(data.compliantSince, i18n.language)}`}
                    {t('badge.to')}
                    {`${timestampToDateFormat(data.validUntil, i18n.language)}`}
                  </p>
                )}
                {data.nextMCD && (
                  <p className="text-sm">
                    {t('badge.next')}
                    <strong>{`${timestampToDateFormat(data.nextMCD, i18n.language)}`}</strong>
                  </p>
                )}
              </>
            )}
            {data.status === 'PENDING' && (
              <>
                <strong>{t('badge.pending')}</strong>
                {data.firstDeadline && (
                  <p className="text-sm">
                    {t('badge.deadline')}
                    {`${timestampToDateFormat(data.firstDeadline, i18n.language)}`}
                  </p>
                )}
              </>
            )}
            {data.status === 'UNVERIFIED' && (
              <>
                <strong className="text-warning">{t('badge.pending')}</strong>
                {data.compliantSince && data.validUntil && (
                  <p className="text-sm">
                    {t('badge.from')}
                    {`${timestampToDateFormat(data.compliantSince, i18n.language)}`}
                    {t('badge.to')}
                    {`${timestampToDateFormat(data.validUntil, i18n.language)}`}
                  </p>
                )}
                {data.lossDeadline && (
                  <p className="text-sm">
                    {t('badge.loss')}
                    <strong>{`${timestampToDateFormat(data.lossDeadline, i18n.language)}`}</strong>
                  </p>
                )}
              </>
            )}
            {data.status === 'REVOKED' && (
              <>
                <strong className="text-danger">{t('badge.pending')}</strong>
                {data.compliantSince && data.validUntil && (
                  <p className="text-sm">
                    {t('badge.from')}
                    {`${timestampToDateFormat(data.compliantSince, i18n.language)}`}
                    {t('badge.to')}
                    {`${timestampToDateFormat(data.validUntil, i18n.language)}`}
                  </p>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

Informations.propTypes = {
  data: PropTypes.shape({
    status: PropTypes.oneOf(['PENDING', 'VERIFIED', 'UNVERIFIED', 'REVOKED', 'DELETED']),
    firstDeadline: PropTypes.number,
    compliantSince: PropTypes.number,
    validUntil: PropTypes.number,
    nextMCD: PropTypes.number,
    lossDeadline: PropTypes.number,
  }),
};

Informations.defaultProps = {
  data: {
    status: 'PENDING',
    firstDeadline: undefined,
    compliantSince: undefined,
    validUntil: undefined,
    nextMCD: undefined,
    lossDeadline: undefined,
  },
};

export default Informations;
