import React from 'react';
import PropTypes from 'prop-types';

import Sidebar from './Sidebar';

const Layout = ({ children, facility }) => (
  <div id="layout-facility">
    <div>
      <Sidebar facility={facility} />
    </div>
    <div className="pb-20 lg:pb-0">{children}</div>
  </div>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  facility: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    logo: PropTypes.string,
    address: PropTypes.shape({
      city: PropTypes.string,
      country: PropTypes.string,
    }),
  }),
};

Layout.defaultProps = {
  facility: {
    logo: null,
    address: {
      city: undefined,
      country: undefined,
    },
  },
};

export default Layout;
