import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import { InputField, Avatar } from 'src/components';
import { useField } from 'formik';

const filterUsers = (option, input) => {
  if (!input || input === '') return true;
  return (
    option.data.firstName.toLowerCase().includes(input.toLowerCase()) ||
    option.data.lastName.toLowerCase().includes(input.toLowerCase()) ||
    option.data.email.toLocaleLowerCase().includes(input.toLocaleLowerCase())
  );
};

const formatOptionLabel = ({ firstName, lastName, email, me, roles }) => (
  <div className="flex items-center space-x-4">
    <Avatar initials={`${firstName.slice(0, 1)}${lastName.slice(0, 1)}`} role={roles[0]} />
    <div className="flex flex-col">
      <span className="text-base capitalize">
        {`${firstName} ${lastName} ${me ? '(You)' : ''}`}
      </span>
      <p className="text-sm truncate text-secondary" style={{ maxWidth: '220px' }}>
        {email}
      </p>
    </div>
  </div>
);

const UserPicker = ({ name, options, addUserWithId, removeUserWithId, remove, removable }) => {
  const [{ value }, , { setValue, setTouched }] = useField(name);
  const [select, setSelect] = useState(true);

  const toggleSelect = (pick) => {
    addUserWithId(value.id);
    setValue(
      { ...value, id: undefined, firstName: '', lastName: '', email: '', title: '', phone: '' },
      true
    );
    setTouched(false);
    setSelect(pick);
  };

  const handleSelect = (option) => {
    const { id, firstName, lastName, email, title, phone } = option;
    setValue({ ...value, id, firstName, lastName, email, title, phone }, true);
    removeUserWithId(id);
  };

  return (
    <div className="relative p-6 space-y-6 rounded-md bg-secondary-grey-light">
      {removable && (
        <div className="absolute -top-4 -right-4">
          <button
            type="button"
            onClick={() => {
              addUserWithId(value.id);
              remove();
            }}
            className="p-2 transition bg-white border rounded-full text-secondary hover:text-white focus:text-white hover:bg-danger focus:bg-danger focus:outline-none"
          >
            <svg viewBox="0 0 15 15" className="w-5 h-5 fill-current">
              <path d="M12.547 13.575L12.9755 5H13C13.3978 5 13.7794 4.84197 14.0607 4.56066C14.342 4.27936 14.5 3.89783 14.5 3.5C14.5 3.10218 14.342 2.72064 14.0607 2.43934C13.7794 2.15804 13.3978 2 13 2H10.39L10.1745 1.136C10.0933 0.811455 9.90597 0.523363 9.64224 0.317527C9.37852 0.111691 9.05355 -7.42238e-05 8.719 5.79473e-07H6.304C5.97371 -0.000289642 5.65256 0.108443 5.39038 0.309326C5.1282 0.510209 4.93966 0.79201 4.854 1.111L4.6165 2H2C1.60218 2 1.22064 2.15804 0.93934 2.43934C0.658035 2.72064 0.5 3.10218 0.5 3.5C0.5 3.89783 0.658035 4.27936 0.93934 4.56066C1.22064 4.84197 1.60218 5 2 5H2.0245L2.453 13.5745C2.47211 13.9588 2.63813 14.321 2.91675 14.5863C3.19536 14.8516 3.56527 14.9997 3.95 15H11.05C11.4346 14.9997 11.8045 14.8517 12.0831 14.5865C12.3617 14.3213 12.5278 13.9592 12.547 13.575ZM5.8215 1.37C5.85008 1.26387 5.91283 1.17011 6.00005 1.10323C6.08726 1.03635 6.19409 1.00007 6.304 1H8.719C8.83062 0.99992 8.93905 1.03719 9.02703 1.10587C9.11502 1.17455 9.17749 1.2707 9.2045 1.379L9.36 2H5.65L5.8215 1.37ZM1.5 3.5C1.5 3.36739 1.55268 3.24022 1.64645 3.14645C1.74022 3.05268 1.86739 3 2 3H13C13.1326 3 13.2598 3.05268 13.3536 3.14645C13.4473 3.24022 13.5 3.36739 13.5 3.5C13.5 3.63261 13.4473 3.75979 13.3536 3.85355C13.2598 3.94732 13.1326 4 13 4H2C1.86739 4 1.74022 3.94732 1.64645 3.85355C1.55268 3.75979 1.5 3.63261 1.5 3.5ZM3.45 13.525L3.0255 5H11.9755L11.55 13.5255C11.5435 13.6536 11.4879 13.7744 11.3948 13.8627C11.3018 13.951 11.1783 14.0002 11.05 14H3.95C3.82161 14.0002 3.69808 13.9509 3.605 13.8625C3.51192 13.7741 3.45642 13.6532 3.45 13.525Z" />
              <path d="M10.0249 7.00005C9.95926 6.99668 9.89356 7.0063 9.83161 7.02837C9.76965 7.05043 9.71266 7.0845 9.66389 7.12862C9.61512 7.17275 9.57554 7.22606 9.54741 7.2855C9.51927 7.34495 9.50314 7.40936 9.49994 7.47504L9.29994 11.475C9.29331 11.6077 9.33963 11.7375 9.42871 11.8359C9.51779 11.9344 9.64233 11.9934 9.77494 12C9.90755 12.0067 10.0374 11.9604 10.1358 11.8713C10.2343 11.7822 10.2933 11.6577 10.2999 11.525L10.4999 7.52504C10.5033 7.45936 10.4937 7.39367 10.4716 7.33171C10.4496 7.26976 10.4155 7.21276 10.3714 7.164C10.3272 7.11523 10.2739 7.07564 10.2145 7.04751C10.155 7.01938 10.0906 7.00325 10.0249 7.00005Z" />
              <path d="M4.97492 7.00003C4.90923 7.00323 4.84482 7.01936 4.78538 7.04749C4.72593 7.07563 4.67262 7.11521 4.6285 7.16398C4.58438 7.21275 4.55031 7.26974 4.52824 7.33169C4.50618 7.39365 4.49656 7.45935 4.49992 7.52503L4.69992 11.525C4.70843 11.6569 4.76802 11.7804 4.86605 11.869C4.96407 11.9577 5.09281 12.0047 5.22492 12C5.29061 11.9968 5.35502 11.9807 5.41447 11.9526C5.47391 11.9244 5.52722 11.8848 5.57135 11.8361C5.61547 11.7873 5.64954 11.7303 5.6716 11.6684C5.69367 11.6064 5.70329 11.5407 5.69992 11.475L5.49992 7.47503C5.49268 7.34264 5.43346 7.21847 5.33514 7.12952C5.23683 7.04057 5.10737 6.99403 4.97492 7.00003Z" />
              <path d="M7.5 7C7.36739 7 7.24021 7.05268 7.14645 7.14645C7.05268 7.24021 7 7.36739 7 7.5V11.5C7 11.6326 7.05268 11.7598 7.14645 11.8536C7.24021 11.9473 7.36739 12 7.5 12C7.63261 12 7.75979 11.9473 7.85355 11.8536C7.94732 11.7598 8 11.6326 8 11.5V7.5C8 7.36739 7.94732 7.24021 7.85355 7.14645C7.75979 7.05268 7.63261 7 7.5 7Z" />
            </svg>
          </button>
        </div>
      )}
      <div className="flex items-center space-x-4">
        <button
          type="button"
          onClick={() => toggleSelect(true)}
          className={`transition border-b-2 text-sm hover:text-blue-600 focus:text-blue-600 focus:outline-none ${
            select ? 'text-blue-500 border-blue-500' : 'text-secondary border-transparent'
          }`}
        >
          Select existing user
        </button>
        <button
          type="button"
          onClick={() => toggleSelect(false)}
          className={`transition border-b-2 text-sm hover:text-blue-600 focus:text-blue-600 focus:outline-none ${
            select ? 'text-secondary border-transparent' : 'text-blue-500 border-blue-500'
          }`}
        >
          Create user
        </button>
      </div>
      <div>
        {select ? (
          <Select
            classNamePrefix="r-select"
            options={options.map((m) => ({ ...m, value: m.id }))}
            filterOption={filterUsers}
            formatOptionLabel={formatOptionLabel}
            placeholder=""
            closeMenuOnSelect
            onChange={handleSelect}
          />
        ) : (
          <div className="space-y-6">
            <div className="grid gap-6 md:grid-cols-2">
              <InputField
                name={`${name}.firstName`}
                label="forms.manager.firstName"
                namespaces={['dashboard']}
              />
              <InputField
                name={`${name}.lastName`}
                label="forms.manager.lastName"
                namespaces={['dashboard']}
              />
            </div>
            <InputField
              name={`${name}.email`}
              label="forms.manager.email"
              namespaces={['dashboard']}
            />
            <InputField
              name={`${name}.title`}
              label="forms.manager.title"
              namespaces={['dashboard']}
            />
            <div className="flex w-1/2">
              <InputField
                name={`${name}.phone`}
                label="forms.manager.phone_optional"
                namespaces={['dashboard']}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

UserPicker.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  addUserWithId: PropTypes.func.isRequired,
  removeUserWithId: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  removable: PropTypes.bool.isRequired,
};

export default UserPicker;
