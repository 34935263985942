import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const Site = ({ id, externalChatbotFacilityId, name, address, managers, providers, createdAt }) => (
  <Link
    to={`/dashboard/sites/${id}`}
    className="grid grid-cols-3 p-4 transition rounded-md lg:grid-cols-6 bg-secondary-grey-light hover:bg-secondary-hover focus:outline-none focus:underline"
  >
    <div className="flex items-center col-span-2">
      <svg viewBox="0 0 75 80" className="flex-shrink-0 h-16 fill-current text-secondary">
        <path d="M68.7458 21.718C65.9837 16.9349 42.6068 3.44434 37.0826 3.44434C31.5668 3.44434 8.18158 16.9349 5.41948 21.718C2.85106 26.1643 2.85106 53.8359 5.41948 58.2822C8.19001 63.0654 31.5668 76.5559 37.0826 76.5559C42.6068 76.5559 65.9837 63.0654 68.7458 58.2822C71.3142 53.8359 71.3142 26.1643 68.7458 21.718Z" />
        <path d="M71.7263 20C68.3747 14.2063 44.0379 0 37.0821 0C30.1263 0 5.78947 14.2063 2.43789 19.9916C-0.812631 25.6084 -0.812631 54.3916 2.43789 60C5.78947 65.7937 30.1263 80 37.0821 80C44.0379 80 68.3747 65.7937 71.7263 60.0084C74.9768 54.3916 74.9768 25.6084 71.7263 20ZM69.941 58.9726C66.8589 64.2863 43.2211 77.9368 37.0821 77.9368C30.9432 77.9368 7.29684 64.2863 4.22316 58.9642C1.35158 54.0042 1.35158 25.9874 4.22316 21.0274C7.30526 15.7137 30.9516 2.06316 37.0821 2.06316C43.2211 2.06316 66.8589 15.7053 69.941 21.0274C72.8126 25.9958 72.8126 54.0042 69.941 58.9726Z" />
      </svg>
      <div className="flex-grow pl-4 text-sm text-left lg:text-base">
        <div>
          <p className="font-bold truncate facility-name">{name}</p>
        </div>
        <div className="text-secondary">
          <span className="capitalize">{address.city}</span>
          {address.state && (
            <>
              <span>{`, `}</span>
              <span className="uppercase">{address.state}</span>
            </>
          )}
          {address.country && (
            <>
              <span>{`, `}</span>
              <span className="capitalize">{address.country}</span>
            </>
          )}
        </div>
        <div className="text-secondary lg:hidden">
          <span className="text-sm">{externalChatbotFacilityId}</span>
        </div>
      </div>
    </div>
    <div className="items-center hidden lg:flex text-secondary">{externalChatbotFacilityId}</div>
    <div className="items-center hidden lg:flex text-secondary">{`${managers} Site Manager`}</div>
    <div className="items-center hidden lg:flex text-secondary">{`${providers} Providers`}</div>
    <div className="items-center hidden lg:flex text-secondary">{createdAt}</div>
    <div className="flex flex-col items-end text-sm text-right lg:hidden text-secondary">
      <span>{`${managers} Managers`}</span>
      <span>{`${providers} Providers`}</span>
      <span>{createdAt}</span>
    </div>
  </Link>
);

Site.propTypes = {
  id: PropTypes.string.isRequired,
  externalChatbotFacilityId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  address: PropTypes.shape({
    city: PropTypes.string.isRequired,
    country: PropTypes.string,
    state: PropTypes.string,
  }).isRequired,
  managers: PropTypes.number.isRequired,
  providers: PropTypes.number.isRequired,
  createdAt: PropTypes.string.isRequired,
};

export default Site;
