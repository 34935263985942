import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { UsersLoader, ModalArchivedUser, ModalResendUser, Manager } from 'src/views/app/shared';
import { Page, Searcher, Pagination } from 'src/components';
import { User, ModalNewUser, ModalEditUser } from './components';

const Users = ({ site, userRole }) => {
  const user = useSelector((state) => state.user);

  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);

  const [modalNewUserOpen, setModalNewUserOpen] = useState(false);
  const [editUser, setEditUser] = useState(null);
  const [archivedUser, setArchivedUser] = useState(null);
  const [resendUser, setResendUser] = useState(null);

  const { data, error, mutate } = useSWR(
    site.id
      ? [
          `/facilities/${site.id}/users?withTopics=true&page=${page}&${
            search !== '' ? `&search=${encodeURIComponent(search)}` : ''
          }`,
        ]
      : null
  );

  if (error) {
    return (
      <Page title={`${site.name} Users` || 'Site Users'}>
        <Page.Main className="pt-16">
          <div>An error has occurred.</div>
        </Page.Main>
      </Page>
    );
  }

  return (
    <>
      <Page title="Users">
        <Page.Main className="container pt-16 pb-24">
          <div className="grid max-w-screen-xl grid-cols-1 gap-6 pt-6 xl:grid-cols-2 2xl:grid-cols-3">
            <Manager
              type="DRM"
              firstName={site.drmUser.firstName}
              lastName={site.drmUser.lastName}
              email={site.drmUser.email}
              roles={site.drmUser.roles || ['MANAGER']}
            />
          </div>

          <div>
            <h5 className="pt-20 text-3xl font-bold">All users</h5>
            <div className="flex flex-col pt-6 space-y-4 lg:flex-row lg:space-y-0 lg:space-x-6">
              <div className="flex items-center">
                <button
                  type="button"
                  onClick={() => setModalNewUserOpen(true)}
                  disabled={!['admin', 'manager'].includes(site.userRole)}
                  className="w-full px-6 py-2 font-bold text-blue-500 transition rounded-md select-none 2xl:whitespace-nowrap bg-blue-50 hover:bg-blue-100 focus:bg-blue-100 focus:outline-none focus:text-blue-600 hover:text-blue-600 whitespace-nowrap"
                >
                  Add user
                </button>
              </div>

              <div className="flex items-center justify-between w-full space-x-4 lg:w-auto">
                <Searcher search={search} setSearch={setSearch} placeholder="Search a user..." />
                {search !== '' && data && (
                  <span className="text-sm text-secondary whitespace-nowrap">
                    {`${
                      data.users.length > 1
                        ? `${data.users.length} results`
                        : `${data.users.length} result`
                    }`}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="pt-12">
            {!data && <UsersLoader withTopics={false} />}
            {data && data.users && data.users.length <= 0 && (
              <div className="flex items-center justify-center">
                <div>
                  <svg viewBox="0 0 180.000000 161.000000" className="h-48 pb-4">
                    <g transform="translate(0.000000,161.000000) scale(0.100000,-0.100000)">
                      <path
                        d="M775 1589 c-276 -43 -513 -225 -619 -477 -43 -101 -59 -187 -59 -307 0 -139 19 -220 82 -350 131 -268 400 -435 702 -436 138 0 230 21 354 81 160 77 282 197 360 355 64 131 79 196 79 350 0 153 -14 217 -79 350 -126 259 -377 423 -673 439 -48 3 -114 1 -147 -5z m254 -194 c210 -50 390 -227 446 -440 19 -76 19 -224 0 -299 -41 -158 -172 -319 -316 -390 -281 -138 -609 -49 -781 213 -118 180 -129 406 -30 604 71 143 229 268 392 311 72 19 209 19 289 1z"
                        fill="#4a4a4a"
                      />
                      <path
                        d="M605 1131 c-108 -49 -159 -185 -113 -306 35 -92 145 -221 278 -328 88 -70 79 -80 105 119 l17 131 -40 39 -41 39 40 85 40 85 -25 18 c-69 50 -70 52 -58 85 11 31 10 32 -26 42 -56 15 -132 12 -177 -9z"
                        fill="#029099"
                      />
                      <path
                        d="M976 1136 c-22 -8 -48 -24 -60 -35 l-21 -21 42 -42 41 -41 -31 -64 -32 -64 44 -37 44 -38 -63 -164 c-34 -91 -66 -175 -71 -187 -27 -70 114 18 256 161 121 123 169 211 170 311 0 88 -31 156 -92 199 -35 24 -54 30 -115 33 -48 2 -87 -2 -112 -11z"
                        fill="#029099"
                      />
                    </g>
                  </svg>
                  <span className="block text-sm font-bold text-center">
                    {search ? 'No user found' : 'No User yet'}
                  </span>
                </div>
              </div>
            )}
            {data && data.users && data.users.length > 0 && (
              <>
                <div className="hidden px-4 text-xs font-bold uppercase sm:grid sm:grid-cols-5 lg:grid-cols-6 gap-x-2 text-secondary">
                  <span className="col-span-2">Name</span>
                  <span className="hidden col-span-2 text-center sm:block">Roles</span>
                  <span className="hidden text-center lg:block">Status</span>
                </div>
                <div className="grid mt-6 border divide-y rounded-md">
                  {data.users
                    .sort((a, b) => a.email.localeCompare(b.email))
                    .map((u) => (
                      <User
                        key={u.email}
                        user={u}
                        openEdit={() => setEditUser({ ...u, id: u.id.toString() })}
                        openDelete={() => setArchivedUser({ ...u, id: u.id.toString() })}
                        openResend={() => setResendUser({ ...u, id: u.id.toString() })}
                        canEdit={!!['ADMIN', 'MANAGER'].includes(user.role)}
                        canResend={!!u.externalChatbotManagerId}
                        settings={['admin', 'manager'].includes(site.userRole)}
                      />
                    ))}
                </div>
                {data.pages > 1 && (
                  <Pagination
                    page={page}
                    setPage={setPage}
                    pages={data.pages}
                    result={data.result}
                  />
                )}
              </>
            )}
          </div>
        </Page.Main>
      </Page>
      {editUser && (
        <ModalEditUser
          open={editUser !== null}
          requestClose={() => setEditUser(null)}
          user={editUser}
          site={site}
          userRole={userRole}
          mutate={mutate}
        />
      )}
      {archivedUser && (
        <ModalArchivedUser
          open={archivedUser !== null}
          requestClose={() => setArchivedUser(null)}
          user={archivedUser}
          mutate={mutate}
          facilityId={site.id}
        />
      )}
      {modalNewUserOpen && (
        <ModalNewUser
          open={modalNewUserOpen}
          requestClose={() => setModalNewUserOpen(false)}
          site={site}
          siteUsers={data || [{ id: -999 }]}
          userRole={userRole}
          mutate={mutate}
        />
      )}
      {resendUser && (
        <ModalResendUser
          open={resendUser !== null}
          requestClose={() => setResendUser(null)}
          user={resendUser}
          facilityId={site.id}
          mutate={mutate}
        />
      )}
    </>
  );
};

Users.propTypes = {
  site: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    address: PropTypes.shape({
      city: PropTypes.string.isRequired,
      country: PropTypes.string,
    }).isRequired,
    userRole: PropTypes.string.isRequired,
    drmUser: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      roles: PropTypes.arrayOf(PropTypes.string.isRequired),
    }).isRequired,
  }).isRequired,
  userRole: PropTypes.string.isRequired,
};

export default Users;
