import React from 'react';
import PropTypes from 'prop-types';

import Sidebar from './Sidebar';

const Layout = ({ children, site }) => (
  <div id="layout-facility">
    <div>
      <Sidebar site={site} />
    </div>
    <div>{children}</div>
  </div>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  site: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    logo: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    }),
    address: PropTypes.shape({
      city: PropTypes.string,
      country: PropTypes.string,
    }),
  }),
};

Layout.defaultProps = {
  site: {
    logo: undefined,
    address: {
      city: undefined,
      country: undefined,
    },
  },
};

export default Layout;
