import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { v1 as uuidv1 } from 'uuid';
import { useField, FieldArray } from 'formik';
import ct from 'city-timezones';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import countries from 'src/constants/countries.json';
import { InputField } from 'src/components';

const NewTripForm = ({ index, country, remove, removable }) => {
  const currentDate = new Date();

  const { t } = useTranslation(['countries']);

  const [minDate] = useState(currentDate.setDate(currentDate.getDate() + 1));
  const [{ value }, , { setValue }] = useField(`trips[${index}]`);
  const [timezones, setTimezones] = useState([]);

  useEffect(() => {
    let tzs = ct.cityMapping.filter(
      (e) => (e.iso2 === country || e.iso3 === country) && e.timezone
    );

    // TODO refactor
    // handle countries not supported by city-timezones (ie: Anguilla)
    if (country === 'AI') {
      tzs = [{ timezone: 'America/Anguilla' }];
    }

    const uniqueTzs = [...new Set(tzs.map((i) => i.timezone))];

    setTimezones(uniqueTzs);
    setValue({ ...value, data: { ...value.data, timezone: uniqueTzs[0] } }, true);
  }, [country]);

  return (
    <div className="relative p-6 space-y-4 border rounded-md">
      {removable && (
        <div className="absolute -top-4 -right-4">
          <button
            type="button"
            onClick={() => remove(index)}
            className="p-2 transition bg-white border rounded-full text-secondary hover:text-white focus:text-white hover:bg-danger focus:bg-danger focus:outline-none"
          >
            <svg viewBox="0 0 15 15" className="w-5 h-5 fill-current">
              <path d="M12.547 13.575L12.9755 5H13C13.3978 5 13.7794 4.84197 14.0607 4.56066C14.342 4.27936 14.5 3.89783 14.5 3.5C14.5 3.10218 14.342 2.72064 14.0607 2.43934C13.7794 2.15804 13.3978 2 13 2H10.39L10.1745 1.136C10.0933 0.811455 9.90597 0.523363 9.64224 0.317527C9.37852 0.111691 9.05355 -7.42238e-05 8.719 5.79473e-07H6.304C5.97371 -0.000289642 5.65256 0.108443 5.39038 0.309326C5.1282 0.510209 4.93966 0.79201 4.854 1.111L4.6165 2H2C1.60218 2 1.22064 2.15804 0.93934 2.43934C0.658035 2.72064 0.5 3.10218 0.5 3.5C0.5 3.89783 0.658035 4.27936 0.93934 4.56066C1.22064 4.84197 1.60218 5 2 5H2.0245L2.453 13.5745C2.47211 13.9588 2.63813 14.321 2.91675 14.5863C3.19536 14.8516 3.56527 14.9997 3.95 15H11.05C11.4346 14.9997 11.8045 14.8517 12.0831 14.5865C12.3617 14.3213 12.5278 13.9592 12.547 13.575ZM5.8215 1.37C5.85008 1.26387 5.91283 1.17011 6.00005 1.10323C6.08726 1.03635 6.19409 1.00007 6.304 1H8.719C8.83062 0.99992 8.93905 1.03719 9.02703 1.10587C9.11502 1.17455 9.17749 1.2707 9.2045 1.379L9.36 2H5.65L5.8215 1.37ZM1.5 3.5C1.5 3.36739 1.55268 3.24022 1.64645 3.14645C1.74022 3.05268 1.86739 3 2 3H13C13.1326 3 13.2598 3.05268 13.3536 3.14645C13.4473 3.24022 13.5 3.36739 13.5 3.5C13.5 3.63261 13.4473 3.75979 13.3536 3.85355C13.2598 3.94732 13.1326 4 13 4H2C1.86739 4 1.74022 3.94732 1.64645 3.85355C1.55268 3.75979 1.5 3.63261 1.5 3.5ZM3.45 13.525L3.0255 5H11.9755L11.55 13.5255C11.5435 13.6536 11.4879 13.7744 11.3948 13.8627C11.3018 13.951 11.1783 14.0002 11.05 14H3.95C3.82161 14.0002 3.69808 13.9509 3.605 13.8625C3.51192 13.7741 3.45642 13.6532 3.45 13.525Z" />
              <path d="M10.0249 7.00005C9.95926 6.99668 9.89356 7.0063 9.83161 7.02837C9.76965 7.05043 9.71266 7.0845 9.66389 7.12862C9.61512 7.17275 9.57554 7.22606 9.54741 7.2855C9.51927 7.34495 9.50314 7.40936 9.49994 7.47504L9.29994 11.475C9.29331 11.6077 9.33963 11.7375 9.42871 11.8359C9.51779 11.9344 9.64233 11.9934 9.77494 12C9.90755 12.0067 10.0374 11.9604 10.1358 11.8713C10.2343 11.7822 10.2933 11.6577 10.2999 11.525L10.4999 7.52504C10.5033 7.45936 10.4937 7.39367 10.4716 7.33171C10.4496 7.26976 10.4155 7.21276 10.3714 7.164C10.3272 7.11523 10.2739 7.07564 10.2145 7.04751C10.155 7.01938 10.0906 7.00325 10.0249 7.00005Z" />
              <path d="M4.97492 7.00003C4.90923 7.00323 4.84482 7.01936 4.78538 7.04749C4.72593 7.07563 4.67262 7.11521 4.6285 7.16398C4.58438 7.21275 4.55031 7.26974 4.52824 7.33169C4.50618 7.39365 4.49656 7.45935 4.49992 7.52503L4.69992 11.525C4.70843 11.6569 4.76802 11.7804 4.86605 11.869C4.96407 11.9577 5.09281 12.0047 5.22492 12C5.29061 11.9968 5.35502 11.9807 5.41447 11.9526C5.47391 11.9244 5.52722 11.8848 5.57135 11.8361C5.61547 11.7873 5.64954 11.7303 5.6716 11.6684C5.69367 11.6064 5.70329 11.5407 5.69992 11.475L5.49992 7.47503C5.49268 7.34264 5.43346 7.21847 5.33514 7.12952C5.23683 7.04057 5.10737 6.99403 4.97492 7.00003Z" />
              <path d="M7.5 7C7.36739 7 7.24021 7.05268 7.14645 7.14645C7.05268 7.24021 7 7.36739 7 7.5V11.5C7 11.6326 7.05268 11.7598 7.14645 11.8536C7.24021 11.9473 7.36739 12 7.5 12C7.63261 12 7.75979 11.9473 7.85355 11.8536C7.94732 11.7598 8 11.6326 8 11.5V7.5C8 7.36739 7.94732 7.24021 7.85355 7.14645C7.75979 7.05268 7.63261 7 7.5 7Z" />
            </svg>
          </button>
        </div>
      )}
      <InputField
        name={`trips.[${index}].data.homeport`}
        label="Homeport*"
        namespaces={['dashboard']}
      />
      <InputField
        type="select"
        name={`trips.[${index}].data.country`}
        label="Country of departure*"
        namespaces={['dashboard']}
        options={countries.map((e) => ({
          ...e,
          label: t(`countries:${e.label}`),
        }))}
      />
      {country && timezones.length > 1 && (
        <InputField
          type="select"
          name={`trips.[${index}].data.timezone`}
          label="forms.address.timezone"
          namespaces={['dashboard']}
          options={timezones.map((tz) => ({ label: tz, value: tz }))}
        />
      )}
      <FieldArray name={`trips.[${index}].data.sails`}>
        {({ remove: removeDate, push }) => (
          <div className="space-y-4">
            {value.data.sails.map((sail, sailIndex) => (
              <div key={`${value.id + sailIndex}`} className="flex flex-col space-y-1">
                <span className="text-sm">Sail date*</span>
                <div className="flex items-center space-x-2">
                  <div key={uuidv1()} className="w-1/2">
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      selected={sail}
                      onChange={(date) => {
                        if (date === null) return;

                        const sails = [...value.data.sails];
                        sails[sailIndex] = date;

                        setValue({ ...value, data: { ...value.data, sails, end: '' } });
                      }}
                      minDate={minDate}
                      excludeDates={value.data.sails}
                      className="w-full px-3 py-2 text-sm transition border rounded focus:outline-none disabled:pointer-events-none"
                    />
                  </div>
                  {value.data.sails.length > 1 && (
                    <button
                      type="button"
                      onClick={() => removeDate(sailIndex)}
                      className="p-2 transition bg-white border rounded-full text-secondary hover:text-white focus:text-white hover:bg-danger focus:bg-danger focus:outline-none"
                    >
                      <svg viewBox="0 0 15 15" className="w-4 h-4 fill-current">
                        <path d="M12.547 13.575L12.9755 5H13C13.3978 5 13.7794 4.84197 14.0607 4.56066C14.342 4.27936 14.5 3.89783 14.5 3.5C14.5 3.10218 14.342 2.72064 14.0607 2.43934C13.7794 2.15804 13.3978 2 13 2H10.39L10.1745 1.136C10.0933 0.811455 9.90597 0.523363 9.64224 0.317527C9.37852 0.111691 9.05355 -7.42238e-05 8.719 5.79473e-07H6.304C5.97371 -0.000289642 5.65256 0.108443 5.39038 0.309326C5.1282 0.510209 4.93966 0.79201 4.854 1.111L4.6165 2H2C1.60218 2 1.22064 2.15804 0.93934 2.43934C0.658035 2.72064 0.5 3.10218 0.5 3.5C0.5 3.89783 0.658035 4.27936 0.93934 4.56066C1.22064 4.84197 1.60218 5 2 5H2.0245L2.453 13.5745C2.47211 13.9588 2.63813 14.321 2.91675 14.5863C3.19536 14.8516 3.56527 14.9997 3.95 15H11.05C11.4346 14.9997 11.8045 14.8517 12.0831 14.5865C12.3617 14.3213 12.5278 13.9592 12.547 13.575ZM5.8215 1.37C5.85008 1.26387 5.91283 1.17011 6.00005 1.10323C6.08726 1.03635 6.19409 1.00007 6.304 1H8.719C8.83062 0.99992 8.93905 1.03719 9.02703 1.10587C9.11502 1.17455 9.17749 1.2707 9.2045 1.379L9.36 2H5.65L5.8215 1.37ZM1.5 3.5C1.5 3.36739 1.55268 3.24022 1.64645 3.14645C1.74022 3.05268 1.86739 3 2 3H13C13.1326 3 13.2598 3.05268 13.3536 3.14645C13.4473 3.24022 13.5 3.36739 13.5 3.5C13.5 3.63261 13.4473 3.75979 13.3536 3.85355C13.2598 3.94732 13.1326 4 13 4H2C1.86739 4 1.74022 3.94732 1.64645 3.85355C1.55268 3.75979 1.5 3.63261 1.5 3.5ZM3.45 13.525L3.0255 5H11.9755L11.55 13.5255C11.5435 13.6536 11.4879 13.7744 11.3948 13.8627C11.3018 13.951 11.1783 14.0002 11.05 14H3.95C3.82161 14.0002 3.69808 13.9509 3.605 13.8625C3.51192 13.7741 3.45642 13.6532 3.45 13.525Z" />
                        <path d="M10.0249 7.00005C9.95926 6.99668 9.89356 7.0063 9.83161 7.02837C9.76965 7.05043 9.71266 7.0845 9.66389 7.12862C9.61512 7.17275 9.57554 7.22606 9.54741 7.2855C9.51927 7.34495 9.50314 7.40936 9.49994 7.47504L9.29994 11.475C9.29331 11.6077 9.33963 11.7375 9.42871 11.8359C9.51779 11.9344 9.64233 11.9934 9.77494 12C9.90755 12.0067 10.0374 11.9604 10.1358 11.8713C10.2343 11.7822 10.2933 11.6577 10.2999 11.525L10.4999 7.52504C10.5033 7.45936 10.4937 7.39367 10.4716 7.33171C10.4496 7.26976 10.4155 7.21276 10.3714 7.164C10.3272 7.11523 10.2739 7.07564 10.2145 7.04751C10.155 7.01938 10.0906 7.00325 10.0249 7.00005Z" />
                        <path d="M4.97492 7.00003C4.90923 7.00323 4.84482 7.01936 4.78538 7.04749C4.72593 7.07563 4.67262 7.11521 4.6285 7.16398C4.58438 7.21275 4.55031 7.26974 4.52824 7.33169C4.50618 7.39365 4.49656 7.45935 4.49992 7.52503L4.69992 11.525C4.70843 11.6569 4.76802 11.7804 4.86605 11.869C4.96407 11.9577 5.09281 12.0047 5.22492 12C5.29061 11.9968 5.35502 11.9807 5.41447 11.9526C5.47391 11.9244 5.52722 11.8848 5.57135 11.8361C5.61547 11.7873 5.64954 11.7303 5.6716 11.6684C5.69367 11.6064 5.70329 11.5407 5.69992 11.475L5.49992 7.47503C5.49268 7.34264 5.43346 7.21847 5.33514 7.12952C5.23683 7.04057 5.10737 6.99403 4.97492 7.00003Z" />
                        <path d="M7.5 7C7.36739 7 7.24021 7.05268 7.14645 7.14645C7.05268 7.24021 7 7.36739 7 7.5V11.5C7 11.6326 7.05268 11.7598 7.14645 11.8536C7.24021 11.9473 7.36739 12 7.5 12C7.63261 12 7.75979 11.9473 7.85355 11.8536C7.94732 11.7598 8 11.6326 8 11.5V7.5C8 7.36739 7.94732 7.24021 7.85355 7.14645C7.75979 7.05268 7.63261 7 7.5 7Z" />
                      </svg>
                    </button>
                  )}
                </div>
              </div>
            ))}
            {value.data.repeat === '' ? (
              <div className="flex items-center space-x-4">
                <div className="flex items-center">
                  <button
                    type="button"
                    className="flex flex-row items-center space-x-2 font-bold text-blue-400 hover:underline focus:underline focus:outline-none focus:text-blue-900 hover:text-blue-900"
                    onClick={() => push('')}
                  >
                    <svg viewBox="0 0 17 17" className="w-4 h-4 fill-current">
                      <path d="M7.31339 1.62251C7.34568 1.00902 7.8623 0.492403 8.47578 0.460114C9.12156 0.460114 9.63818 0.976734 9.63818 1.62251L9.67046 7.33761H15.3533C15.999 7.33761 16.5157 7.85423 16.5157 8.5C16.5157 9.14577 15.999 9.66239 15.3533 9.66239H9.67046L9.63818 15.3775C9.63818 16.0233 9.12156 16.5399 8.47578 16.5399C7.8623 16.5076 7.34568 15.991 7.31339 15.3775L7.34568 9.66239L1.59829 9.66239C0.984805 9.6301 0.468186 9.11349 0.435897 8.5C0.468186 7.88651 0.984805 7.3699 1.59829 7.33761L7.34568 7.33761L7.31339 1.62251Z" />
                    </svg>
                    <span>Add date</span>
                  </button>
                </div>
                {value.data.sails.length < 2 && (
                  <div className="flex items-center">
                    <button
                      type="button"
                      className="flex flex-row items-center space-x-2 font-bold text-blue-400 hover:underline focus:underline focus:outline-none focus:text-blue-900 hover:text-blue-900"
                      onClick={() =>
                        setValue({ ...value, data: { ...value.data, repeat: 'UNSET' } })
                      }
                    >
                      <svg viewBox="0 0 16 18" className="w-4 h-4 fill-current">
                        <path d="M14.7888 6.97235C14.1264 6.97235 13.5893 7.50948 13.5893 8.17193V10.7322C13.5893 13.1493 11.6198 15.1187 9.20274 15.1187H6.76777C4.35071 15.1187 2.38125 13.1493 2.38125 10.7322C2.38125 8.31517 4.35071 6.34571 6.76777 6.34571H9.0237L7.59136 7.77804C7.12586 8.24355 7.12586 8.99552 7.59136 9.46103C7.82412 9.69379 8.12849 9.81912 8.43286 9.81912C8.73723 9.81912 9.0416 9.69379 9.27435 9.46103L12.7478 5.98763C13.2133 5.52212 13.2133 4.77014 12.7478 4.30463L9.27435 0.849131C8.80885 0.383623 8.05687 0.383623 7.59136 0.849131C7.12586 1.31464 7.12586 2.06661 7.59136 2.53212L9.0237 3.96446H6.76777C3.0258 3.96446 0 7.00816 0 10.7322C0 14.4563 3.04371 17.5 6.76777 17.5H9.22064C12.9626 17.5 15.9884 14.4563 15.9884 10.7322V8.17193C15.9884 7.50948 15.4513 6.97235 14.7888 6.97235Z" />
                      </svg>
                      <span>Add repetition</span>
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <div className="w-1/2 p-4 space-y-4 bg-gray-100 border rounded-md">
                <div>
                  <InputField
                    type="select"
                    name={`trips.[${index}].data.repeat`}
                    label="Frequency:"
                    translate={false}
                    options={[
                      { value: 'UNSET', label: 'Select applicable' },
                      { value: 'DAYS', label: 'Every day' },
                      { value: 'WEEKS', label: 'Every week' },
                    ]}
                  />
                </div>
                <div className="flex flex-col">
                  <span className="text-sm">End of repetition*</span>
                  {value.data.sails[0] !== '' ? (
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      selected={value.data.end}
                      onChange={(date) => {
                        if (date === null) return;
                        setValue({ ...value, data: { ...value.data, end: date } });
                      }}
                      minDate={value.data.sails[0]}
                      className="w-full px-3 py-2 text-sm transition border rounded focus:outline-none disabled:pointer-events-none"
                    />
                  ) : (
                    <span className="text-sm text-red-500">Select a date first</span>
                  )}
                </div>
                <div className="flex items-center">
                  <button
                    type="button"
                    className="flex flex-row items-center space-x-2 font-bold text-blue-400 hover:underline focus:underline focus:text-blue-900 hover:text-blue-900 focus:outline-none"
                    onClick={() => setValue({ ...value, data: { ...value.data, repeat: '' } })}
                  >
                    <svg viewBox="0 0 15 15" className="w-3 h-3 fill-current">
                      <path d="M12.5214 0.384615C13.0912 -0.128205 14.0028 -0.128205 14.5726 0.384615C15.1425 0.954416 15.1425 1.8661 14.5726 2.4359L9.5584 7.50712L14.5726 12.5214C15.1425 13.0912 15.1425 14.0028 14.5726 14.5726C14.0028 15.1425 13.0912 15.1425 12.5214 14.5726L7.50712 9.5584L2.4359 14.5726C1.8661 15.1425 0.954416 15.1425 0.384615 14.5726C-0.128205 14.0028 -0.128205 13.0912 0.384615 12.5214L5.45584 7.50712L0.384615 2.4359C-0.128205 1.8661 -0.128205 0.954416 0.384615 0.384615C0.954416 -0.128205 1.8661 -0.128205 2.4359 0.384615L7.50712 5.45584L12.5214 0.384615Z" />
                    </svg>
                    <span>Delete repetition</span>
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </FieldArray>
    </div>
  );
};

NewTripForm.propTypes = {
  index: PropTypes.number.isRequired,
  removable: PropTypes.bool.isRequired,
  remove: PropTypes.func.isRequired,
  country: PropTypes.string,
};

NewTripForm.defaultProps = {
  country: '',
};

export default NewTripForm;
