import React from 'react';
import PropTypes from 'prop-types';

const UsersLoader = ({ withTopics }) => (
  <>
    <div className="hidden px-4 text-xs font-bold uppercase md:grid md:grid-cols-5 xl:grid-cols-9 gap-x-2 text-secondary">
      <span className="col-span-2">Name</span>
      <span className="hidden text-center xl:block">Title</span>
      <span className="hidden col-span-3 text-center opacity-0 md:block xl:col-span-4">
        Sections
      </span>
      <span className="hidden text-center xl:block">Status</span>
    </div>
    <div className="grid mt-6 border divide-y rounded-md">
      {[...Array(8)].map((_, i) => (
        <div
          key={i.toString()}
          className="grid p-4 gap-y-4 md:gap-y-0 md:grid-cols-5 xl:grid-cols-9 gap-x-2"
        >
          <div className="flex items-center col-span-1 space-x-4 md:col-span-2">
            <div className="rounded-full w-9 h-9 bg-secondary-hover animate-pulse" />
            <div className="flex flex-col">
              <div className="w-16 h-4 rounded-full bg-secondary-hover animate-pulse" />
              <div className="w-24 h-2 mt-2 rounded-full bg-secondary-hover animate-pulse" />
            </div>
          </div>

          <div className="items-center justify-center hidden xl:flex">
            <div className="w-16 h-4 rounded-full bg-secondary-hover animate-pulse" />
          </div>

          <div className="flex items-center justify-center md:col-span-3 xl:col-span-4">
            {withTopics && (
              <div className="grid w-full grid-cols-1 gap-2 2xl:grid-cols-2">
                <div
                  className="w-full h-4 rounded-full bg-secondary-hover animate-pulse"
                  style={{ maxWidth: '220px' }}
                />
                <div
                  className="w-full h-4 rounded-full bg-secondary-hover animate-pulse"
                  style={{ maxWidth: '220px' }}
                />
                <div
                  className="w-full h-4 rounded-full bg-secondary-hover animate-pulse"
                  style={{ maxWidth: '220px' }}
                />
                <div
                  className="w-full h-4 rounded-full bg-secondary-hover animate-pulse"
                  style={{ maxWidth: '220px' }}
                />
              </div>
            )}
          </div>

          <div className="items-center justify-center hidden xl:flex">
            <div className="w-24 h-4 rounded-full bg-secondary-hover animate-pulse" />
          </div>
        </div>
      ))}
    </div>
  </>
);

UsersLoader.propTypes = {
  withTopics: PropTypes.bool,
};

UsersLoader.defaultProps = {
  withTopics: true,
};

export default UsersLoader;
