import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { httpClient, mapRoles } from 'src/helpers';

import { Modal, Spinner, Snackbar, Avatar } from 'src/components';

const ModalArchived = ({ open, requestClose, user, mutate, facilityId, description }) => {
  const [snackbarState, setSnackbarState] = useState({ type: 'HIDDEN', msg: '' });
  const [isArchiving, setIsArchiving] = useState(false);

  const handleConfirm = async () => {
    if (window.gtag) window.gtag('event', 'Delete_user');
    setIsArchiving(true);

    try {
      const { status } = await httpClient.delete(
        process.env.GATSBY_VERTICAL === 'CRUISES'
          ? `/subclients/${facilityId}/users/${user.id}`
          : `/facilities/${facilityId}/users/${user.id}`
      );

      if (status === 200) {
        setTimeout(() => {
          setSnackbarState({ type: 'SUCCESS' });
        }, 1000);

        setTimeout(() => {
          mutate();
          requestClose();
        }, 2000);
      } else throw Error('Disabled user failed.');
    } catch (error) {
      if (error.response.status === 400)
        setSnackbarState({
          type: 'ERROR',
          msg:
            'User cannot be deleted because of a section constraint. Remove the section(s) before deleting the user.',
        });
      else setSnackbarState({ type: 'ERROR' });
      setIsArchiving(false);
    }
  };

  const handleCancel = () => {
    setIsArchiving(false);
    requestClose();
  };

  return (
    <Modal open={open} requestClose={requestClose}>
      {!isArchiving ? (
        <Modal.Body className="px-6 pt-6">
          <Snackbar
            type={snackbarState.type}
            message={snackbarState.msg}
            requestClose={() => setSnackbarState({ ...snackbarState, type: 'HIDDEN' })}
          />
          <div className="max-w-md mx-auto">
            <div className="pt-6 pb-12">
              <h5 className="font-bold">Do you want to remove this user ?</h5>
              <p className="pt-2 text-secondary">
                {description || 'User will loose access to this site.'}
              </p>
              <div className="flex items-center pt-12 space-x-4">
                <Avatar
                  initials={`${user.firstName.slice(0, 1)}${user.lastName.slice(0, 1)}`}
                  role={user.roles[0]}
                />
                <div className="flex flex-col">
                  <span className="font-semibold capitalize">
                    {`${user.firstName} ${user.lastName}`}
                  </span>
                  <span className="text-xs capitalize lg:text-sm text-secondary">
                    {user.roles
                      .filter((r) => r !== 'USER')
                      .map(mapRoles)
                      .join(', ')}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center pb-6">
              <div className="flex items-center w-full space-x-4">
                <button
                  type="button"
                  onClick={() => handleCancel()}
                  className="w-1/2 py-4 font-semibold transition border-2 rounded-lg select-none focus:outline-none hover:bg-gray-200 focus:bg-gray-200"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={() => handleConfirm()}
                  className="w-full py-4 font-semibold transition border-2 rounded-lg select-none border-danger text-danger hover:border-danger-light focus:border-danger-light hover:bg-danger-light focus:bg-danger-light hover:text-white focus:text-white focus:outline-none"
                >
                  Remove User
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      ) : (
        <div className="flex justify-center p-6 text-primary-light">
          <Spinner />
        </div>
      )}
    </Modal>
  );
};

ModalArchived.propTypes = {
  open: PropTypes.bool.isRequired,
  requestClose: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    roles: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }).isRequired,
  mutate: PropTypes.func.isRequired,
  facilityId: PropTypes.string,
  description: PropTypes.string,
};

ModalArchived.defaultProps = {
  facilityId: '',
  description: '',
};

export default ModalArchived;
