import React from 'react';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { Page, Spinner } from 'src/components';
import { fetchOnce } from 'src/helpers';
import { Logo, Bot } from './components';

const Settings = () => {
  const user = useSelector((state) => state.user);

  const { data, error, mutate } = useSWR(`/clients/${user.clientId}/settings`, fetchOnce);

  if (error) {
    return (
      <Page title="Settings">
        <Page.Main className="container py-12 space-y-12">
          <p>An error occured</p>
        </Page.Main>
      </Page>
    );
  }

  if (!data) {
    return (
      <Page title="Settings">
        <Page.Main className="container py-12 space-y-12">
          <div className="flex items-center justify-center text-primary-light">
            <Spinner />
          </div>
        </Page.Main>
      </Page>
    );
  }

  return (
    <Page title="Settings">
      <Page.Main className="container py-12 space-y-12">
        <Logo
          logo={data.logo}
          canEditLogo={data.canEditLogo && ['ADMIN'].includes(user.role)}
          mutate={mutate}
        />
        {process.env.GATSBY_VERTICAL === 'GREENSHIELD' && (
          <Bot settings={data.config} canEditChatbot={data.canEditChatbot} mutate={mutate} />
        )}
      </Page.Main>
    </Page>
  );
};

export default Settings;
