import React from 'react';
// import ReactWebChat, { createDirectLine, createStyleSet, createStore } from 'botframework-webchat';
import PropTypes from 'prop-types';

import './bot.scss';
import { Page } from 'src/components';

// const styleOptions = {
//   botAvatarInitials: '',
//   hideSendBox: true,
//   userAvatarInitials: '',
//   hideUploadButton: true,
//   bubbleFromUserBackground: '#ffffff',
//   bubbleFromUserTextColor: 'White',
//   bubbleFromUserBorderRadius: 20,
//   bubbleFromUserBorderWidth: 0,
//   suggestedActionBackground: '#56C1A3',
//   suggestedActionTextColor: 'white',
//   suggestedActionBorderRadius: 20,
//   suggestedActionBorderColor: 'white',
//   sendBoxButtonColor: '#56C1A3',
// };

const Assessment = ({ facility }) => {
  console.log('TEST PAGE');
  // const [token, setToken] = useState(null);
  // const [localStore, setLocalStore] = useState(null);
  // const [styleSet, setStyleSet] = useState(null);

  // const initializeBot = async () => {
  //   const res = await fetch('https://directline.botframework.com/v3/directline/tokens/generate', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: `Bearer ${process.env.GATSBY_BOT_TOKEN}`,
  //     },
  //   });

  //   const { token: t } = await res.json();
  //   setToken(t);

  //   const createdStyleSet = createStyleSet({
  //     botAvatarBackgroundColor: '#ffffff',
  //     backgroundColor: '#ffffff',
  //     bubbleBorderRadius: 20,
  //     bubbleBorderWidth: 0,
  //     bubbleBackground: '#E6E6E6',
  //     transcriptVisualKeyboardIndicatorWidth: 0,
  //     transcriptActivityVisualKeyboardIndicatorWidth: 0,
  //     sendBoxButtonColor: '#56C1A3',
  //     suggestedActionBackground: '#56C1A3',
  //     autoScrollSnapOnActivity: true,
  //     autoScrollSnapOnPage: true,
  //   });
  //   setStyleSet(createdStyleSet);

  //   const createdStore = createStore({}, (store) => (next) => (action) => {
  //     switch (action.type) {
  //       case 'DIRECT_LINE/POST_ACTIVITY_FULFILLED':
  //         // hideManagerCodeInput();
  //         break;
  //       case 'DIRECT_LINE/CONNECT_FULFILLED':
  //         store.dispatch({
  //           type: 'DIRECT_LINE/POST_ACTIVITY',
  //           meta: { method: 'keyboard' },
  //           payload: {
  //             activity: {
  //               type: 'invoke',
  //               name: 'InitConversation',
  //               locale: 'EN-en',
  //               value: {
  //                 jsonWebToken: token,
  //                 triggeredScenario: {
  //                   trigger: 'FC_Connect_Manager',
  //                 },
  //               },
  //             },
  //           },
  //         });
  //         break;
  //       // case 'DIRECT_LINE/INCOMING_ACTIVITY':
  //       //   if (
  //       //     action.payload &&
  //       //     action.payload.activity &&
  //       //     action.payload.activity.type === 'message'
  //       //   ) {
  //       //     processMessageActivity(action.payload.activity);
  //       //   } else if (
  //       //     action.payload &&
  //       //     action.payload.activity &&
  //       //     action.payload.activity.type === 'event' &&
  //       //     action.payload.activity.name === 'ShareLocationEvent'
  //       //   ) {
  //       //     // share
  //       //     getUserLocation((location: any) => {
  //       //       store.dispatch({
  //       //         type: 'WEB_CHAT/SEND_POST_BACK',
  //       //         payload: { value: JSON.stringify(location) },
  //       //       });
  //       //     });
  //       //   }
  //       //   break;
  //       default:
  //         break;
  //     }
  //     return next(action);
  //   });
  //   setLocalStore(createdStore);
  // };

  // useEffect(() => {
  //   initializeBot();
  // }, []);

  return (
    <Page title={`Assessment ${facility.name}` || 'Assessment'}>
      <Page.Main className="pt-16">
        Bot page
        {/* {token ? (
          <div id="bot">
            <div className="flex items-start justify-center max-w-screen-sm pt-4 mx-auto bot-container lg:pt-6">
              <ReactWebChat
                directLine={createDirectLine({ token })}
                store={localStore}
                styleOptions={styleOptions}
                styleSet={styleSet}
              />
            </div>
          </div>
        ) : (
          <div>Loading...</div>
        )} */}
      </Page.Main>
    </Page>
  );
};

Assessment.propTypes = {
  facility: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default Assessment;
